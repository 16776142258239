const descomponerFechaHora = (date) => {
    if (!(date instanceof Date)) {
      throw new Error('El parámetro debe ser un objeto Date');
    }
  
    const dia = String(date.getDate()).padStart(2, '0');
    const mes = String(date.getMonth() + 1).padStart(2, '0');
    const anio = date.getFullYear();
    const fecha = `${dia}/${mes}/${anio}`;
  
    const horas = String(date.getHours()).padStart(2, '0');
    const minutos = String(date.getMinutes()).padStart(2, '0');
    const segundos = String(date.getSeconds()).padStart(2, '0');
    const hora = `${horas}:${minutos}:${segundos}`;
  
    return { fecha, hora };
  }
  export default descomponerFechaHora;