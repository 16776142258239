import { isLoginComercio } from "./loginController";

export function getApiUrl() {
    if (isLoginComercio())
        return process.env.REACT_APP_API_URL_COMERCIOS;
    return process.env.REACT_APP_API_URL;
}

export const RequestContentType = Object.freeze({
    APPLICATION_JSON: "application/json",
    XFORM_URLENCODED: "application/x-www-form-urlencoded;charset=UTF-8"
});

function prepararBody(type, body) {
    if (type === RequestContentType.APPLICATION_JSON)
        return JSON.stringify(body);
    if (type === RequestContentType.XFORM_URLENCODED)
        return new URLSearchParams(body);
    return body;
};

export function getRequest(url, path, token = "") {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(
                `${url}${path}`,
                {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            if (!response.ok) {
                if (response.status === 401) {
                    handleLogout();
                }
                reject({
                    success: false,
                    message: "Falló la conexión",
                    status: 500,
                    data: response
                });
                return;
            }
            try {
                const respuesta = await response.json();
                resolve(respuesta);
                return;
            } catch {
                resolve(response);
            }
        } catch (err) {
            reject({
                success: false,
                message: "Falló la conexión",
                status: 500,
                data: err
            });
        }
    });
}

export function deleteRequest(url, path, token = "") {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(
                `${url}${path}`,
                {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            if (!response.ok) {
                if (response.status === 401) {
                    handleLogout();
                }
                reject({
                    success: false,
                    message: "Falló la conexión",
                    status: 500,
                    data: response
                });
                return;
            }
            try {
                const respuesta = await response.json();
                resolve(respuesta);
                return;
            } catch {
                resolve(response);
            }
        } catch (err) {
            reject({
                success: false,
                message: "Falló la conexión",
                status: 500,
                data: err
            });
        }
    });
}

function bodyRequest(url, path, type, body, token = "", method = "POST", isLogin = false) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`${url}${path}`, {
                method: method,
                headers: {
                    'Content-Type': type,
                    'Authorization': `Bearer ${token}`
                },
                body: prepararBody(type, body)
            });
            if (!response.ok) {
                if (response.status === 401 && !isLogin) {
                    handleLogout();
                }
                reject(response);
                return;
            }
            try {
                const respuesta = await response.json();
                resolve(respuesta);
                return;
            } catch {
                resolve(response);
            }
        } catch (err) {
            reject({
                success: false,
                message: "Falló la conexión",
                status: 500,
                data: err
            });
        }
    });
}
export async function postRequest(url, path, type, body, token, isLogin = false) {
    return await bodyRequest(url, path, type, body, token, "POST", isLogin);
}
export async function putRequest(url, path, type, body, token) {
    return await bodyRequest(url, path, type, body, token, "PUT");
}

export function postFormDataRequest(url, path, formdata, token) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(url + path, {
                method: "POST",
                body: formdata,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                if (response.status === 401)
                    handleLogout();
                reject({
                    success: false,
                    message: "Falló la conexión",
                    status: 500,
                    data: response
                });
                return;
            }
            try {
                const respuesta = await response.json();
                resolve(respuesta);
                return;
            } catch {
                resolve(response);
            }
        } catch (err) {
            reject({
                success: false,
                message: "Falló la conexión",
                status: 500,
                data: err
            });
        }
    });
}

function handleLogout() {
    window.location.href = "/logout";
}