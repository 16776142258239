import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  eliminarUsuarioRequest,
  editarUsuarioRequest,
  restablecerUsuarioRequest,
  obtenerUsuariosPorIdComercio,
} from "../../../connection/mantenedores/usuarioMantenedor";
import { getToken } from "../../../connection/tokenController";
import { useLoader } from "../../../context/LoaderContext";
import UsuariosLayout from "../../../components/UsuariosLayout";
import ShadowBox from "../../../components/ShadowBox";
import { Divider, Grid, Typography } from "@mui/material";
import Combobox from "../../../components/Combobox";
import { obtenerTodosLosComerciosRequest } from "../../../connection/mantenedores/comercioMantenedor";
import SeleccionarUsuarioComercioDialog from "../../../pages/usuarios/comercios/SeleccionarUsuarioComercioDialog";

function parsearComerciosACombobox(listado) {
  return listado.map((x, idx) => ({
    nombre: x.nombreComercio.toUpperCase(),
    codigo: x.idComercio,
    rut: x.rut,
  }));
}

export const SELECTED_COMMERCE = "selectedCommerce";
export const USUARIO_COMERCIO_INPAGE = "usuario_comercio_in_page";

const UsuariosComercios = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [listado, setListado] = useState([]);
  const [comercios, setComercios] = useState([]);
  const [comercioSelected, setComercioSelected] = useState({
    nombre: "",
    codigo: -1,
    rut: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleEnableDisable = async (modifyUser, onHabilitarUsuario) => {
    if (modifyUser.habilitado) {
      await eliminarUsuarioRequest(modifyUser.idUsuario, getToken());
      handleSetListado();
      return "Usuario deshabilitado con éxito";
    }
    await handleHabilitarYResetearUsuario(modifyUser, onHabilitarUsuario);
    handleSetListado();
    return "Usuario habilitado con éxito";
  };

  const handleHabilitarYResetearUsuario = async (
    modifyUser,
    onHabilitarUsuario
  ) => {
    await editarUsuarioRequest(
      { ...modifyUser, habilitado: !modifyUser.habilitado },
      getToken(),
      modifyUser.idUsuario
    );
    const respuesta = await restablecerUsuarioRequest(
      modifyUser.idUsuario,
      getToken()
    );
    onHabilitarUsuario(modifyUser, respuesta);
  };

  const handleSetListado = async () => {
    if (!comercioSelected) {
      setListado([]);
      return;
    }
    try {
      showLoader();
      const token = getToken();
      const idComercio = comercioSelected.codigo;
      const usuarios = await obtenerUsuariosPorIdComercio(idComercio, token);
      setListado(usuarios);
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    handleSetListado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comercioSelected]);

  const handleEdit = (row) => {
    navigate(`/usuarios-comercios/editar-usuario`, { state: { usuario: row } });
  };

  const handleSelectComercio = (commerce) => {
    setComercioSelected(commerce);
    localStorage.setItem(SELECTED_COMMERCE, JSON.stringify(commerce));
  };

  const handleUnSelectComercio = () => {
    setComercioSelected(null);
    localStorage.removeItem(SELECTED_COMMERCE);
  };

  const handleValidarSeleccionarButton = (e) => {
    if (!comercioSelected || comercioSelected.codigo === -1) {
      e.preventDefault();
      showNotification("error", "Por favor, Seleccione un comercio.");
    }
  };
  const handleEstablecerComercios = async () => {
    try {
      const listadoComercios = await obtenerTodosLosComerciosRequest(
        getToken()
      );
      const comerciosHabilitados = listadoComercios.filter((x) => x.habilitado);
      comerciosHabilitados.sort((a, b) =>
        a.nombreComercio.localeCompare(b.nombreComercio)
      );
      setComercios(parsearComerciosACombobox(comerciosHabilitados));
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };
  const establecerComercioInicial = () => {
    try {
      if (comercios.length === 0) return;
      if (localStorage.getItem(USUARIO_COMERCIO_INPAGE) === "true") {
        const data = JSON.parse(localStorage.getItem(SELECTED_COMMERCE));
        handleSelectComercio(data);
      }
      localStorage.setItem(USUARIO_COMERCIO_INPAGE, "false");
    } catch {}
  };

  useEffect(() => {
    handleEstablecerComercios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    establecerComercioInicial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comercios]);

  const openDialog = () => {
    if (!comercioSelected || comercioSelected.codigo === -1) {
      showNotification("error", "Por favor, seleccione un comercio.");
      return;
    }
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const onSelectUser = () => {
    handleSetListado();
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        MANTENEDOR DE USUARIOS COMERCIO
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={6} lg={3}>
            <label style={{ color: "black" }}>SELECCIONAR COMERCIO</label>
            <Combobox
              rows={comercios || []}
              placeholder="Comercio"
              onFilter={() => {}}
              onSelect={handleSelectComercio}
              onUnSelect={handleUnSelectComercio}
              style={{ width: "100%" }}
              selected={comercioSelected}
            />
          </Grid>
        </Grid>
        <br />
        <Divider />
        <br />
        {comercioSelected && comercioSelected.codigo !== -1 && (
          <>
            <Typography sx={{ margin: "0px" }} variant="h6">
              Comercio: {comercioSelected.nombre}
            </Typography>

            <Typography sx={{ margin: "0px" }} variant="h6">
              Rut: {comercioSelected.rut || "No disponible"}
            </Typography>
          </>
        )}

        <br />
        {!comercioSelected ? (
          <Typography textAlign="center">
            No hay ningún comercio seleccionado
          </Typography>
        ) : (
          <>
            <UsuariosLayout
              listado={listado}
              onEnableDisable={handleEnableDisable}
              onEdit={handleEdit}
              labelAddButton={"USUARIO EXISTENTE"}
              labelAddUserButton={"CREAR USUARIO"}
              handleValidarSeleccionarButton={handleValidarSeleccionarButton}
              isButtonDisabled={
                !comercioSelected || comercioSelected.codigo === -1
              }
              onAddButtonClick={openDialog}
            />
            <SeleccionarUsuarioComercioDialog
              open={dialogOpen}
              onClose={closeDialog}
              onSelectUser={onSelectUser}
              idComercio={comercioSelected.codigo}
            />
          </>
        )}
      </ShadowBox>
    </>
  );
};

export default UsuariosComercios;
