import React from 'react';
import Button from './Button';

export default function RoundButton(props) {
  let classes = "round";
  if (props.variant === 'outlined') {
    classes += " outlined"
  }
  return (
    <Button type={props.type || "button"} 
    onClick={props.onClick} 
    className={classes}
    disabled={props.disabled}
    style={{height: 'fit-content'}}
    >
      {props.children}
      </Button>
  )
}
