import { getApiUrl, RequestContentType, getRequest, postFormDataRequest, putRequest } from "../apiConnection";

const API_URL = getApiUrl();

export async function leerDonacionPorIdComercio(idComercio, token) {
    return await getRequest(API_URL, `/api/donaciones?idComercio=${idComercio}`, token);
}
export async function leerDonacionesRequest(token) {
    return await getRequest(API_URL, "/api/donaciones", token);
}

export async function editarDonacionRequest(data, idComercio, token) {
    return await putRequest(API_URL, `/api/donaciones/${idComercio}`, RequestContentType.APPLICATION_JSON, data, token);
}

export async function uploadFileRequest(formdata, token) {
    return await postFormDataRequest(API_URL, "/api/donaciones/uploadimage", formdata, token);
}