import React, { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import "./styles.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ComerciosTableRow from "./ComerciosTableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { compararASC, compararDESC } from "../../utils/utilidades";
import { useTheme } from '@mui/material/styles';

export default function ComerciosTable(props) {
  const theme = useTheme();
  const { listado, filterText } = props;
  const [filteredListado, setFilteredListado] = useState([]);
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: 10,
    count: 1,
    page: 0,
  });
  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };
  const handleRowsPerPageChange = (e) => {
    setPaginationControl({ ...paginationControl, page: 0, rowsPerPage: e.target.value });
  };

  useEffect(() => {
    const array = listado.filter(
      (comercio) =>
        comercio.idComercio.toString().includes(filterText.toLowerCase()) ||
        (comercio.nombreComercio &&
          comercio.nombreComercio
            .toLowerCase()
            .includes(filterText.toLowerCase())) ||
        comercio.rut.includes(filterText.toLowerCase())
    );
    setFilteredListado(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, listado]);

  const [sorting, setSorting] = useState({
    atributo: "",
    order: "asc",
  });

  const handleSort = (atributo, callback) => {
    if (sorting.atributo === atributo) {
      const order = sorting.order === "asc" ? "desc" : "asc";
      setSorting({ ...sorting, order: order });
      callback(order);
      return;
    }
    setSorting({ atributo: atributo, order: "asc" });
    callback("asc");
  };
  const handleSortNombreComercio = (order) => {
    setPaginationControl({ ...paginationControl, page: 0 });
    if (order === "asc") {
      setFilteredListado(listado.sort((a, b) => compararASC(a, b, "nombreComercio")));
      return;
    }
    setFilteredListado(listado.sort((a, b) => compararDESC(a, b, "nombreComercio")));
  };
  const inicioCorte = () => {
    return (paginationControl.page * paginationControl.rowsPerPage)
  }
  const corteFinal = () => {
    return paginationControl.rowsPerPage * paginationControl.page + paginationControl.rowsPerPage
  }
  const handleSortNroComercio = (order) => {
    setPaginationControl({ ...paginationControl, page: 0 });
    if (order === "asc") {
      setFilteredListado(listado.sort((a, b) => compararASC(a, b, "numeroComercioGetnet")));
      return;
    }
    setFilteredListado(listado.sort((a, b) => compararDESC(a, b, "numeroComercioGetnet")));
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={
          {
            minWidth: 650,
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              width: 'auto',
              overflowX: 'auto',
            }
          }
        }
        aria-label="Tabla comercios" size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '1px', whiteSpace: "nowrap" }}></TableCell>
            <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
              <TableSortLabel
                active={sorting.atributo === 'numeroComercioGetnet'}
                direction={sorting.order}
                onClick={()=> {handleSort("numeroComercioGetnet", handleSortNroComercio)
                }}
              >

              <Typography><b>N° COMERCIO</b></Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: '1px', whiteSpace: "nowrap" }}>
              <TableSortLabel
                active={sorting.atributo === "nombre_comercio"}
                direction={sorting.order}
                onClick={() => {
                  handleSort("nombre_comercio", handleSortNombreComercio);
                }}
              >
                <Typography><b>COMERCIO</b></Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell colSpan={2} sx={{ width: '1px', whiteSpace: "nowrap" }}>
              <Typography><b>CREDENCIALES</b></Typography>
            </TableCell>
            <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
              <Typography><b></b></Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="">
          {filteredListado.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} style={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          ) : (
            filteredListado.slice(inicioCorte(), corteFinal()).map((row, idx) => {
              return (
                <ComerciosTableRow
                  key={idx}
                  row={row}
                  onEdit={props.onEdit}
                  onDelete={props.onDelete}
                />
              );
            })
          )
          }

        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredListado.length}
        rowsPerPage={paginationControl.rowsPerPage}
        page={paginationControl.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </TableContainer>
  );
}
