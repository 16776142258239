import React from 'react'

const styles = {
    container: {
        padding: '2rem',
        backgroundColor: '#AAAAFF22',
        height: '100vh',
        width: '100vw',
        margin: '0px',
        boxSizing: 'border-box'
    },
    status: {
      color: "#096937"
    }
}
export default function HealthCheck() {
  return (
    <div style={styles.container}>
        <h1>Status: <span style={styles.status}>200</span></h1>
        <h2>La aplicación está funcionando correctamente</h2>
    </div>
  )
}
