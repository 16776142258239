import { Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { useLoader } from "../../context/LoaderContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Input from "../Input/Input";

export default function GscanDialogTableRow({
  editMode,
  label,
  value,
  onChange,
}) {
  const { showNotification } = useLoader();

  const handleCopyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => showNotification("success", "Copiado al portapapeles"))
      .catch((error) => console.error(error));
  };

  const handleChange = (e) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <TableRow>
      <TableCell sx={{ width: "5.5rem" }}>
        <div style={{ height: "1rem" }}>{label}</div>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {editMode ? (
            <Input
              value={value}
              onChange={handleChange}
              className="input"
              style={{ width: "365%" }}
            />
          ) : (
            <div style={{ paddingLeft: "8px" }}>{value}</div>
          )}
          <Box>
            <Tooltip title="Copiar al portapapeles" placement="left">
              <IconButton
                sx={{ ml: 1, visibility: editMode ? "collapse" : "visible" }}
                onClick={() => handleCopyToClipboard(value)}
              >
                <ContentCopyIcon className="edit-icon" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}
