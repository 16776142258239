import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutHandler } from '../../connection/loginController';

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    logoutHandler();
    
    navigate('/login');
  }, [navigate]);

  return <></>;
};

export default Logout;
