import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { Toolbar, useMediaQuery } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useTheme } from "@emotion/react";
import { getToken } from "../connection/tokenController";
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DescriptionIcon from '@mui/icons-material/Description';
// import StorefrontIcon from '@mui/icons-material/Storefront';
import { obtenerAppComercio, obtenerListadoApps } from "../connection/mantenedores/aplicacionesMantenedor";
import {COMERCIO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, ADMIN_ROLE_VALUE, DESARROLLO_ROLE_VALUE, getIdComercio, getRolUsuario } from "../connection/loginController";

const estilos = {
  content: {
    padding: "1rem",
    background: "linear-gradient(0deg, #D4E8FF, white)",
    height: 'calc(100vh - 100px)',
    overflow: 'auto'
  },
};

const sidebarPages = [
  { id: "comercios", rol: [DESARROLLO_ROLE_VALUE, COMERCIO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], name: "COMERCIOS", icon: <StoreIcon />, selected: false, to: "/comercios" },
  { hide: true, to: '/comercios/nuevo-comercio', directed_id: 'comercios' },
  { hide: true, to: '/comercios/editar-comercio', directed_id: 'comercios' },
  { hide: true, to: '/comercios/aplicaciones/*', directed_id: 'comercios' },
  { id: "aplicaciones", rol: [DESARROLLO_ROLE_VALUE], name: "APLICACIONES", icon: <AppRegistrationIcon />, selected: true, to: "/aplicaciones" },
  { hide: true, to: '/aplicaciones/crear-aplicacion', directed_id: 'aplicaciones' },
  { hide: true, to: '/aplicaciones/editar-aplicaciones/*', directed_id: 'aplicaciones' },
  { id: "pos", rol: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], name: "POS", icon: <PhonelinkSetupIcon />, selected: true, to: "/pos" },
  { hide: true, to: '/pos/nuevo-pos', directed_id: 'pos' },
  { hide: true, to: '/pos/editar-pos', directed_id: 'pos' },
  { id: "usuarios", rol: [ADMIN_ROLE_VALUE], name: "USUARIOS GETNET", icon: <GroupIcon />, selected: true, to: "/usuarios" },
  { hide: true, to: '/usuarios/nuevo-usuario', directed_id: 'usuarios' },
  { hide: true, to: '/usuarios/editar-usuario', directed_id: 'usuarios' },
  { id: "usuarios-comercios", rol: [ADMIN_ROLE_VALUE], name: "USUARIOS COMERCIOS", icon: <AssignmentIndIcon />, selected: true, to: "/usuarios-comercios" },
  { hide: true, to: '/usuarios-comercios/nuevo-usuario', directed_id: 'usuarios-comercios' },
  { hide: true, to: '/usuarios-comercios/editar-usuario', directed_id: 'usuarios-comercios' },
  { hide: true, to: '/usuarios-comercios/seleccionar-usuario-comercio', directed_id: 'usuarios-comercios' },
  { hide: true, to: '/usuarios-comercios/nuevo-usuario-comercio', directed_id: 'usuarios-comercios' },
  { id: "usuarios-desarrollo", rol: [ADMIN_ROLE_VALUE], name: "USUARIOS DESARROLLO", icon: <ManageAccountsIcon />, selected: true, to: "/usuarios-desarrollo" },
  { hide: true, to: '/usuarios/nuevo-usuario-desarrollo', directed_id: 'usuarios-desarrollo' },
  { hide: true, to: '/usuarios/editar-usuario-desarrollo', directed_id: 'usuarios-desarrollo' },
  {
    id: "Transacciones", rol: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], name: "TRANSACCIONES", icon: <PointOfSaleIcon />, children: [
      { id: "c2ctxs", rol: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], name: "C2C TXS", icon: <ReceiptLongIcon />, selected: false, app: 'c2c', to: "/c2c-txs" },
      { id: "gscantxs", rol: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], name: "G-SCAN TXS", icon: <ReceiptLongIcon />, selected: false, app: 'g-scan', to: "/g-scan-txs" },
      { id: "dontxs", rol: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], name: "DON TXS", icon: <ReceiptLongIcon />, selected: false, app: 'donaciones', to: "/donaciones-txs" },
      // { id: "multicomerciostxs", rol: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], name: "MULTI-COMERCIOS TXS", icon: <ReceiptLongIcon />, selected: false, app: 'multi-comercios', to: "/multi-comercios-txs" },
    ]
  },
  { id: "getscan", rol: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], name: "G-SCAN", icon: <CenterFocusWeakIcon />, selected: false, app: 'g-scan', to: "/getnetscan" },
  { id: "donaciones", rol: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], name: "DONACIONES", icon: <VolunteerActivismIcon />, selected: false, app: 'donaciones', to: "/donaciones" },
  { hide: true, to: '/donaciones/editar-donacion/*', directed_id: 'donaciones' },
  { hide: true, to: '/donaciones/habilitar-donaciones', directed_id: 'donaciones' },
  { id: "logs", rol: [ADMIN_ROLE_VALUE], name: "LOGS", icon: <DescriptionIcon />, selected: false, to: "/logs" },
  // { id: "multi-comercios",rol: [DESARROLLO_ROLE_VALUE, COMERCIO_ROLE_VALUE, OPERACIONES_ROLE_VALUE],name: "MULTICOMERCIOS", icon: <StorefrontIcon />,selected: false,to: "/multi-comercios"},
  // { hide: true, to: '/multi-comercios/nuevo-multi-comercios', directed_id: 'multi-comercios' },
  // { hide: true, to: '/multi-comercios/editar-multi-comercios', directed_id: 'multi-comercios'}
];

export default function HomeLayout() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [sidebar, setSidebar] = useState([]);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const rol = getRolUsuario();

  useEffect(() => {
    sidebarPagesFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rol]);

  const sidebarPagesFunction = async () => {
    try {
      if (!isTokenValid()) {
        throw new Error('Token no válido');
      }

      let pages = [...sidebarPages];
      if (rol !== DESARROLLO_ROLE_VALUE) {
        pages = pages.filter(page => page.id !== "aplicaciones");
      }

      if (rol === DESARROLLO_ROLE_VALUE || rol === ADMIN_ROLE_VALUE || rol === OPERACIONES_ROLE_VALUE) {
        setSidebar(pages);
        return;
      }

      const [appComercio, appHabilitadas] = await Promise.all([
        obtenerAppComercio(getIdComercio(), getToken()),
        obtenerListadoApps(getToken())
      ]);
      const nombresApps = appComercio
        .map(({ idApps }) => appHabilitadas.find(app => app.idApps === idApps)?.nombre.toLowerCase())
        .filter(Boolean);

      const newSidebar = pages.flatMap(({ app, children, ...rest }) => {
        if (!app) {
          const filteredChildren = children?.filter(child => !child.app || nombresApps.includes(child.app)) || [];
          return filteredChildren.length > 0 ? [{ ...rest, children: filteredChildren }] : !children ? [rest] : [];
        }
        return nombresApps.includes(app) ? [{ app, ...rest, children }] : [];
      });
      setSidebar(newSidebar);
    } catch (error) {
      console.error('Error al generar el sidebar:', error);
    }
  };

  const isTokenValid = () => {
    const token = getToken();
    return Boolean(token);
  };

  useEffect(() => {
    if (!isTokenValid()) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <Navbar onHamburgerClick={toggleDrawer} />
      <Sidebar
        open={openDrawer}
        onClose={toggleDrawer}
        pages={sidebar}
        isDesktop={isDesktop}
      />
      <Toolbar />
      <div style={{ ...estilos.content, marginLeft: isDesktop ? "240px" : "0px" }}>
        <Outlet />
      </div>
    </div>
  );
}
