import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import React from "react";
import { ReactComponent as RequetSend } from "../../resources/icons/request_send.svg";

export default function LogsTableRow({ log, onShowDatos }) {
  const isGrouped = log.historial && log.historial.length > 0;

  return (
    <TableRow>
      <TableCell sx={{ textAlign: "center" }}>
        {log.tabla || log.nombreTabla}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>{log.campo}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {isGrouped
          ? `${""} `
          : log.datos || "Sin datos"}
        {isGrouped && (
          <Tooltip title="Mostrar Historial">
            <IconButton
              onClick={() => {
                onShowDatos(log);
              }}
            >
              <RequetSend
                style={{ height: "1.4rem", fill: "#444444" }}
                title=""
              />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
