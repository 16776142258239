import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function capitalizeFirstLetter(text) {
  return text
    .replace(/(?:^|\.\s*)(\w)/g, (match, p1) => p1.toUpperCase())
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());
}

export default function CustomBreadcrumbs(props) {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "1rem" }}>
      {props.items.map((item, key) => {
        const formattedLabel = capitalizeFirstLetter(item.label);
        if (item.href) {
          return (
            <Typography
              key={key}
              sx={{ "&:hover": { textDecoration: "underline" } }}
              color="inherit"
            >
              <Link
                to={item.href}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {formattedLabel}
              </Link>
            </Typography>
          );
        }
        return (
          <Typography key={key} color="text.primary">
            {formattedLabel}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
}
