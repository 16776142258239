import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function LogDialog(props) {
  const { historial, tabla, campo } = props;

  const [sorting, setSorting] = useState({
    atributo: "fecha",
    order: "desc",
  });

  const handleClose = () => {
    props.onClose();
  };

  const handleSort = (atributo) => {
    const isAsc = sorting.atributo === atributo && sorting.order === "asc";
    setSorting({
      atributo,
      order: isAsc ? "desc" : "asc",
    });
  };

  const sortedHistorial = [...historial].sort((a, b) => {
    if (sorting.atributo === "fecha") {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);
      return sorting.order === "asc" ? fechaA - fechaB : fechaB - fechaA;
    }
    return 0;
  });

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          HISTORIAL
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" gutterBottom>
            <b>TABLA:</b> {tabla}
          </Typography>
          <Typography variant="h6" gutterBottom>
            <b>CAMPO:</b> {campo}
          </Typography>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small" aria-label="tabla de historial">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sorting.atributo === "fecha"}
                      direction={sorting.order}
                      onClick={() => handleSort("fecha")}
                      hideSortIcon={!sorting.atributo === "fecha"}
                    >
                      <b>Fecha</b>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <b>Usuario</b>
                  </TableCell>
                  <TableCell>
                    <b>Dato Antes</b>
                  </TableCell>
                  <TableCell>
                    <b>Dato Después</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedHistorial && sortedHistorial.length > 0 ? (
                  sortedHistorial.map((log, index) => {
                    const fecha = new Date(log.fecha);

                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ textAlign: "left" }}>
                          {fecha.toLocaleDateString("es-CL")}
                          <div
                            style={{ fontSize: "12px", fontStyle: "italic" }}
                          >
                            {fecha.toLocaleTimeString("es-CL")}
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            maxWidth: 100,
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {log.nombreUsuario || "N/A"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            maxWidth: 150,
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {log.datoAntes}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            maxWidth: 150,
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {log.datoDespues}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No hay datos en el historial.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
