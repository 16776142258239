import React, { useState } from "react";
import { obtenerUsuarioPorEmailRequest } from "../../../connection/mantenedores/usuarioMantenedor";
import { getToken } from "../../../connection/tokenController";
import { useLoader } from "../../../context/LoaderContext";
import {
  Divider,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import RoundButton from "../../../components/RoundButton";
import Input from "../../../components/Input/Input";
import SUsuarioComercio from "../../../components/UsuariosLayout/SUsuarioComercio";
import {
  crearUsuarioComercioRequest,
  obtenerComerciosPorIdUsuarioRequest,
} from "../../../connection/mantenedores/usuarioComercioMantenedor";
import { validarEmail } from "../../../utils/utilidades";

const SeleccionarUsuarioComercioDialog = ({
  open,
  onClose,
  onSelectUser,
  idComercio,
}) => {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [listado, setListado] = useState([]);
  const [email, setEmail] = useState("");
  const [lastEmail, setLastEmail] = useState("");

  const handleChangeEmail = (event) => {
    const trimmedValue = event.target.value.trim();
    setEmail(trimmedValue);
  };

  const handleSearch = async () => {
    showLoader();
    try {
      if (email.length < 3 || !validarEmail(email)) {
        showNotification("error", "Ingrese un email válido");
        return;
      }
      setLastEmail(email);
      await obtenerUsuarios(email);
    } catch (error) {
      showNotification("error", "Ha ocurrido un error inesperado");
      console.error(error);
    } finally {
      hideLoader();
      setEmail("");
    }
  };
  const obtenerUsuarios = async (correo) => {
    let usuarios = await obtenerUsuarioPorEmailRequest(
      correo.toLocaleLowerCase(),
      getToken()
    );
    usuarios = usuarios.filter((x) => x.rol === "Comercios");
    if (usuarios.length === 0) {
      showNotification("error", "Usuario no existe");
      return;
    }
    const idComerio = idComercio;
    const listadoFinal = await Promise.all(
      usuarios.map(async (item) => {
        const listadoComercios = await obtenerComerciosPorIdUsuarioRequest(
          item.idUsuario,
          getToken()
        );
        const existe = listadoComercios.find((x) => x.idComercio === idComerio);
        if (existe) return { ...item, disabled: true };
        return { ...item, disabled: false };
      })
    );
    setListado(listadoFinal);
  };

  const handleSelect = async (row) => {
    showLoader();
    try {
      const idUsuario = row.idUsuario;
      const respuesta = await crearUsuarioComercioRequest(
        idUsuario,
        idComercio,
        getToken()
      );
      if (respuesta.idUsuarioComercio) {
        showNotification("success", "Usuario seleccionado correctamente!");
        obtenerUsuarios(lastEmail);
        onSelectUser();
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } catch (error) {
      showNotification("error", "Ha ocurrido un error inesperado");
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleClose = () => {
    setListado([]);
    setEmail("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "750px",
          height: "350px",
        },
      }}
    >
      <DialogTitle>INGRESAR EMAIL DE USUARIO REGISTRADO</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
        </div>
        <Divider />
        <br />
        <br />
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Input
                value={email}
                onChange={handleChangeEmail}
                style={{
                  marginRight: "150px",
                  maxWidth: "90%",
                  width: "100%",
                }}
                placeholder="Buscar por email..."
              />
              <RoundButton
                variant="outlined"
                onClick={handleSearch}
                style={{ padding: "10px 20px", fontSize: "12px" }}
              >
                BUSCAR
              </RoundButton>
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
        <SUsuarioComercio
          listado={listado}
          onSelect={handleSelect}
          addUserRoute="/usuarios-seleccionar/nuevo-usuario"
          comercioSeleccionado={
            idComercio ? idComercio.nombre : "NOMBRE COMERCIO"
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SeleccionarUsuarioComercioDialog;
