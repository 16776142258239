import React, { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import { green, red, grey } from "@mui/material/colors";
import PosDialog from "../../components/PosDialog/PosDialog";
import {
  autenticarComerciosRequest, validarConexionRequest,
} from "../../connection/mantenedores/posMantenedor";
import { getToken } from "../../connection/tokenController";
import { obtenerComercioPorIdRequest } from "../../connection/mantenedores/comercioMantenedor";
import "./styles.css";
import { capitalizarPrimeraLetra } from "../../utils/utilidades";

const EstadoConexiones = {
  CONECTADO: "conectado",
  DESCONECTADO: "desconectado",
  NORMAL: "normal",
};

export default function PosTableRow(props) {
  const { row, variant, onEdit } = props;
  const [conexionEstados, setConexionEstados] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSerialNumber, setSelectedSerialNumber] = useState("");
  const [pollStatus, setPollStatus] = useState({
    status: "Probando Conexión",
    message: "Esperando Respuesta...",
  });
  const [isLoading, setIsLoading] = useState(false);

  const getIconColor = (serialNumber) => {
    const estado = conexionEstados[serialNumber] || EstadoConexiones.NORMAL;
    switch (estado) {
      case EstadoConexiones.CONECTADO:
        return green[500];
      case EstadoConexiones.DESCONECTADO:
        return red[500];
      case EstadoConexiones.NORMAL:
        return grey[500];
      default:
        return grey[500];
    }
  };

  const handlePoll = async () => {
    setPollStatus({
      status: "Probando Conexión",
      message: "Esperando Respuesta...",
    });
    setIsLoading(true);
    try {
      const { idTerminal, idSucursal, serialNumber, idComercio } = props.row;
      const body = {
        idTerminal: String(idTerminal),
        idSucursal: idSucursal,
        serialNumber: serialNumber,
        command: 106,
      };
      const token = getToken();
      const comercio = await obtenerComercioPorIdRequest(idComercio, token);

      if (!comercio || !comercio.clientId || !comercio.clientSecret) {
        throw new Error("Datos de comercio incompletos");
      }

      const data = {
        clientId: comercio.clientId,
        clientSecret: comercio.clientSecret,
      };
      const resToken = await autenticarComerciosRequest(data);
      const authToken = resToken.data.token;

      if (!authToken) {
        throw new Error("Error de autenticación de comercio");
      }

      const resPoll = await validarConexionRequest(body, authToken);

      if (!resPoll || !resPoll.status || !resPoll.message) {
        throw new Error("Error en la respuesta de validación de conexión");
      }
      setPollStatus({ status: resPoll.status, message: resPoll.message });

      const newConexionEstados = { ...conexionEstados };
      if (resPoll.status === "OK") {
        newConexionEstados[serialNumber] = EstadoConexiones.CONECTADO;
      } else {
        newConexionEstados[serialNumber] = EstadoConexiones.DESCONECTADO;
      }
      setConexionEstados(newConexionEstados);
    } catch (err) {
      const errorResponse = await obtenerError(err);
      const errorMessage =
        errorResponse ||
        "Keypos no encontrado para esta solicitud. Se debe configurar el POS";

      setPollStatus({ status: "Error", message: errorMessage });

      const newConexionEstados = { ...conexionEstados };
      newConexionEstados[props.row.serialNumber] =
        EstadoConexiones.DESCONECTADO;
      setConexionEstados(newConexionEstados);

    } finally {
      setIsLoading(false);
    }
  };
  const obtenerError = async (err) => {
    try {
      const error = await err.json();
      return capitalizarPrimeraLetra(error.message).replaceAll("c2c", "C2C");
    }catch {
      return null;
    }
  }
  const handleDialogOpen = (serialNumber) => {
    setSelectedSerialNumber(serialNumber);
    setPollStatus({
      status: "Probando Conexión",
      message: "Esperando Respuesta...",
    });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedSerialNumber("");
  };

  if (variant) {
    return (
      <TableRow>
        <TableCell colSpan={7} sx={{ textAlign: "center" }}>
          No hay resultados
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <IconButton aria-label="Editar" onClick={() => onEdit(row)}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        <Typography>{row.idTerminal}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{row.idSucursal}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{row.serialNumber}</Typography>
      </TableCell>
      <TableCell>
        <Tooltip title="Probar conexión" placement="left">
          <IconButton
            aria-label="Estado de Conexión"
            onClick={() => {
              handlePoll();
              handleDialogOpen(row.serialNumber);
            }}
          >
            <TapAndPlayIcon
              className="icon-table"
              style={{ color: getIconColor(row.serialNumber) }}
            />
          </IconButton>
        </Tooltip>
        <PosDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          serialNumber={selectedSerialNumber}
          pollStatus={pollStatus}
          isLoading={isLoading}
          handlePoll={handlePoll}
        />
      </TableCell>
    </TableRow>
  );
}
