import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { DialogContent } from "@mui/material";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import SecurityUpdateWarningIcon from "@mui/icons-material/SecurityUpdateWarning";
import "../../components/PosDialog/style.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const PosDialogContent = ({ serialNumber, pollStatus, isLoading }) => {
  const statusText =
    pollStatus.status === "OK" ? "POS Conectado" : pollStatus.status;
  return (
    <DialogContent dividers>
      <div className="icon-container">
        {isLoading ? (
          <>
            <TapAndPlayIcon className="posdialog-icon pos-icon" />
            <div className="dots-container">
              <div className="dots">.</div>
              <div className="dots">.</div>
              <div className="dots">.</div>
              <div className="dots">.</div>
            </div>
            <CloudQueueIcon className="posdialog-icon cloud-icon" />
          </>
        ) : pollStatus.status === "OK" ? (
          <SecurityUpdateGoodIcon className="posdialog-icon success-icon" />
        ) : (
          <SecurityUpdateWarningIcon className="posdialog-icon warning-icon" />
        )}
      </div>
      <div className="text-content">
        <h1 className="dialog-title">{statusText}</h1>
        <h1 className="dialog-description1">S/N: {serialNumber}</h1>
        <p className="dialog-description">{pollStatus.message}</p>
      </div>
    </DialogContent>
  );
};

export default function PosDialog({
  open,
  onClose,
  serialNumber,
  pollStatus,
  isLoading,
  handlePoll,
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        Conexión POS
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <PosDialogContent
        serialNumber={serialNumber}
        pollStatus={pollStatus}
        isLoading={isLoading}
      />

      <DialogActions>
        <Button onClick={handlePoll} color="primary" disabled={isLoading}>
          Reintentar
        </Button>
        <Button onClick={onClose} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}