import React from 'react'
import './styles.css'

export default function Button(props) {
  let styles = props.style || {};
  let className = props.className || "";
  if (props.largeButton){
    styles.width = "100%";
  }
  if (props.variant === "gray") {
    className += " gray_button";
  }
  if (props.disabled) {
    className += " disabled"
  }
  
  const handleClick = (e) => {
    if (props.onClick)
        props.onClick(e);
  }
  return (
    <button disabled={props.disabled} type={props.type || "button"} onClick={handleClick} style={styles} className={className}>{props.children}</button>
  )
}
