import React from 'react';
import './styles.css'

export default function CustomSelect({onChange, listado, variant, valueName, text, value}) {
    const handleChange = (e) => {
        if (onChange)
            onChange(e);
    }
  return (
    <select className='customselect' onChange={handleChange} value={value}>
        {
            listado.length === 0 ? <></> : 
            listado.map((x, key) => {
                if (variant === "objects")
                    return <option key={key} value={x[valueName]}>{x[text]}</option>
                return <option key={key}>{x}</option>
            })
        }
    </select>
  )
}
