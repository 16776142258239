import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react';
import { useLoader } from '../context/LoaderContext';

export default function Loader() {
  const { open, hideLoader } = useLoader();
  return (
    <Backdrop 
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 99}}
        open={open}
        onClick={hideLoader}
        >
        <CircularProgress color='inherit'/>
    </Backdrop>
  )
}
