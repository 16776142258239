import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function SidebarLink(props) {
  const { item, onSelect, isSelected, child } = props;

  const handleSelect = () => {
    if (props.onSelect)
      onSelect(item.id);
  };
  const estiloListItem = {
    backgroundColor: (isSelected && "#FF4040 !important"),
    color: (isSelected && 'white'),
  }

  return (
    <ListItemButton
      component={Link}
      to={item.to}
      onClick={handleSelect}
      selected={isSelected}
      sx={estiloListItem}
    >
      <ListItemIcon sx={{ paddingLeft: (child && '0.5rem'), color: (isSelected && 'white') }}>
        {item.icon}
      </ListItemIcon>
      <ListItemText >{item.name}</ListItemText>
    </ListItemButton>
  );
}
