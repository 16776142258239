import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SidebarLink from './SidebarLink';

function handleCheckPageSelected(items) {
    const childPage = items.find(x => x.to === window.location.pathname);
    return childPage ? true : false;
}

export default function SidebarAccordion(props) {
    const { title, items, onSelect, selectedId } = props;
    const [open, setOpen] = React.useState(handleCheckPageSelected(items));

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding >
                    {
                        items.map((item, key) => {
                            return (
                                <SidebarLink
                                    child
                                    key={key}
                                    item={item}
                                    onSelect={onSelect}
                                    isSelected={selectedId === item.id}
                                />
                            )
                        })
                    }
                </List>
            </Collapse>
        </>
    )
}
