import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import { obtenerComercioPorIdRequest, obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import { editarDonacionRequest, leerDonacionPorIdComercio, leerDonacionesRequest } from "../../connection/mantenedores/donacionesMantenedor";
import { getToken } from "../../connection/tokenController";
import "./styles.css";
import FiltroTexto from "../../components/FiltroTexto";
import DonacionesTable from "../../components/DonacionesTable";
import RoundButton from "../../components/RoundButton";
import Dialogo from "../../components/Dialogo";
import { getIdComercio, isComercioUserRole, isLoginComercio } from "../../connection/loginController";
import DonacionesConfiguraciones from "../../components/DonacionesTable/DonacionesConfiguraciones";
import emptyImg from '../../resources/images/empty.png';
import { APP_DONACIONES, obtenerAppsHabilitadas } from "../../connection/AppsController";

const estilos = {
  divRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  }
}


export default function Donaciones() {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [listadoDonaciones, setListadoDonaciones] = useState([]);
  const [listadoFiltrado, setListadoFiltrado] = useState([]);
  const [textoFiltro, setTextoFiltro] = useState("");
  const [comercioOnly, setComercioOnly] = useState({});
  const [dialogo, setDialogo] = useState({
    open: false,
    idComercio: 0,
    nombreComercio: ''
  });
  const handleDeshabilitar = (row) => {
    setDialogo({ open: true, idComercio: row.idComercio, nombreComercio: row.nombreComercio });
  }
  const handleConfirmHabilitarComercio = async () => {
    showLoader();
    try {
      const data = { habilitado: false };
      const respuesta = await editarDonacionRequest(
        data,
        dialogo.idComercio,
        getToken()
      );
      if (respuesta.ok) {
        showNotification("success", "Comercio habilitado exitosamente");
        obtenerListadoDonaciones();
      }
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    }
    finally {
      hideLoader();
    }
  }
  const handleCancelDialog = () => {
    setDialogo({ ...dialogo, open: false });
  }


  const handleOpenRow = (row) => {
    let nuevoListado = listadoDonaciones.map((x, key) => {
      if (x.idComercio === row.idComercio)
        x.open = !x.open;
      return x
    });
    setListadoDonaciones(nuevoListado)
    setListadoFiltrado(filtrar(textoFiltro, nuevoListado))
  }

  const handleFiltrar = (e) => {
    setListadoFiltrado(filtrar(e, listadoDonaciones));
  }
  const filtrar = (texto, listado) => {
    return listado.filter(x => {
      if (texto.length === 0)
        return true;
      return x.nombreComercio.toLowerCase().includes(texto);
    });
  }
  const handleChangeFiltro = (e) => {
    setTextoFiltro(e.target.value)
  }
  const onlyComercio = async () => {
    showLoader();
    try {
      const onlyComercioData = await obtenerComercioPorIdRequest(getIdComercio(), getToken());
      const donacion = await leerDonacionPorIdComercio(getIdComercio(), getToken());
      donacion.nombreComercio = onlyComercioData.nombreComercio;
      setComercioOnly(donacion);
    } catch (error) {
      showNotification("error", "Ha ocurrido un error inesperado");
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const obtenerListadoDonaciones = async () => {
    if (isComercioUserRole()) {
      onlyComercio();
      return;
    }
    try {
      showLoader();
      const [comerciosList, donacionesList] = await Promise.all([
        obtenerTodosLosComerciosRequest(getToken()),
        leerDonacionesRequest(getToken())
      ]);
      
      const comercioMap = new Map();
      comerciosList.forEach(com => comercioMap.set(com.idComercio, com));

      const listado = donacionesList.map((donacion) => {
        const comercioFiltered = comercioMap.get(donacion.idComercio) || {};
        return {
          ...donacion,
          nombreComercio: comercioFiltered.nombreComercio || '',
          rut: comercioFiltered.rut || ''
        };
      });
      setListadoDonaciones(
        listado
          .filter(x => x.habilitado)
          .sort((a, b) => a.nombreComercio.localeCompare(b.nombreComercio, 'es', { sensitivity: 'base' }))
      );
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };
  const checkearValidRoute = async () => {
    if (!isLoginComercio()) return;
    const nombresApps = await obtenerAppsHabilitadas();
    if (!nombresApps.includes(APP_DONACIONES)) {
      navigate('/404');
    }
  }
  useEffect(() => {
    checkearValidRoute();
    obtenerListadoDonaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Typography variant="h5" sx={{ margin: '1rem 0' }}>PARAMETROS APP DONACIONES</Typography>
      {isComercioUserRole() ? (
        <ShadowBox style={{ padding: '2rem' }}>
          <div style={estilos.divRow}>
            <div>
              <label style={{ color: "black" }}>Logo</label>
              <br />
              <img alt="logo" src={comercioOnly.urlLogo || emptyImg} style={{ width: 'auto', height: '40px' }} />
            </div>
            <RoundButton
              variant="outlined" onClick={() => { navigate(`/donaciones/editar-donacion/${comercioOnly.idComercio}`) }}>
              Editar parámetros
            </RoundButton>
          </div>

          <br />
          <DonacionesConfiguraciones item={comercioOnly ? comercioOnly : {}} />
        </ShadowBox>
      ) : (
        <>
          <br />
          <ShadowBox style={{ padding: '2rem' }}>
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ marginBottom: '1rem' }}>
                <FiltroTexto type="text" onFilter={handleFiltrar} onChange={handleChangeFiltro} />
              </Grid>
            </Grid>

            <DonacionesTable listado={listadoFiltrado}
              editLink={'/donaciones/editar-donacion/'}
              onOpen={handleOpenRow}
              onDeshabilitar={handleDeshabilitar} />
          </ShadowBox>
          <Dialogo open={dialogo.open} onClose={handleCancelDialog} title="Deshabilitar donación en comercio" text={`Estás seguro de deshabilitar a ${dialogo.nombreComercio}`} onConfirm={handleConfirmHabilitarComercio} />
        </>
      )}
    </>
  );

}