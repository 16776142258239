import { useEffect, useState } from "react";
import "./styles.css";
import Eye from "./Eye";

function procesarValor(texto, min, max) {
  const valor = parseInt(texto);
  if (valor < min)
    return min;
  if (valor > max)
    return max;

  if (texto.length > 1 && texto[0] === "0")
    return texto.substring(1, texto.length);
  return texto;
}
export default function Input(props) {
  const [className, setClassName] = useState("");
  const [containerClassname, setContainerClassname] = useState("input_container");
  const [eyeOpen, setEyeOpen] = useState(false);
  const [showableType, setShowableType] = useState(props.type);

  const handleClassName = () => {
    let classes = props.className || "";
    if (props.error) classes += " error";
    if (props.variant === "combobox") classes += " combobox";
    if (props.disabled === true) classes += " disabled";
    setClassName(classes);
  };
  useEffect(() => {
    handleClassName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.className, props.error, props.disabled]);

  const onChangeEvent = (e) => {
    e.preventDefault();
    if (props.validate) props.validate(e);
    if (props.type === "number") {
      e.target.value = procesarValor(e.target.value, props.min, props.max);
    }
    if (props.onChange) props.onChange(e);
  };
  const handleFocus = (e) => {
    if (!props.onFocus) return;
    props.onFocus(e);
  }
  const handleBlur = (e) => {
    if (!props.onBlur) return;
    setTimeout(() => {
      props.onBlur(e);
    }, 100);
  };
  useEffect(() => {
    if (props.completeWidth) {
      setContainerClassname(addClassname(containerClassname, "complete-width"));
    }
    if (props.variant === "combobox") {
      setContainerClassname(addClassname(containerClassname, "combobox_container"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleEye = () => {
    const nuevoValor = !eyeOpen;
    setEyeOpen(nuevoValor);
    setShowableType(nuevoValor ? "text" : "password");
  }

  return (
    <div className={containerClassname}>
      <input
        type={showableType}
        placeholder={props.placeholder}
        required={props.required}
        onChange={onChangeEvent}
        value={props.value || ""}
        className={className}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={props.style || null}
        min={props.min || null}
        max={props.max || null}
        maxLength={props.maxLength || null}
        disabled={props.disabled ? true : false}
      />
      {
        props.type === "password" ? <Eye open={eyeOpen} onClick={handleToggleEye} /> : <></>
      }
      {props.error && (
        <>
          <br />
          <label>{props.errorText}</label>
        </>
      )}
    </div>
  );
}

const addClassname = (clases, nuevaClase) => {
  if (clases.length === 0) {
    return nuevaClase;
  }
  const split = clases.split(' ');
  if (!containedClass(split, nuevaClase)) {
    return clases += ` ${nuevaClase}`;
  }
  return clases;
}

const containedClass = (array, clase) => {
  return array.filter((x) => x.includes(clase)).length > 0;
}