import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LogsTableRow from "./LogsTableRow";
import "./styles.css";

const styles = {
  smallCell: {
    width: "1%",
    whiteSpace: "nowrap",
    padding: "1%",
    textAlign: "center",
    verticalAlign: "middle",
  },
};

export default function LogsTable(props) {
  const { agrupaciones, filterText } = props;
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: 10,
    page: 0,
  });

  const inicioCorte = () => {
    return paginationControl.page * paginationControl.rowsPerPage;
  };

  const corteFinal = () => {
    return (
      paginationControl.rowsPerPage * paginationControl.page +
      paginationControl.rowsPerPage
    );
  };

  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };

  const handleRowsPerPageChange = (e) => {
    setPaginationControl({
      ...paginationControl,
      page: 0,
      rowsPerPage: parseInt(e.target.value, 10),
    });
  };

  useEffect(() => {
    setPaginationControl({ ...paginationControl, page: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, agrupaciones]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 500,
        }}
        aria-label="Tabla Logs"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={styles.smallCell}>
              <b>TABLA</b>
            </TableCell>
            <TableCell sx={styles.smallCell}>
              <b>CAMPO</b>
            </TableCell>
            <TableCell sx={styles.smallCell}>
              <b>HISTORIAL</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="t-stripped t-highlight">
          {agrupaciones && agrupaciones.length > 0 ? (
            agrupaciones
              .slice(inicioCorte(), corteFinal())
              .map((agrupacion, index) => (
                <LogsTableRow
                  log={agrupacion}
                  key={index}
                  onShowDatos={props.onShowDatos}
                />
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No se encontraron Logs.
              </TableCell>
            </TableRow>
          ) 
        }
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={agrupaciones ? agrupaciones.length : 0}
        rowsPerPage={paginationControl.rowsPerPage}
        page={paginationControl.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </TableContainer>
  );
}