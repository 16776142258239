import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function TransaccionDialog(props) {
  const { request, response } = props;
  const handleClose = () => {
    props.onClose();
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>DATOS DE LA SOLICITUD</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={{ border: '1px solid #44444422', borderWidth: '0 1px 0 0', padding: '1rem' }}>
                <Typography variant="h6">SOLICITUD:</Typography>
                {!request ? (
                  <Typography>No hay datos</Typography>
                ) : (
                  <>
                    <Typography component="pre" style={{ whiteSpace: "pre-wrap" }}>
                      <code>{request}</code>
                    </Typography>
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ border: '1px solid #44444422', borderWidth: '0 0 0 1px', padding: '1rem' }}>
                <Typography variant="h6">RESPUESTA:</Typography>
                {!response ? (
                  <Typography>No hay datos</Typography>
                ) : (
                  <>
                    <Typography component="pre" style={{ whiteSpace: "pre-wrap" }}>
                      <code>{response}</code>
                    </Typography>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
