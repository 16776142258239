import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@mui/material'
import React from 'react'
import Button from './Button'

export default function DialogoUsuarioPasswordTemporal(props) {
    const handleConfirm = () => {
        if (props.onConfirm)
            props.onConfirm();
    }
    const handleCloseDialogo = () => {
        if (props.onClose)
            props.onClose();
    }
    return (
        <Dialog open={props.open} onClose={handleCloseDialogo}>
            <DialogTitle>{props.title}</DialogTitle>
            <Divider/>
            <DialogContent>
                <DialogContentText sx={{ color: "black" }}>
                    Se ha generado una contraseña de inicio que deberá ser actualizada
                    en el primer inicio de sesion
                </DialogContentText>
                <div style={{ padding: '0.5rem', color: 'black' }}>
                    <Typography>Correo electrónico: {props.email}</Typography>
                    <Typography>Contraseña: {props.password}</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={handleConfirm} >
                    CONTINUAR
                </Button>
            </DialogActions>
        </Dialog>
    )
}
