import React, { useEffect, useState } from "react";
import TableSortLabel from "@mui/material/TableSortLabel";
import "./styles.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { compararASC, compararDESC } from "../../utils/utilidades";
import RoundButton from "../RoundButton";
import { useLoader } from "../../context/LoaderContext";

export default function SeleccionUsuarioComercioTable(props) {
  const { listado, filterText, onSelect } = props;
  const { showNotification } = useLoader();
  const [filteredListado, setFilteredListado] = useState([]);
  const [sorting, setSorting] = useState({
    atributo: "",
    order: "asc",
  });
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: 10,
    count: 1,
    page: 0,
  });

  const inicioCorte = () =>
    paginationControl.page * paginationControl.rowsPerPage;
  const corteFinal = () =>
    paginationControl.rowsPerPage * paginationControl.page +
    paginationControl.rowsPerPage;

  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };

  const handleSort = (atributo, callback) => {
    handleChangePage(null, 0);
    if (sorting.atributo === atributo) {
      const order = sorting.order === "asc" ? "desc" : "asc";
      setSorting({ ...sorting, order: order });
      callback(order);
      return;
    }
    setSorting({ atributo: atributo, order: "asc" });
    callback("asc");
  };

  const handleSortNombre = (order) => {
    setPaginationControl({ ...paginationControl, page: 0 });
    if (order === "asc") {
      const list = listado.sort((a, b) => compararASC(a, b, "nombre"));
      setFilteredListado(list);
      return;
    }
    setFilteredListado(listado.sort((a, b) => compararDESC(a, b, "nombre")));
  };

  useEffect(() => {
    setPaginationControl({ ...paginationControl, page: 0 });
    const lista = filterText
      ? listado.filter(
          (user) =>
            (user.nombre &&
              user.nombre.toLowerCase().includes(filterText.toLowerCase())) ||
            (user.emailUsuario &&
              user.emailUsuario
                .toLowerCase()
                .includes(filterText.toLowerCase()))
        )
      : listado;
    setFilteredListado(lista);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, listado]);

  const handleSelect = (row) => {
    if (row.disabled) {
      showNotification(
        "error",
        "Este usuario está registrado en este comercio"
      );
      return;
    }
    onSelect(row);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Tabla" size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sorting.atributo === "nombre"}
                direction={sorting.order}
                onClick={() => {
                  handleSort("nombre", handleSortNombre);
                }}
              >
                <b>NOMBRE</b>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <b>EMAIL</b>
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              <b>SELECCIONAR</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="t-stripped t-highlight">
          {filteredListado.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <Typography>No se encontraron usuarios.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            filteredListado
              .slice(inicioCorte(), corteFinal())
              .map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell>{row.nombre.toUpperCase()}</TableCell>
                  <TableCell>{row.emailUsuario.toUpperCase()}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <RoundButton
                      variant="text"
                      color="default"
                      disabled={row.disabled}
                      onClick={() => handleSelect(row)}
                    >
                      {row.disabled ? "Registrado" : "Seleccionar"}
                    </RoundButton>
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
