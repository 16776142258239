import React, { useState } from "react";
import Dialogo from "../Dialogo";
import SeleccionUsuarioComercioTable from "../UserTable/SeleccionUsuarioComercioTable";

const SUsuarioComercio = ({ onSelect, listado }) => {
  const [openSelectDialog, setOpenSelectDialog] = useState(false);
  const [selectUser, setSelectUser] = useState({});

  const handleCloseDialogo = () => setOpenSelectDialog(false);

  const handleSelect = (row) => {
    setSelectUser(row);
    setOpenSelectDialog(true);
  };

  const handleConfirmSelect = async () => {
    onSelect(selectUser);
  };

  return (
    <>
      {listado.length > 0 && (
        <SeleccionUsuarioComercioTable
          listado={listado}
          onSelect={handleSelect}
        />
      )}
      <Dialogo
        title="Confirmar Selección"
        text={`¿Estás seguro que deseas seleccionar al usuario ${selectUser.nombre}?`}
        open={openSelectDialog}
        onClose={handleCloseDialogo}
        onConfirm={handleConfirmSelect}
      />
    </>
  );
};

export default SUsuarioComercio;
