import React from 'react';
import RoundButton from '../../components/RoundButton';
import { useNavigate } from 'react-router-dom';
import './styles.css';

export default function NotFound() {
    let navigate = useNavigate();
    const handleVolver = () => {
        navigate('/');
    }
  return (
    <div id="not_found">
    <h1>PÁGINA NO ENCONTRADA</h1>
    <p>Está página no existe o no se encuentra disponible</p>
    <RoundButton type="button" onClick={handleVolver}>Volver Atrás</RoundButton>
    </div>
  )
}
