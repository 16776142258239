import { Box, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "../GscanDialog/style.css";
import {
  WarningDialog,
  ConfirmationDialog,
} from "../GscanDialog/GscanDialogValidation";

export default function GscanDialogSectionHeader({
  title,
  editMode,
  switchState,
  disableSwitch,
  onSwitchChange,
  onEditClick,
  onEditConfirm,
  onEditCancel,
}) {
  const [warningOpen, setWarningOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationError, setConfirmationError] = useState(false);

  const handleSwitchChange = async (event) => {
    try {
      if (!event.target.checked) {
        setWarningOpen(true);
      } else {
        await onSwitchChange(event);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleConfirmWarning = async () => {
    setWarningOpen(false);
    try {
      await onSwitchChange({ target: { checked: false } });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseWarning = () => {
    setWarningOpen(false);
  };

  const handleConfirmClick = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    setConfirmationText("");
    setConfirmationError(false);
  };

  return (
    <Box className="section">
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <>
          <Switch
            checked={Boolean(switchState)}
            onChange={handleSwitchChange}
            disabled={disableSwitch}
            color="primary"
            sx={{ ml: 1, visibility: editMode ? "collapse" : "visible" }}
          />
          {!editMode && (
            <Tooltip title="Editar" placement="top">
              <span>
                <IconButton
                  size="large"
                  aria-label="edit"
                  onClick={onEditClick}
                  className="edit-icon"
                  disabled={Boolean(switchState)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {editMode && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Aceptar" placement="left">
                <IconButton
                  size="large"
                  onClick={handleConfirmClick}
                  className="edit-icon"
                >
                  <CheckIcon sx={{ color: "green" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancelar" placement="right">
                <IconButton
                  size="large"
                  onClick={onEditCancel}
                  className="cancel-icon"
                >
                  <CloseIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </>
      </Box>
      {/* dialogo de switch desahabilitado */}
      <WarningDialog
        open={warningOpen}
        onClose={handleCloseWarning}
        onConfirm={handleConfirmWarning}
      />
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={onEditConfirm}
        confirmationText={confirmationText}
        setConfirmationText={setConfirmationText}
        confirmationError={confirmationError}
        setConfirmationError={setConfirmationError}
      />
    </Box>
  );
}