import React from 'react'
import SidebarLink from './SidebarLink';
import SidebarAccordion from './SidebarAccordion';

export default function SidebarItem(props) {
    const { item, onSelect, selectedId } = props;
    return (
        <>
            {
                item.children ?
                    <SidebarAccordion
                        title={item.name}
                        items={item.children}
                        icon={item.icon}
                        onSelect={onSelect}
                        selectedId={selectedId} /> :
                    <SidebarLink
                        item={item}
                        onSelect={onSelect}
                        isSelected={selectedId === item.id}
                    />
            }
        </>
    )
}
