import { getApiUrl, RequestContentType, deleteRequest, getRequest, postRequest, putRequest } from "../apiConnection";

const API_URL = getApiUrl();

export async function obtenerUsuarioRequest(nombre, habilitado) {
    return await getRequest(API_URL, `/api/Usuarios?nombre=${nombre}&habilitado=${habilitado}`)
}
export async function obtenerUsuarioPorEmailRequest(email, token) {
    return await getRequest(API_URL, `/api/usuarios?emailUsuario=${email}`, token);
}
export async function obtenerTodosLosUsuariosRequest(token) {
    return await getRequest(API_URL, '/api/Usuarios', token);
}
export async function obtenerUsuariosPorIdComercio(idComercio, token) {
    return await getRequest(API_URL, `/api/Usuarios?idcomercio=${idComercio}`, token);
}
export async function authUsuario(username, password, recaptchaToken) {
    return await postRequest(API_URL, "/api/Usuarios/Auth",
        RequestContentType.XFORM_URLENCODED, { user: username, pass: password, token: recaptchaToken }, null, true);
}
export async function authUsuarioComercio(username, password, rutComercio, recaptchaToken) {
    return await postRequest(API_URL, "/api/Usuarios/Auth",
        RequestContentType.XFORM_URLENCODED, { rutComercio: rutComercio,  user: username, pass: password, token: recaptchaToken }, null, true);
}
export async function crearUsuarioRequest(usuario, token) {
    return await postRequest(API_URL, '/api/Usuarios', RequestContentType.APPLICATION_JSON, usuario, token);
}
export async function editarUsuarioRequest(usuario, token, id) {
    return await putRequest(API_URL, `/api/usuarios/${id}`, RequestContentType.APPLICATION_JSON, usuario, token);
}
export async function restablecerUsuarioRequest(id, token) {
    return await postRequest(API_URL, `/api/usuarios/resetearclave/${id}`, RequestContentType.APPLICATION_JSON, null, token);
}
export async function eliminarUsuarioRequest(id, token) {
    return await deleteRequest(API_URL, `/api/usuarios/${id}`, token)
}
export async function cambiarPrimeraClaveResquest(usuario, token) {
    return await postRequest(API_URL, '/api/usuarios/cambiarclave', RequestContentType.APPLICATION_JSON, usuario, token);
}
export async function olvidarClaveRequest(email) {
    return await postRequest(API_URL, '/api/usuarios/OlvidoClave', RequestContentType.APPLICATION_JSON, { Email: email }, null, false);
}
