import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import Button from "./Button";

export default function Dialogo(props) {
  const handleClose = () => {
    if (props.onClose)
        props.onClose();
  };
  const handleAccept = () => {
    handleClose();
    if (props.onConfirm) props.onConfirm();
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={handleClose} variant= "gray">
          CANCELAR
        </Button>
        <Button type="button" onClick={handleAccept} variant="red">
          ACEPTAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
