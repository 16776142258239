import React, { useState, useEffect } from "react";
import ShadowBox from "../../components/ShadowBox";
import { Grid, Typography, Divider } from "@mui/material";
import SelectorFecha from "../../components/SelectorFecha";
import RoundButton from "../../components/RoundButton";
import LogsTable from "../../components/LogsTable/LogsTable";
import Combobox from "../../components/Combobox";
import CustomSelect from "../../components/CustomSelect";
import { obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import { useLoader } from "../../context/LoaderContext";
import { obtenerLogsRequest } from "../../connection/mantenedores/logMantenedor";
import { getToken } from "../../connection/tokenController";
import LogDialog from "../../components/LogDialog";

function parsearComerciosACombobox(listado) {
  return listado.map((x, idx) => {
    return {
      nombre: x.nombreComercio.toUpperCase(),
      codigo: x.idComercio,
    };
  });
}

const MOSTRAR_TODO = "MOSTRAR TODOS";

export default function Logs() {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [logs, setLogs] = useState([]);
  const [groupedLogs, setGroupedLogs] = useState([]);
  const [filteredGroupedLogs, setFilteredGroupedLogs] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(null);

  const [comercios, setComercios] = useState([]);
  const [dialogoJson, setDialogoJson] = useState({
    open: false,
    tabla: "",
    campo: "",
    historial: [],
  });
  const [todosCamposUnicos, setTodosCamposUnicos] = useState([]);
  const [camposUnicos, setCamposUnicos] = useState([]);
  const [todasTablasUnicas, setTodasTablasUnicas] = useState([]);
  const [tablasUnicas, setTablasUnicas] = useState([]);
  const [filterTable, setFilterTable] = useState(MOSTRAR_TODO);
  const [filterCampo, setFilterCampo] = useState(MOSTRAR_TODO);
  const [comercioSelected, setComercioSelected] = useState({
    nombre: "",
    codigo: -1,
  });

  const handleChangeStartDate = (date) => {
    setStartDate(date);
    setMaxEndDate(obtenerMaxDate(date));
  };

  const resetFilters = () => {
    vaciarFiltros();
    setStartDate(null);
    setEndDate(null);
    setLogs([]);
    setGroupedLogs([]);
    setFilteredGroupedLogs([]);
    showNotification("info", "Filtros restablecidos");
  };
  const vaciarFiltros = () => {
    if (comercioSelected.codigo === -1) return;
    handleUnSelectComercio();
  };

  const handleSelectComercio = (commerce) => {
    setComercioSelected(commerce);
  };
  const handleUnSelectComercio = () => {
    setComercioSelected({ nombre: "", codigo: -1 });
  };

  const handleBuscarLogs = async () => {
    showLoader();
    vaciarFiltros();
    try {
      if (!validarDatos()) return;
      const fechaDesdeBack = startDate.toLocaleDateString("en-CA");
      const fechaHasta = new Date(endDate);
      fechaHasta.setDate(fechaHasta.getDate() + 1);
      const fechaHastaBack = fechaHasta.toLocaleDateString("en-CA");

      const respuesta = await obtenerLogsRequest(
        fechaDesdeBack,
        fechaHastaBack,
        getToken()
      );

      const logsTransformados = respuesta.map((log) => ({
        ...log,
        nombreTabla: log.nombreTabla ? log.nombreTabla.toUpperCase() : "",
        campo: log.campo ? log.campo.toUpperCase() : "",
      }));
      const camposUnicosList = [
        ...new Set(logsTransformados.map((log) => log.campo)),
      ];
      setTodosCamposUnicos(camposUnicosList);
      const tablasUnicasList = [
        ...new Set(logsTransformados.map((log) => log.nombreTabla)),
      ];
      setTodasTablasUnicas(tablasUnicasList);
      const agrupaciones = generarAgrupaciones(tablasUnicasList, camposUnicosList, logsTransformados);

      setLogs(logsTransformados);
      setGroupedLogs(agrupaciones);
      setFilteredGroupedLogs(agrupaciones);
    } catch (error) {
      console.error(error);
      showNotification("error", "Ha habido un error al obtener los datos");
    } finally {
      hideLoader();
    }
  };

  const generarAgrupaciones = (tablasUnicasList, camposUnicosList, logsTransformados, idComercio = 0) => {
    try {
      if (idComercio === 0) {
        setTablasUnicas(tablasUnicasList);
        setCamposUnicos(camposUnicosList);
        return camposUnicosList.map((campo) => {
          const filtrado = logsTransformados.filter(
            (log) => log.campo === campo
          );
          return {
            campo: filtrado[0].campo,
            tabla: filtrado[0].nombreTabla,
            historial: filtrado,
          };
        });
      }
      const listadoComerciosAgrupados = logsTransformados.filter(
        (log) => log.idComercio === idComercio
      );
      const nuevosCamposUnicosList = [
        ...new Set(listadoComerciosAgrupados.map((log) => log.campo)),
      ];
      setCamposUnicos(nuevosCamposUnicosList);
      const nuevasTablasUnicasList = [
        ...new Set(listadoComerciosAgrupados.map((log) => log.nombreTabla)),
      ];
      setTablasUnicas(nuevasTablasUnicasList);
      return nuevosCamposUnicosList.map((campo) => {
        const filtrado = logsTransformados.filter(
          (log) => log.campo === campo && log.idComercio === idComercio
        );
        return {
          campo: filtrado[0].campo,
          tabla: filtrado[0].nombreTabla,
          historial: filtrado,
        };
      });
    } catch {}
  };

  function validarDatos() {
    if (!comercioSelected && !startDate && !endDate) {
      showNotification("error", "Selecciona Fecha Desde.");
      return false;
    }
    if (!startDate) {
      showNotification("error", "Selecciona Fecha Desde");
      return false;
    }
    if (!endDate) {
      showNotification("error", "Selecciona Fecha Hasta.");
      return false;
    }
    const fechaDesde = new Date(startDate);
    const fechaHasta = new Date(endDate);
    const diferenciaDias = (fechaHasta - fechaDesde) / (1000 * 60 * 60 * 24);
    if (diferenciaDias > 31) {
      showNotification(
        "error",
        "El rango de fechas no debe ser mayor a un mes."
      );
      return false;
    }
    return true;
  }

  const handleEstablecerComercios = async () => {
    try {
      const listadoComercios = await obtenerTodosLosComerciosRequest(getToken());
      const comerciosHabilitados = listadoComercios.filter((x) => x.habilitado);

      // Ordenar comercios habilitados de A a Z por nombre
      comerciosHabilitados.sort((a, b) =>
        a.nombreComercio.localeCompare(b.nombreComercio)
      );

      setComercios(parsearComerciosACombobox(comerciosHabilitados));
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    handleEstablecerComercios();
    // eslint-disable-next-line
  }, []);
  const obtenerMaxDate = (value) => {
    if (value == null) return null;
    const fecha = new Date(value);
    fecha.setDate(fecha.getDate() + 30);
    return fecha;
  };

  const handleShowDatos = (row) => {
    setDialogoJson({
      open: true,
      tabla: row.tabla || row.nombreTabla,
      campo: row.campo,
      historial: row.historial || [],
    });
  };

  const handleCloseDialogoJson = () => {
    setDialogoJson({ ...dialogoJson, open: false });
  };

  const handleFiltrarPorCampo = (e) => {
    setFilterCampo(e.target.value);
  };

  const handleFiltrarPorTabla = (e) => {
    setFilterTable(e.target.value);
  };

  const handleFiltrarLogs = () => {
    setFilteredGroupedLogs(
      groupedLogs.filter((item) => {
        if (filterTable !== MOSTRAR_TODO && item.tabla !== filterTable) {
          return false;
        }
        if (filterCampo !== MOSTRAR_TODO && item.campo !== filterCampo) {
          return false;
        }
        return true;
      })
    );
  };

  useEffect(() => {
    handleFiltrarLogs();
    // eslint-disable-next-line
  }, [filterCampo, filterTable]);

  // si listado campos unicos cambia se restablece el filtro
  useEffect(() => {
    setFilterCampo(MOSTRAR_TODO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camposUnicos]);

  // FILTRO DE TABLA
  useEffect(() => {
    if (filterTable === MOSTRAR_TODO) {
      setCamposUnicos([...new Set(groupedLogs.map((log) => log.campo))]);
    } else {
      const listadoSet = groupedLogs.filter((log) => log.tabla === filterTable);
      setCamposUnicos([...new Set(listadoSet.map((log) => log.campo))]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTable]);

  // si listado tablas unicas cambia se restablece el filtro
  useEffect(() => {
    setFilterTable(MOSTRAR_TODO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablasUnicas]);

  // FILTRO DE COMERCIO
  useEffect(() => {
    const idComercio = comercioSelected.codigo;
    let nTablasUnicas = todasTablasUnicas;
    if (idComercio === -1) {
      nTablasUnicas = [...new Set(groupedLogs.map((log) => log.tabla))];
      setTablasUnicas(nTablasUnicas);
    }
    const agrupaciones = generarAgrupaciones(
      nTablasUnicas,
      todosCamposUnicos,
      logs,
      idComercio
    );
    setGroupedLogs(agrupaciones);
    setFilteredGroupedLogs(agrupaciones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comercioSelected]);

  return (
    <div className="LogsTable">
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        REGISTRO DE ACTIVIDADES
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <br />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={6} md={3}>
            <label style={{ color: "black" }}>FECHA DESDE</label>
            <br />
            <SelectorFecha
              label="Fecha Desde"
              value={startDate}
              onChange={handleChangeStartDate}
              InputProps={{ style: { borderColor: "black" } }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <label style={{ color: "black" }}>FECHA HASTA</label>
            <br />
            <SelectorFecha
              label="Fecha Hasta"
              value={endDate}
              onChange={(value) => setEndDate(value)}
              InputProps={{ style: { borderColor: "black" } }}
              min={startDate}
              max={maxEndDate}
            />
          </Grid>
        </Grid>
        <br />
        <div style={{ marginRight: "1rem", display: "inline-flex" }}>
          <RoundButton variant="outlined" onClick={handleBuscarLogs}>
            BUSCAR
          </RoundButton>
        </div>
        <RoundButton variant="outlined" onClick={resetFilters}>
          LIMPIAR
        </RoundButton>
        <br />
        <br />
        <Divider />
        <br />
        <br />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <div>
              <label style={{ color: "black", marginBottom: "2px" }}>
                FILTRAR COMERCIO
              </label>
              <Combobox
                rows={comercios || []}
                placeholder="MOSTRAR TODOS"
                onFilter={() => {}}
                onSelect={handleSelectComercio}
                onUnSelect={handleUnSelectComercio}
                selected={comercioSelected}
                style={{ width: "265px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label style={{ color: "black", marginBottom: "2px" }}>
                FILTRAR TABLAS
              </label>
              <CustomSelect
                listado={[MOSTRAR_TODO, ...tablasUnicas]}
                onChange={handleFiltrarPorTabla}
                value={filterTable}
                style={{ width: "100%" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label style={{ color: "black", marginBottom: "2px" }}>
                FILTRAR CAMPOS
              </label>
              <CustomSelect
                listado={[MOSTRAR_TODO, ...camposUnicos]}
                onChange={handleFiltrarPorCampo}
                value={filterCampo}
                style={{ width: "100%" }}
              />
            </div>
          </Grid>
        </Grid>

        <br />
        <LogsTable
          agrupaciones={filteredGroupedLogs}
          onShowDatos={handleShowDatos}
        />
      </ShadowBox>
      <LogDialog
        open={dialogoJson.open}
        onClose={handleCloseDialogoJson}
        tabla={dialogoJson.tabla}
        campo={dialogoJson.campo}
        historial={dialogoJson.historial}
      />
    </div>
  );
}
