import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import GscanTableRow from "./GscanTableRow";

export default function GscanTable({
  listado,
  onOpenDialog
}) {
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: 10,
    count: 1,
    page: 0,
  });

  const handleChangePage = (event, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };

  const handleRowsPerPageChange = (event) => {
    setPaginationControl({
      ...paginationControl,
      page: 0,
      rowsPerPage: event.target.value,
    });
  };

  const inicioCorte = () =>
    paginationControl.page * paginationControl.rowsPerPage;
  const corteFinal = () => inicioCorte() + paginationControl.rowsPerPage;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>COMERCIO</b>
              </TableCell>
              <TableCell>
                <b>RUT</b>
              </TableCell>
              <TableCell>
                <b>CANTIDAD URLS</b>
              </TableCell>
              <TableCell>
                <b>URLS</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listado.slice(inicioCorte(), corteFinal()).map((item, key) => (
              <GscanTableRow
                key={key}
                item={item}
                onOpenDialog={onOpenDialog}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={listado.length}
          rowsPerPage={paginationControl.rowsPerPage}
          page={paginationControl.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage="Filas por página:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      </TableContainer>
    </>
  );
}
