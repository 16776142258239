import React from "react";
import { TableCell, TableRow } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as RequetSend } from "../../resources/icons/request_send.svg";
import IconButton from "@mui/material/IconButton";

function isFechaValida(fecha) {
  return fecha.getFullYear() > 1;
}

export default function TransaccionesTableRow(props) {
  const { row, onShowRequest } = props;
  return (
    <TableRow>
      <TableCell sx= {{whiteSpace: "nowrap"}}>
        {row.fechaRequest.toLocaleDateString("es-CL")} <br />
        <span style={{ fontSize: "11px", fontStyle: "italic",  }}>
          {row.fechaRequest.toLocaleTimeString("es-CL")}
        </span>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>{row.idTxs}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>{row.comando}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>{row.functionCode}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>{row.responseCode}</TableCell>
      <TableCell>{row.responseMessage}</TableCell>
      <TableCell sx={{ textAlign: "left" }}>
        {!isFechaValida(row.fechaResponse) ? (
          <></>
        ) : (
          <>
            {row.fechaResponse.toLocaleDateString("es-CL")} <br />
            <span style={{ fontSize: "11px", fontStyle: "italic" }}>
              {row.fechaResponse.toLocaleTimeString("es-CL")}
            </span>
          </>
        )}
      </TableCell>
      <TableCell>
        <Tooltip title="Mostrar">
          <IconButton
            onClick={() => {
              onShowRequest(row);
            }}
          >
            <RequetSend
              style={{ height: "1.6rem", fill: "#444444" }}
              title=""
            />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
