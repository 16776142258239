import React, { useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Skeleton,
} from "@mui/material";
import Input from "../Input";
import {
  crearEmailRequest,
  editarEmailRequest,
  eliminarEmailRequest,
  obtenerEmailsRequest,
} from "../../connection/mantenedores/comercioMantenedor";
import { getToken } from "../../connection/tokenController";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import Dialogo from "../Dialogo";
import ComercioExpandedTableRow from "./ComercioExpandedTableRow";
import { useLoader } from "../../context/LoaderContext";

export const ComercioExpandedTable = (props) => {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const { row, open } = props;
  const [correos, setCorreos] = useState([]);
  const [dialogo, setDialogo] = useState({
    open: false,
    idEmailComercio: 0,
  });
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true); 

  const handleInputChange = (text) => {
    setInputValue(text.target.value);
  };

  async function crearEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showNotification("error", "Correo electrónico no válido");
      return;
    }
    try {
      const body = {
        idComercio: row.idComercio,
        email: email,
        habilitado: row.habilitado,
      };
      showLoader();
      const res = await crearEmailRequest(body, getToken());
      if (res.idEmailComercio) {
        setInputValue("");
        showNotification(
          "success",
          "Correo electrónico creado exitosamente!"
        );
        callEmails();
      }
      return;
    } catch (error) {
      showNotification("error", "Algo salió mal");
    } finally {
      hideLoader();
    }
  }

  async function callEmails() {
    setLoading(true);
    const correos = await obtenerEmailsRequest(row.idComercio, getToken());
    const correosHabiles = correos.filter(
      (object) => object.habilitado === true
    );
    const correosOrden = correosHabiles.sort((a, b) =>
      a.email.localeCompare(b.email)
    );
    setCorreos(correosOrden);
    setLoading(false); 
  }

  useEffect(() => {
    if (open) callEmails();
    // eslint-disable-next-line
  }, [open]);

  const handleEditEmail = async (idEmailComercio, nuevoEmail) => {
    showLoader();
    const body = {
      idComercio: row.idComercio,
      email: nuevoEmail,
      habilitado: row.habilitado,
    };
    try {
      const res = await editarEmailRequest(body, idEmailComercio, getToken());
      if (res) {
        showNotification("success", "Correo electrónico editado exitosamente");
        callEmails();
      }
    } catch (error) {
      showNotification("error", "Algo salió mal");
    } finally {
      hideLoader();
    }
  };

  const handleDeleteEmail = (idEmailComercio) => {
    setDialogo({ idEmailComercio: idEmailComercio, open: true });
  };

  const deleteEmail = async () => {
    showLoader();
    try {
      const res = await eliminarEmailRequest(
        dialogo.idEmailComercio,
        getToken()
      );
      if (res) {
        setInputValue("");
        showNotification(
          "success",
          "Correo electrónico eliminado exitosamente!"
        );
        callEmails();
      }
    } catch (error) {
      showNotification("error", "Algo salió mal");
    } finally {
      hideLoader();
      handleCloseDialog();
    }
  };

  const handleCloseDialog = () => {
    setDialogo({ ...dialogo, open: false });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "1rem 0" }}>
      <Table
        style={{
          width: "fit-content",
          borderSpacing: "0 1rem",
          fontSize: "1rem",
        }}
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                padding: "0",
                borderBottom: "1px solid #ddd",
                textAlign: "center",
              }}
            >
              CORREOS ELECTRÓNICOS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? ( 
            <TableRow>
              <TableCell colSpan={2}>
                <Skeleton animation="pulse" />
              </TableCell>
            </TableRow>
          ) : (
            correos.map((email, index) => (
              <TableRow key={index} className="email-row">
                <ComercioExpandedTableRow
                  email={email}
                  onEditEmail={handleEditEmail}
                  onDeleteEmail={handleDeleteEmail}
                />
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <Input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="ejemplo@email.com"
              ></Input>
            </TableCell>
            <TableCell>
              <Tooltip title="Agregar correo">
                <IconButton onClick={() => crearEmail(inputValue)}>
                  <AddCircleOutlineSharpIcon
                    sx={{ width: "30px", height: "30px" }}
                  />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <Dialogo
        open={dialogo.open}
        onClose={handleCloseDialog}
        title="Eliminar Correo"
        text={"¿Estás seguro que deseas eliminar este correo electrónico?"}
        onConfirm={deleteEmail}
      />
    </div>
  );
};
