import React, { createContext, useContext, useState } from 'react';
import { obtenerNumeroAleatorio } from '../utils/utilidades';

const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
    const [open, setOpen] = useState(false);

    const [notificaciones, setNotificaciones] = useState([]);
    const hideNotificacion = (id, event, reason) => {
        if (reason === "clickaway") return;
        setNotificaciones(notificaciones.filter((x) => x.id !== id));
    };

    const showNotification = (type, message) => {
        const numeroAleatorio = obtenerNumeroAleatorio(0, 10);
        const notificacion = {
            id: `${Date.now()}${numeroAleatorio}`,
            open: true,
            type: type,
            message: message
        };
        setNotificaciones(prev => [...prev, notificacion]);
        setTimeout(() => {
            setNotificaciones(prev => prev.filter(notif => notif.id !== notificacion.id));
        }, 5000);
    }

    const showLoader = () => setOpen(true);
    const hideLoader = () => setOpen(false);

    return (
        <LoaderContext.Provider value={{ open, showLoader, hideLoader, notificaciones, showNotification, hideNotificacion }}>
            {children}
        </LoaderContext.Provider>
    );
};