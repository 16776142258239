import React from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const estilos = {
    eye: {
      color: '#444',
      position: 'absolute',
      top: '50%',
      right: '1rem',
      transform: 'translate(0, -50%)',
      cursor: 'pointer',
      transition: 'color 150ms ease',
      '&:hover': {
        color: '#444444cc'
      }
    }
  }

export default function Eye(props) {
  return (
    <>
    {
        props.open ? <VisibilityIcon sx={estilos.eye} onClick={props.onClick} /> :
        <VisibilityOffIcon sx={estilos.eye} onClick={props.onClick} />
    }
    </>
  )
}
