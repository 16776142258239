import React, { useCallback, useRef } from "react";
import { Box, Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import logo from "../../resources/images/logo.png";
import InputPassword from "../../components/InputPassword";
import Button from "../../components/Button";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { useLoader } from "../../context/LoaderContext";
import { authUsuarioComercio } from "../../connection/mantenedores/usuarioMantenedor";
import ReCAPTCHA from "react-google-recaptcha";
import { saveToken } from "../../connection/tokenController";
import {
  saveIdComercio,
  saveUserCambioClave,
  saveUserComercioRole,
} from "../../connection/loginController";
import { formatearRut, validarEmail, validarRut } from "../../utils/utilidades";
import { VERSION } from "../../version";

const CaptchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

const styles = {
  box: {
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(180deg, #D4E8FF, white)"
  },
  shadowBox: {
    borderRadius: "15px",
    padding: "2rem 2rem",
    textAlign: "center"
  },
  imgLogo: {
    width: "12rem",
  },
  olvidadPassword: {
    color: "#c1c1c1",
  },
  versionStyle: {
    textAlign: "center",
    fontStyle: "italic",
    color: "#445",
    position: "absolute",
    bottom: "1rem",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  textContainer: {
    textAlign: "right",
  },
  largeText: {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "#d32f2f", // color rojo más atractivo
    fontFamily: "'Roboto', sans-serif", // ejemplo de fuente más atractiva
  },
};
export default function LoginComercio() {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();

  const [username, setUsername] = useState("");
  const [rut, setRut] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [rutError, setRutError] = useState(false)

  const reCaptchaRef = useRef(null);
  const [recaptchaValidated, setRecaptchaValidated] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const handleChangeUsername = (e) => setUsername(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);

  const handleChangeRut = (e) => {
    setRutError(false);
    setRut(e.target.value);
  }
  const handleFocusRut = (e) => {
    if (e.target.value.length === 0) return;
    const texto = e.target.value.replace("-", "");
    setRut(texto)
  }
  const handleBlurRut = (e) => {
    try {
      setRutError(false);
      if (!e.target.value)
        return;
      const tempRut = formatearRut(e.target.value);
      if (!validarRut(tempRut)) {
        setRutError(true);
      }
      setRut(tempRut);
    } catch {
      setRutError(true);
    }
  }

  const handleRecaptchaChange = useCallback((token) => {
    setReCaptchaToken(token);
    setRecaptchaValidated(!!token);
  }, []);
  const resetRecaptcha = () => {
    try {
      setRecaptchaValidated(false);
      if (reCaptchaRef.current) reCaptchaRef.current.reset();
    } catch (error) {
      console.error("no se pudo restablecer el captcha");
    }
  };

  const handleLoginClick = (e) => {
    setError("");
    if (!validarDatos()) return;
    if (!validarRecaptcha()) return;
    handleLogin();
  };
  const validarDatos = () => {
    if (!rut.trim() || !username.trim() || !password.trim()) {
      setError("Por favor, complete todos los campos");
      return false;
    }
    if (!validarRut(rut)) {
      setError("Por favor, introduzca un rut válido");
      return false;
    }
    if (!validarEmail(username)) {
      setError("Por favor, introduce un correo electrónico válido");
      return false;
    }
    return true;
  };

  const validarRecaptcha = () => {
    if (!recaptchaValidated) {
      setError("Valide el captcha por favor");
      return false;
    }
    return true;
  };
  const handleLogin = async () => {
    showLoader();
    try {
      // Hacer login
      const respuesta = await authUsuarioComercio(username, password, rut, reCaptchaToken);
      if (respuesta.cambioDeClave) {
        saveUserComercioRole();
        saveUserCambioClave(username, password);
        handleNavigate("/cambio-clave");
        return;
      }
      if (!respuesta.idComercio)
        return;
      if (respuesta.token) {
        saveIdComercio(respuesta.idComercio);
        saveUserComercioRole();
        handleLoginCorrect(respuesta);
        return;
      }
      if (respuesta.token === "") {
        setError("Introduce las credenciales correctas.");
        resetRecaptcha();
        return;
      }
    } catch (error) {
      // Manejo de errores
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      if (error.status === 401) {
        setError("Introduce las credenciales correctas.");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
      resetRecaptcha();
    } finally {
      // Close loader
      hideLoader();
    }
  };
  const handleLoginCorrect = (respuesta) => {
    saveToken(respuesta.token, respuesta.expires_in);
    handleNavigate("/comercios");
  }
  const handleNavigate = (to) => {
    showNotification("success", "Inicio de sesión exitoso");
    navigate(to);
  };
  return (
    <>
      <Box sx={styles.box}>
        <ShadowBox style={styles.shadowBox}>
          <img style={styles.imgLogo} src={logo} alt="logo" />
          <br />
          <Typography variant="p" style={styles.labelLogin} gutterBottom>
            Bienvenido
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }} >
            COMERCIO
          </Typography>
          <br />
          {/* de momento se dejo como el username para dejarlo funcionando y ver el front  */}
          <br />
          <Input
            className={`gray ${rutError ? "error" : null}`}
            required
            onChange={handleChangeRut}
            onBlur={handleBlurRut}
            onFocus={handleFocusRut}
            value={rut}
            placeholder="Rut Comercio"
            completeWidth={true}
          />
          <br />
          <Input
            className="gray"
            required
            onChange={handleChangeUsername}
            value={username}
            placeholder="Correo electrónico"
            completeWidth={true}
          />
          <br />
          <InputPassword
            className="gray"
            value={password}
            onChange={handleChangePassword}
            completeWidth={true}
          />
          <br />
          <Typography sx={{ color: "#444 !important" }}>
            <Link className="forgot_link" to="/olvide-mi-pass">
              Olvide mi contraseña
            </Link>
          </Typography>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={CaptchaSiteKey}
              onChange={handleRecaptchaChange}
            />
          </div>
          <br />
          <div style={{ textAlign: "center", width: "100%" }}>
            {error && (
              <Typography
                sx={{
                  color: "red",
                  wordWrap: "break-word",
                }}
              >
                {error}
              </Typography>
            )}
          </div>
          <br />
          <Button
            onClick={handleLoginClick}
            largeButton
          >
            INICIAR SESIÓN
          </Button>
        </ShadowBox>
        <Typography sx={styles.versionStyle}>
          Versión: {VERSION}
        </Typography>
      </Box>
    </>
  );
}
