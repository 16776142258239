import React, { useEffect, useState } from "react";
import GscanDialogSectionHeader from "./GscanDialogSectionHeader";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import GscanDialogTableRow from "./GscanDialogTableRow";
import { useLoader } from "../../context/LoaderContext";
import { validarURL } from "../../utils/utilidades";

export default function GscanDialogResponse({
  onToggleHabilitarEndpoint,
  endpoint,
  onSave,
}) {
  const [editMode, setEditMode] = useState(false);
  const { showNotification } = useLoader();
  const [urlError, setUrlError] = useState("");
  const [apiKeyError, setApiKeyError] = useState("");
  const [valores, setValores] = useState({
    url: "",
    parametros: { apiKey: "" },
  });

  useEffect(() => {
    setValores({
      url: endpoint?.urlEndpoint || "",
      parametros: getParametros(),
    });
    // eslint-disable-next-line
  }, [endpoint]);

  const handleToggleEdit = () => {
    setEditMode(!editMode);
  };

  const handleConfirmEdit = () => {
    let hasError = false;

    if (!validarURL(valores.url)) {
      setUrlError("La URL no es válida.");
      showNotification("error", "URL Response no es válida.");
      hasError = true;
    } else {
      setUrlError("");
    }

    if (hasError) return;
    const valuesFilled = allFieldsFilled();
    const objeto = {
      ...endpoint,
      urlEndpoint: valores.url?.trim(),
      parametros: JSON.stringify(valores.parametros),
      habilitado: valuesFilled,
    };

    onSave(objeto);
    handleToggleEdit();
  };

  function getParametros() {
    try {
      return JSON.parse(endpoint.parametros);
    } catch {
      return { apikey: "" };
    }
  }

  const handleCancelEdit = () => {
    setValores({
      url: endpoint?.urlEndpoint,
      parametros: getParametros(),
    });
    handleToggleEdit();
  };

  const handleChangeUrl = (url) => {
    setValores({ ...valores, url: url });
  };

  const handleChangeApiKey = (apiKey) => {
    setValores({ ...valores, parametros: { apiKey: apiKey } });
    if (!apiKey.trim()) {
      setApiKeyError("El API Key no puede estar vacío.");
    } else {
      setApiKeyError("");
    }
  };

  const allFieldsFilled = () => {
    const { url, parametros } = valores;

    const isNotEmpty = (value) =>
      typeof value === "string" && value.trim().length > 0;
    return isNotEmpty(url) && (!parametros || isNotEmpty(parametros.apiKey));
  };

  const handleSwitchChange = () => {
    try {
      if (allFieldsFilled() || endpoint.habilitado) {
        onToggleHabilitarEndpoint(endpoint);
      } else {
        console.warn(
          "Algunos campos están vacíos. No se puede habilitar el switch."
        );
      }
    } catch (error) {
      console.error("Error al cambiar el estado del switch:", error);
    }
  };

  return (
    <>
      <GscanDialogSectionHeader
        title="Response"
        editMode={editMode}
        switchState={endpoint.habilitado}
        onSwitchChange={handleSwitchChange}
        disabled={Boolean()}
        onEditClick={handleToggleEdit}
        onEditConfirm={handleConfirmEdit}
        onEditCancel={handleCancelEdit}
        disableSwitch={!(allFieldsFilled() || endpoint.habilitado)}
      />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="request table">
          <GscanDialogResponseBody
            urlResponse={valores.url}
            urlError={urlError}
            apiKey={valores.parametros.apiKey}
            apiKeyError={apiKeyError}
            onChangeUrl={handleChangeUrl}
            onChangeApikey={handleChangeApiKey}
            editMode={editMode}
          />
        </Table>
      </TableContainer>
    </>
  );
}
const GscanDialogResponseBody = ({
  urlResponse,
  editMode,
  onChangeUrl,
  onChangeApikey,
  apiKey,
}) => {
  return (
    <TableBody>
      <GscanDialogTableRow
        editMode={editMode}
        label="URL Response"
        value={urlResponse}
        onChange={onChangeUrl}
      />
      <GscanDialogTableRow
        editMode={editMode}
        label="Api Key"
        value={apiKey}
        onChange={onChangeApikey}
      />
    </TableBody>
  );
};