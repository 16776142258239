import { getApiUrl, RequestContentType, getRequest, postRequest, putRequest } from "../apiConnection";

const API_URL = getApiUrl();
const URL_POS_INTEGRADO = process.env.REACT_APP_URL_POS_INTEGRADO;

export async function crearPosRequest(pos, token) {
    return postRequest(API_URL, "/api/posdata", RequestContentType.APPLICATION_JSON, pos, token);
}

export async function obtenerTodosLosPosIdComercio(id, token) {
    return await getRequest(API_URL, `/api/posdata?idComercio=${id}`, token);
}
export async function obtenerUnComercioPorId(id, token) {
    return await getRequest(API_URL, `api/comercios/${id}`, token);
}

export async function editarPosRequest(pos, id, token) {
    return await putRequest(API_URL, `/api/posdata/${id}`, RequestContentType.APPLICATION_JSON, pos, token);
}

export async function validarConexionRequest(body, token) {
    return postRequest(URL_POS_INTEGRADO, "/api/postxs/poll", RequestContentType.APPLICATION_JSON, body, token);
}
export async function autenticarComerciosRequest(params) {
    return await postRequest(URL_POS_INTEGRADO, "/api/postxs/auth", RequestContentType.XFORM_URLENCODED, params, null);
}