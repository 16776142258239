import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import React, { useEffect, useState } from "react";
import GscanDialogTableRow from "./GscanDialogTableRow";
import GscanDialogSectionHeader from "./GscanDialogSectionHeader";
import { useLoader } from "../../context/LoaderContext";
import { validarURL } from "../../utils/utilidades";

export default function GscanDialogRequest({
  endpoint,
  onToggleHabilitarEndpoint,
  onSave,
}) {
  const [editMode, setEditMode] = useState(false);
  const { showNotification } = useLoader();
  const [urlError, setUrlError] = useState("");
  const [apiKeyError, setApiKeyError] = useState("");
  const [valores, setValores] = useState({
    url: "",
    parametros: { apikey: "" },
  });

  useEffect(() => {
    setValores({
      url: endpoint?.urlEndpoint || "",
      parametros: getParametros(),
    });
    // eslint-disable-next-line
  }, [endpoint]);

  const handleToggleEdit = () => {
    setEditMode(!editMode);
  };

  const handleConfirmEdit = () => {
    let hasError = false;

    if (!validarURL(valores.url)) {
      setUrlError("La URL no es válida.");
      showNotification("error", "URL Request no es válida.");
      hasError = true;
    } else {
      setUrlError("");
    }

    if (hasError) return;
    const valuesFilled = allFieldsFilled();
    const objeto = {
      ...endpoint,
      urlEndpoint: valores.url?.trim(),
      parametros: JSON.stringify(valores.parametros),
      habilitado: valuesFilled,
    };

    onSave(objeto);
    handleToggleEdit();
  };

  function getParametros() {
    try {
      return JSON.parse(endpoint.parametros);
    } catch {
      return { apikey: "" };
    }
  }

  const allFieldsFilled = () => {
    const { url, parametros } = valores;
    const isNotEmpty = (value) =>
      typeof value === "string" && value.trim().length > 0;
    return isNotEmpty(url) && (!parametros || isNotEmpty(parametros.apikey));
  };

  const handleCancelEdit = () => {
    setValores({
      url: endpoint?.urlEndpoint || "",
      parametros: getParametros(),
    });
    handleToggleEdit();
  };

  const handleChangeUrl = (url) => {
    setValores((prev) => ({
      ...prev,
      url: url || "",
    }));
    if (!validarURL(url)) {
      setUrlError("La URL no es válida.");
    } else {
      setUrlError("");
    }
  };

  const handleChangeApiKey = (apiKey) => {
    setValores((prev) => ({
      ...prev,
      parametros: { apikey: apiKey || "" },
    }));
    if (valores.url.trim() && !apiKey?.trim()) {
      setApiKeyError("El API Key es necesario si se proporciona una URL.");
    } else {
      setApiKeyError("");
    }
  };

  const handleSwitchChange = () => {
    try {
      if (allFieldsFilled()) {
        onToggleHabilitarEndpoint(endpoint);
      } else {
        console.warn(
          "Algunos campos están vacíos. No se puede habilitar el switch."
        );
      }
    } catch (error) {
      console.error("Error al cambiar el estado del switch:", error);
    }
  };

  return (
    <>
      <GscanDialogSectionHeader
        title="Request"
        editMode={editMode}
        switchState={endpoint.habilitado}
        onSwitchChange={handleSwitchChange}
        disabled={Boolean()}
        onEditClick={handleToggleEdit}
        onEditConfirm={handleConfirmEdit}
        onEditCancel={handleCancelEdit}
        disableSwitch={!(allFieldsFilled() || endpoint.habilitado)}
      />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="request table">
          <GscanDialogRequestBody
            urlRequest={valores.url}
            apikey={valores.parametros.apikey}
            onChangeUrl={handleChangeUrl}
            onChangeApiKey={handleChangeApiKey}
            editMode={editMode}
            urlError={urlError}
            apiKeyError={apiKeyError}
          />
        </Table>
      </TableContainer>
    </>
  );
}

const GscanDialogRequestBody = ({
  editMode,
  urlRequest,
  apikey,
  onChangeUrl,
  onChangeApiKey,
  urlError,
  apiKeyError,
}) => {
  return (
    <TableBody>
      <GscanDialogTableRow
        editMode={editMode}
        label="URL Request"
        value={urlRequest}
        onChange={onChangeUrl}
        error={urlError}
      />
      <GscanDialogTableRow
        editMode={editMode}
        label="API Key"
        value={apikey}
        onChange={onChangeApiKey}
        error={apiKeyError}
      />
    </TableBody>
  );
};
