import React, { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import "./styles.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import TransaccionesTableRow from "./TransaccionesTableRow";

export default function TransaccionesTable(props) {
  const { listado } = props;
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: 10,
    count: 1,
    page: 0,
  });
  const [sortConfig, setSortConfig] = useState({
    key: "fechaRequest",
    direction: "desc",
  });

  const inicioCorte = () => {
    return paginationControl.page * paginationControl.rowsPerPage;
  };
  const corteFinal = () => {
    return (
      paginationControl.rowsPerPage * paginationControl.page +
      paginationControl.rowsPerPage
    );
  };

  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };

  const handleRowsPerPageChange = (e) => {
    setPaginationControl({
      ...paginationControl,
      page: 0,
      rowsPerPage: e.target.value,
    });
  };

  const handleSort = (key) => {
    if (key === "fechaRequest") {
      const direction = sortConfig.direction === "asc" ? "desc" : "asc";
      setSortConfig({ key, direction });
    }
  };

  const sortedListado = [...listado].sort((a, b) => {
    if (sortConfig.key === "fechaRequest") {
      const aValue = new Date(a[sortConfig.key]);
      const bValue = new Date(b[sortConfig.key]);
      return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
    }
    return 0;
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Tabla" size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === "fechaRequest"}
                direction={
                  sortConfig.key === "fechaRequest"
                    ? sortConfig.direction
                    : "desc"
                }
                onClick={() => handleSort("fechaRequest")}
              >
                <b>FECHA</b>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <b>ID TXS</b>
            </TableCell>
            <TableCell>
              <b>COMANDO</b>
            </TableCell>
            <TableCell>
              <b>FUNCTION CODE</b>
            </TableCell>
            <TableCell>
              <b>RESPONSE CODE</b>
            </TableCell>
            <TableCell>
              <b>RESPONSE MESSAGE</b>
            </TableCell>
            <TableCell>
              <b>FECHA RESPONSE</b>
            </TableCell>
            <TableCell>
              <b>ACCIÓN</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="t-stripped t-highlight">
          {sortedListado.length === 0 ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                No se encontraron transacciones.
              </TableCell>
            </TableRow>
          ) : (
            sortedListado
              .slice(inicioCorte(), corteFinal())
              .map((row, idx) => (
                <TransaccionesTableRow
                  key={idx}
                  row={row}
                  onShowRequest={props.onShowRequest}
                  onShowResponse={props.onShowResponse}
                  variant={props.variant}
                />
              ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={sortedListado.length}
        rowsPerPage={paginationControl.rowsPerPage}
        page={paginationControl.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </TableContainer>
  );
}
