import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "./styles.css";
import { useLoader } from "../../context/LoaderContext";

const ExportButton = ({ data, fechaDesde, fechaHasta, titulo, sNumber}) => {
  const { showNotification } = useLoader();
  const handleExport = () => {
    if (data && data.length > 0) {
      const headers = [
        "IDTXS",
        "FECHA REQUEST",
        "HORA REQUEST",
        "FECHA RESPONSE",
        "HORA RESPONSE",
        "COMANDO",
        "FUNCTION CODE",
        "RESPONSE CODE",
        "RESPONSE MESSAGE",
        "SOLICITUD",
        "RESPUESTA",
      ];

      const formatJson = (jsonString) => {
        try {
          const jsonObj = JSON.parse(jsonString);
          return JSON.stringify(jsonObj, null, 2);
        } catch (e) {
          return jsonString;
        }
      };
      const excelBody = data.map((item) => [
        item.idtxs || "",
        item.fechaRequest || "",
        item.horaRequest || "",
        item.fechaResponse || "",
        item.horaResponse || "",
        item.comando || "",
        item.functionCode || "",
        item.responseCode || 0,
        item.responseMessage || "",
        formatJson(item.solicitud) || "",
        formatJson(item.respuesta) || "",
      ]);
      const workBook = XLSX.utils.book_new();
      const titleRow = ["COMERCIO:", `${data[0].nombreComercio}`];
      const serialNumber= ["S/N:", `${sNumber}` ]
      const fechaInRow = ["FECHA DESDE:", `${fechaDesde}`];
      const fechaEndRow = ["FECHA HASTA:", `${fechaHasta}`];
      const vacioRow = [""];
      const sheetData = [
        titleRow,
        serialNumber,
        fechaInRow,
        fechaEndRow,
        vacioRow,
        headers,
        ...excelBody,
      ];
      const workSheet = XLSX.utils.aoa_to_sheet(sheetData);

      const range = XLSX.utils.decode_range(workSheet["!ref"]);
      const colWidths = headers.map((header, i) =>
        Math.max(
          header.length,
          ...excelBody.map((row) => (row[i] ? row[i].toString().length : 0))
        )
      );

      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell = workSheet[XLSX.utils.encode_cell({ r: R, c: C })];
          if (
            cell &&
            cell.v &&
            typeof cell.v === "string" &&
            (C === headers.indexOf("SOLICITUD") ||
              C === headers.indexOf("RESPUESTA"))
          ) {
            cell.s = cell.s || {};
            cell.s.alignment = cell.s.alignment || {};
            cell.s.alignment.wrapText = true;
          }
        }
      }

      workSheet["!cols"] = colWidths.map((width) => ({ wch: width }));

      XLSX.utils.book_append_sheet(workBook, workSheet, "Datos");

      const workBookOut = XLSX.write(workBook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAs(
        new Blob([workBookOut], { type: "application/octet-stream" }),
        `T${titulo}/${fechaDesde}/${fechaHasta}.xlsx`
      );
    } else {
      showNotification("error", "Debe realizar una búsqueda para poder exportar los datos.");
    }
  };

  return (
    <button className="export-button" onClick={handleExport}>
      EXPORTAR EXCEL
      <img src="/excel.png" alt="Excel Icon" />
    </button>
  );
};

export default ExportButton;