import React, { useState } from "react";
import FormularioUsuario from "../../../components/Formularios/FormularioUsuario";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../connection/tokenController";
import { useLoader } from "../../../context/LoaderContext";
import { crearUsuarioRequest } from "../../../connection/mantenedores/usuarioMantenedor";
import DialogoUsuarioPasswordTemporal from "../../../components/DialogoUsuarioPasswordTemporal";
import { DESARROLLO_ROLE_VALUE } from "../../../connection/loginController";

const breadcrumbItems = [
    { href: '/usuarios-desarrollo', label: "Usuarios Desarrollo" }
];

export default function CrearUsuarioDesarrollo() {
    let navigate = useNavigate();
    const { showLoader, hideLoader, showNotification } = useLoader();

    const [errorMessage, setErrorMessage] = useState("");
    const [dialogo, setDialogo] = useState({
        open: false,
        text: "",
    });

    const handleOpenDialogo = (email, text) => {
        const passwordBlob = new Blob([text], { type: 'text/plain' });
        const item = new ClipboardItem({
            'text/plain': passwordBlob
        });
        navigator.clipboard.write([item]).then(() => { }).catch((error) => { console.error(error) })
        setDialogo({ open: true, email: email, text: text });
    };
    const handleCloseDialogo = () => {
        setDialogo({ ...dialogo, open: false });
    };

    const handleVolver = () => {
        navigate("/usuarios-desarrollo");

    };
    const handleConfirm = async (usuario) => {
        const datos = {
            nombre: usuario.nombre,
            emailUsuario: usuario.emailUsuario,
            habilitado: true,
            clave: usuario.clave,
            rol: DESARROLLO_ROLE_VALUE
        };
        try {
            showLoader();
            const response = await crearUsuarioRequest(datos, getToken());
            setErrorMessage("");
            handleOpenDialogo(datos.emailUsuario, response.clave);
            showNotification("success", "¡Usuario creado con éxito!");
        } catch (error) {
            if (error.status === 500) {
                showNotification("error", "Error de conexión");
                return;
            }
            if (error.status === 409) {
                showNotification("error", "Ya existe un usuario con este correo");
                return;
            }
            showNotification("error", "Ha ocurrido un error inesperado");
        }
        finally {
            hideLoader();
        }
    };
    return (
        <>
            <FormularioUsuario
                titulo="CREAR USUARIO DESARROLLO"
                usuario={null}
                onCancel={handleVolver}
                onConfirm={handleConfirm}
                buttonText="ACEPTAR"
                errorMessage={errorMessage}
                breadcrumbItems={breadcrumbItems}
            />
            <DialogoUsuarioPasswordTemporal
                title="USUARIO CREADO CON ÉXITO"
                open={dialogo.open}
                onClose={handleCloseDialogo}
                email={dialogo.email}
                password={dialogo.text}
                onConfirm={handleVolver}
            />
        </>
    );
}
