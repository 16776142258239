import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Input from "../Input";
import { Close } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import Skeleton from "@mui/material/Skeleton";

export default function ComercioExpandedTableRow({
  email,
  onEditEmail,
  onDeleteEmail,
  loading,
}) {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(email.email);
  const [loadingEmail, setLoadingEmail] = useState(false);

  function handleCancelEditing() {
    setEditing(false);
    setInputValue(email.email);
  }

  function handleChangeInputValue(e) {
    setInputValue(e.target.value);
  }

  function handleEditEmail() {
    onEditEmail(email.idEmailComercio, inputValue);
    setInputValue(email.email);
    setEditing(false);
  }

  function handleDeleteEmail() {
    onDeleteEmail(email.idEmailComercio);
  }

  useEffect(() => {
    setInputValue(email.email);
  }, [email.email]);

  useEffect(() => {
    setLoadingEmail(loading);
  }, [loading]);

  if (loadingEmail) {
    return (
      <TableRow>
        <TableCell colSpan={2}>
          <Skeleton animation="pulse" />
        </TableCell>
      </TableRow>
    );
  }

  if (editing) {
    return (
      <>
        <TableCell>
          <Input
            type="text"
            value={inputValue}
            onChange={handleChangeInputValue}
            placeholder="ejemplo@email.com"
          />
        </TableCell>
        <TableCell>
          <Tooltip title="Aceptar">
            <IconButton style={{ color: "green" }} onClick={handleEditEmail}>
              <CheckIcon style={{ cursor: "pointer" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Cancelar">
            <IconButton style={{ color: "red" }} onClick={handleCancelEditing}>
              <Close style={{ cursor: "pointer" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </>
    );
  }

  return (
    <>
      <TableCell>
        {loading ? (
          <Skeleton animation="pulse" />
        ) : (
          email.email
        )}
      </TableCell>
      <TableCell>
        <Tooltip title="Editar">
          <IconButton onClick={() => setEditing(true)}>
            <EditIcon style={{ cursor: "pointer" }} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Eliminar">
          <IconButton onClick={handleDeleteEmail}>
            <DeleteIcon style={{ cursor: "pointer" }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </>
  );
}