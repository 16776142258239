import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import empty from "../../resources/images/empty.png";
import { Link } from "react-router-dom";
import DonacionesConfiguraciones from './DonacionesConfiguraciones';

export default function DonacionesRow({ item, onOpen, editLink }) {
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => { onOpen(item) }}
          >
            {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link to={`${editLink}${item.idComercio}`}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
        </TableCell>
        <TableCell>
          <div className="donacion_logo_container">
            <img alt="logo" src={item.urlLogo || empty} />
          </div>
        </TableCell>
        <TableCell><Typography>{item.nombreComercio.toUpperCase()}</Typography></TableCell>
        <TableCell><Typography>{item.rut}</Typography></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} timeout={1000}>
          <Collapse in={item.open} unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <DonacionesConfiguraciones item={item} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
