import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import FiltroTexto from "../../components/FiltroTexto";
import {
  crearGetNetScanRequest,
  editarGetnetScanRequest,
  obtenerDatosRequest,
} from "../../connection/mantenedores/getnetscanMantendor";
import { getToken } from "../../connection/tokenController";
import {
  obtenerComercioPorIdRequest,
  obtenerComerciosPorIdAppRequest,
} from "../../connection/mantenedores/comercioMantenedor";
import { useLoader } from "../../context/LoaderContext";
import GscanTable from "../../components/GScanTable/GscanTable";
import {
  getIdComercio,
  isComercioUserRole,
  isLoginComercio,
} from "../../connection/loginController";
import {
  APP_GSCAN,
  obtenerAppsHabilitadas,
} from "../../connection/AppsController";
import { useNavigate } from "react-router-dom";
import { obtenerListadoApps } from "../../connection/mantenedores/aplicacionesMantenedor";
import GscanDialog from "../../components/GscanDialog";
import GscanDialogContent from "../../components/GscanDialog/GscanDialogContent";

export default function GetnetScan() {
  const navigate = useNavigate();
  const [comerciosHabilitados, setComerciosHabilitados] = useState([]);
  const [comercioOnly, setComercioOnly] = useState({
    idComercio: 0,
    nombreComercio: "",
    rut: "",
    clientId: "",
    clientSecret: "",
    endpoints: [],
  });
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [comerciosFiltrados, setComerciosFiltrados] = useState([]);
  const [filterText, setFilterText] = useState("");

  // USEFFECT INICIALIZADOR
  useEffect(() => {
    inicializar();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const inicializar = async () => {
    await checkearValidRoute();
    handleBuscarData();
  }

  function handleChangeInputFilter(text) {
    setFilterText(text);
  }

  const mapearListadoComerciosGetnetScan = (listadoUrls, listadoComercios) => {
    return listadoComercios.map((comercio) => ({
      idComercio: comercio.idComercio,
      nombreComercio: comercio.nombreComercio,
      rut: comercio.rut,
      clientId: comercio.clientId,
      clientSecret: comercio.clientSecret,
      endpoints: listadoUrls.filter(
        (url) => comercio.idComercio === url.idComercio
      ),
    }));
  };

  const obtenerIdAppGScan = async () => {
    const apps = await obtenerListadoApps(getToken());
    return apps.find((app) => app.nombre.toLowerCase() === "g-scan").idApps;
  };

  const obtenerListadoEndpoint = async () => {
    try {
      const idAppGScan = await obtenerIdAppGScan();
      const listadoComercios = await obtenerComerciosPorIdAppRequest(
        idAppGScan,
        getToken()
      );
      const listadoUrls = await obtenerDatosRequest(getToken());
      return mapearListadoComerciosGetnetScan(listadoUrls, listadoComercios);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const obtenerSetearListadoDeEndpoints = async () => {
    showLoader();
    try {
      const listadoFinal = await obtenerListadoEndpoint();
      setComerciosHabilitados(listadoFinal);
      setComerciosFiltrados(listadoFinal);
      hideLoader();
    } catch (error) {
      showNotification("error", error.message);
    }
  };

  useEffect(() => {
    const dataToFilter = isComercioUserRole()
      ? [comercioOnly]
      : comerciosHabilitados;
    const filteredData = dataToFilter.filter(
      (comercio) =>
        comercio.nombreComercio
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        comercio.rut.includes(filterText)
    );
    setComerciosFiltrados(filteredData);
  }, [filterText, comerciosHabilitados, comercioOnly]);

  const checkearValidRoute = async () => {
    if (isLoginComercio()) {
      const nombresApps = await obtenerAppsHabilitadas();
      if (!nombresApps.includes(APP_GSCAN)) {
        navigate("/404");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await checkearValidRoute();
      await obtenerSetearListadoDeEndpoints();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  // MODIFICACION DE ENDPOINTS ==================================
  const handleTogleHabilitarEndpoint = async (endpoint) => {
    try {
      showLoader();
      const respuesta = await editarGetnetScanRequest(
        endpoint.idGetnetScan,
        { ...endpoint, habilitado: !endpoint.habilitado },
        getToken()
      );
      if (respuesta.ok) {
        handleBuscarData();
        showNotification("success", "Modificado exitosamente");
      }
    } catch (error) {
      console.error(error);
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };

  const handleSaveEndpoint = async (data) => {
    const isEdit = data.idGetnetScan > 0;
    try {
      showLoader();
      const token = getToken();
      if (isEdit) {
        await editarGetnetScanRequest(data.idGetnetScan, data, token);
      } else {
        await crearGetNetScanRequest(data, token);
      }
      handleBuscarData();
      showNotification(
        "success",
        isEdit ? "Url Modificada exitosamente" : "Endpoint creado exitosamente"
      );
    } catch (error) {
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };

  const [gscanDialogControl, setGscanDialogControl] = useState({
    open: false,
    data: {
      idComercio: 0,
      nombreComercio: "",
      rut: "",
      clientId: "",
      clientSecret: "",
      endpoints: [],
    },
  });

  const handleOpenDialog = (data) => {
    setGscanDialogControl({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setGscanDialogControl({ open: false, data: gscanDialogControl.data });
  };

  const handleBuscarData = async () => {
    if (isComercioUserRole()) {
      establecerComercioOnly();
      return;
    }
    await checkearValidRoute();
    await obtenerSetearListadoDeEndpoints();
  };

  useEffect(() => {
    if (gscanDialogControl.open) {
      const data = comerciosHabilitados.find(
        (x) => x.idComercio === gscanDialogControl.data.idComercio
      );
      setGscanDialogControl((prevState) => ({ ...prevState, data }));
    }
  }, [
    comerciosHabilitados,
    gscanDialogControl.open,
    gscanDialogControl.data.idComercio,
  ]);

  // ONLY COMERCIO =================================
  const establecerComercioOnly = async () => {
    showLoader();
    try {
      const listadoUrls = await obtenerDatosRequest(getToken());
      const idComercio = Number(getIdComercio());
      const comercio = await obtenerComercioPorIdRequest(
        idComercio,
        getToken()
      );
      const objeto = {
        clientId: comercio.clientId,
        clientSecret: comercio.clientSecret,
        endpoints: listadoUrls.filter((x) => x.idComercio === idComercio),
        idComercio: comercio.idComercio,
        nombreComercio: comercio.nombreComercio,
        rut: comercio.rut,
      };
      setComercioOnly(objeto);
    } catch (error) {
      showNotification("error", "Ha ocurrido un error inesperado");
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        PARAMETROS APP G-SCAN
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        {isComercioUserRole() ? (
          <GscanDialogContent
            key={comercioOnly.idComercio}
            onToggleHabilitarEndpoint={handleTogleHabilitarEndpoint}
            onSave={handleSaveEndpoint}
            data={comercioOnly}
          />
        ) : (
          <>
            <div>
              <FiltroTexto type="text" onFilter={handleChangeInputFilter} />
            </div>
            <br />
            <GscanTable
              listado={comerciosFiltrados}
              onOpenDialog={handleOpenDialog}
            />
          </>
        )}
      </ShadowBox>
      <GscanDialog
        open={gscanDialogControl.open}
        data={gscanDialogControl.data}
        onClose={handleCloseDialog}
        onToggleHabilitarEndpoint={handleTogleHabilitarEndpoint}
        onSave={handleSaveEndpoint}
      />
    </>
  );
}
