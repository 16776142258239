import { Box, Typography } from '@mui/material'
import React from 'react'
import ShadowBox from '../../components/ShadowBox'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
// import { isComercioUserRole } from '../../connection/loginController'
const styles = {
    box: {
        height: "100vh", 
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(0deg, #D4E8FF, white)",
    },
    shadowBox: {
        borderRadius: "10px",
        padding: '2rem 1.5rem',
        textAlign: 'center',
        maxWidth: "20rem"
    }
}
export default function PasswordRestablecida() {
    const navigate = useNavigate();
    function handleConfirm(e) {
        e.preventDefault();
        navigate('/login');
    }
    return (
        <Box sx={styles.box}>
            <ShadowBox style={styles.shadowBox}>
                <Typography variant="h6" gutterBottom>
                    CONTRASEÑA RESTABLECIDA EXITOSAMENTE
                </Typography>
                <br />
                <Typography sx={{ textAlign: 'center' }}>
                    Tu contraseña ha sido restablecida exitosamente
                </Typography>
                <br />
                <Button onClick={handleConfirm} largeButton>VOLVER</Button>
            </ShadowBox>
        </Box>
    )
}
