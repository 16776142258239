import LoginController from "./pages/auth/LoginController.jsx";
import CambioClave from "./pages/usuarios/CambioClave";
import HomeLayout from "./layouts/HomeLayout";
import Home from "./pages/Home";
import PosMantenedor from "./pages/pos/PosMantenedor";
import EditarCrearPos from "./pages/pos/EditarCrearPos";
import ComerciosMantenedor from "./pages/comercios/ComerciosMantenedor";
import EditarCrearComercio from './pages/comercios/EditarCrearComercio.jsx';
import Donaciones from "./pages/donaciones/Donaciones";
import EditarDonacion from "./pages/donaciones/EditarDonacion";
import HealthCheck from "./pages/HealthCheck.jsx";
import NotFound from "./pages/NotFound/NotFound.jsx";
import Logout from "./pages/auth/Logout.jsx";
import ForgotPassword from "./pages/auth/ForgotPassword.jsx";
import C2CTransacciones from "./pages/transacciones/C2CTsx.jsx";
// import MultiComerciosTransacciones from "./pages/transacciones/MultiComerciosTsx.jsx";
import GScanTransacciones from "./pages/transacciones/GScanTsx.jsx";
import DonacionesTsx from "./pages/transacciones/DonacionesTsx.jsx";
import PasswordRestablecida from "./pages/auth/PasswordRestablecida.jsx";
import GetnetScan from "./pages/getnetscan/GetnetScan.jsx";
import Logs from "./pages/logs/Logs.jsx";
import UsuariosOperaciones from "./pages/usuarios/operaciones/UsuariosOperaciones.jsx";
import CrearUsuarioOperaciones from "./pages/usuarios/operaciones/CrearUsuarioOperaciones.jsx";
import EditarUsuarioOperaciones from "./pages/usuarios/operaciones/EditarUsuarioOperaciones.jsx";
import UsuariosComercios from "./pages/usuarios/comercios/UsuariosComercios.jsx";
import CrearUsuarioComercio from "./pages/usuarios/comercios/CrearUsuarioComercio.jsx";
import EditarUsuarioComercio from "./pages/usuarios/comercios/EditarUsuarioComercio.jsx";
import UsuariosDesarrollo from "./pages/usuarios/desarrollo/UsuariosDesarrollo.jsx";
import CrearUsuarioDesarrollo from "./pages/usuarios/desarrollo/CrearUsuarioDesarrollo.jsx";
import Aplicaciones from "./pages/Aplicaciones/Aplicaciones.jsx";
import EditarUsuarioDesarrollos from "./pages/usuarios/desarrollo/EditarUsuarioDesarrollo.jsx";
import EditarAplicaciones from "./pages/Aplicaciones/EditarAplicaciones.jsx";
import AplicacionesMantenedor from "./pages/Aplicaciones/AplicacionesMantenedor.jsx";
import { DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, ADMIN_ROLE_VALUE, COMERCIO_ROLE_VALUE } from "./connection/loginController.js";
// import MultiComerciosMantenedor from "./pages/MultiComercio/MultiComerciosMantenedor.jsx";
// import EditarCrearMultiComercios from "./pages/MultiComercio/EditarCrearMultiComercios.jsx";
 
 
const rutas = [
    { ruta: "/login", element: <LoginController /> },
    { ruta: "/logout", element: <Logout /> },
    { ruta: "/cambio-clave", element: <CambioClave /> },
    {
        ruta: "/",
        element: <HomeLayout />,
        children: [
            { index: "index", restrictionRole: [DESARROLLO_ROLE_VALUE,OPERACIONES_ROLE_VALUE, ADMIN_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <Home /> },
            { ruta: "pos", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <PosMantenedor /> },
            { ruta: "pos/nuevo-pos", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], element: <EditarCrearPos edit={false} /> },
            { ruta: "pos/editar-pos", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], element: <EditarCrearPos edit={true} /> },
            { ruta: "usuarios", restrictionRole: [ADMIN_ROLE_VALUE], element: <UsuariosOperaciones /> },
            { ruta: "usuarios/nuevo-usuario", restrictionRole: [ADMIN_ROLE_VALUE], element: <CrearUsuarioOperaciones /> },
            { ruta: "usuarios/editar-usuario", restrictionRole: [ADMIN_ROLE_VALUE], element: <EditarUsuarioOperaciones /> },
            { ruta: "usuarios-desarrollo", restrictionRole: [ADMIN_ROLE_VALUE], element: <UsuariosDesarrollo /> },
            { ruta: "usuarios/nuevo-usuario-desarrollo", restrictionRole: [ADMIN_ROLE_VALUE], element: <CrearUsuarioDesarrollo /> },
            { ruta: "usuarios/editar-usuario-desarrollo", restrictionRole: [ADMIN_ROLE_VALUE], element: <EditarUsuarioDesarrollos /> },
            { ruta: "usuarios-comercios", restrictionRole: [ADMIN_ROLE_VALUE], element: <UsuariosComercios /> },
            { ruta: "usuarios-comercios/nuevo-usuario-comercio", restrictionRole: [ADMIN_ROLE_VALUE], element: <CrearUsuarioComercio  /> },
            { ruta: "usuarios-comercios/editar-usuario", restrictionRole: [ADMIN_ROLE_VALUE], element: <EditarUsuarioComercio /> },
            { ruta: "comercios", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <ComerciosMantenedor /> },
            // { ruta: "multi-comercios", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <MultiComerciosMantenedor /> },
            // { ruta: "multi-comercios/editar-multi-comercios", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], element: <EditarCrearMultiComercios edit={true} /> },
            // { ruta: "multi-comercios/nuevo-multi-comercios", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], element: <EditarCrearMultiComercios edit={false} /> },
            { ruta: "aplicaciones", restrictionRole: [DESARROLLO_ROLE_VALUE], element: <AplicacionesMantenedor />},
            { ruta: "comercios/aplicaciones/:idComercio", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], element: <Aplicaciones />},
            { ruta: "aplicaciones/crear-aplicacion", restrictionRole: [DESARROLLO_ROLE_VALUE], element: <EditarAplicaciones edit={false} />},
            { ruta: "aplicaciones/editar-aplicaciones/:idApps", restrictionRole: [DESARROLLO_ROLE_VALUE], element: <EditarAplicaciones edit={true} />},
            { ruta: "comercios/nuevo-comercio", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], element: <EditarCrearComercio edit={false} /> },
            { ruta: "comercios/editar-comercio", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], element: <EditarCrearComercio edit={true} /> },
            { ruta: "donaciones", app: 'donaciones', restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <Donaciones /> },
            { ruta: "donaciones/editar-donacion/:idComercio", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <EditarDonacion /> },
            { ruta: "c2c-txs", app: 'c2c', restrictionRole: [DESARROLLO_ROLE_VALUE,OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <C2CTransacciones /> },
            // { ruta: "multi-comercios-txs", app: 'multi-comercios', restrictionRole: [DESARROLLO_ROLE_VALUE,OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <MultiComerciosTransacciones /> },
            { ruta: "g-scan-txs", app: 'g-scan', restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <GScanTransacciones /> },
            { ruta: "donaciones-txs", app: 'donaciones', restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <DonacionesTsx /> },
            { ruta: "getnetscan", app: 'g-scan', restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE, COMERCIO_ROLE_VALUE], element: <GetnetScan /> },
            { ruta: "logs", restrictionRole: [ADMIN_ROLE_VALUE], element: <Logs /> },
            { ruta: "healthcheck", restrictionRole: [DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE], element: <HealthCheck /> },
        ],
    },
    { ruta: "olvide-mi-pass", element: <ForgotPassword /> },
    { ruta: "password-restablecida", element: <PasswordRestablecida /> },
    { ruta: "/*", element: <NotFound /> }
];
 
export default rutas;