import { Divider, Drawer, List, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRolUsuario } from "../../connection/loginController";
import SidebarItem from "./SidebarItem";
import SidebarLink from "./SidebarLink";
import LogoutIcon from '@mui/icons-material/Logout';
import { VERSION } from '../../version';

function compararRutas(ruta, to) {
  if (to.includes("/*")) {
    const split = ruta.split("/");
    ruta = ruta.replace(`/${split[split.length - 1]}`, "");
    to = to.replace("/*", "");
  }
  return ruta === to
}
function compararRutasChild(ruta, to) {
  return ruta.includes(to)
}

export default function Sidebar(props) {
  const isDesktop = props.isDesktop;
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleSelectItem = (itemId) => {
    props.onClose();
    setSelectedItemId(itemId);
  };
  const hasAccess = (rol) => {
    const userRol = getRolUsuario();
    if (typeof rol === 'string') {
      return userRol === rol;
    }
    return rol.includes(userRol)
  };

  const handleCheckPageSelected = () => {
    const selectedId = findSelectedPage(props.pages);
    setSelectedItemId(selectedId);
  };
  const findSelectedPage = (pages) => {
    for (let page of pages) {
      if (page.children) {
        const childPage = page.children.find(child => compararRutasChild(window.location.pathname, child.to));
        if (childPage)
          return childPage.id;
      }
      else {
        if (compararRutas(window.location.pathname, page.to)) {
          return page.directed_id ? page.directed_id : page.id
        }
      }
    }
    return null;
  };

  useEffect(() => {
    handleCheckPageSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, props.pages]);

  return (
    <Drawer
      variant={isDesktop ? "permanent" : "temporary"}
      open={props.open}
      onClose={props.onClose}
      sx={{
        width: 240,
        flexShrink: 0,
        overflowY: "auto",
        maxHeight: "100vh",
        [`& .MuiDrawer-paper`]: {
          width: isDesktop ? 240 : "60vw",
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <List>
        {props.pages.filter(x => !x.hide).map(
          (item, idx) =>
          hasAccess(item.rol) ? (
              <SidebarItem
                key={idx}
                item={item}
                onSelect={handleSelectItem}
                selectedId={selectedItemId}
              />
            ) : <React.Fragment key={idx} />
        )}
      </List>
      <div style={{ flexGrow: 1 }}></div>
      <List sx={{ display: { sm: "block", md: "none" } }}>
        <Divider />
        <SidebarLink item={{ to: "/logout", name: "Cerrar sesión", icon: <LogoutIcon /> }} />
      </List>
      <Typography sx={{ textAlign: 'center', fontStyle: 'italic', color: '#444' }} >Versión: {VERSION}</Typography>

    </Drawer>
  );
}
