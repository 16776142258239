import { getApiUrl, RequestContentType, deleteRequest, getRequest, postRequest, putRequest } from "../apiConnection";

const API_URL = getApiUrl();

export async function crearApps (body, token){
    return await postRequest(API_URL, '/api/apps', RequestContentType.APPLICATION_JSON, body, token)
}
export async function obtenerListadoApps (token){
    return await getRequest(API_URL, '/api/apps', token)
}
export async function editarApps(idApp, body, token){
    return await putRequest(API_URL, `/api/apps/${idApp}`, RequestContentType.APPLICATION_JSON, body, token)
}
export async function crearAppComercio(body, token){
    return await postRequest(API_URL, '/api/appscomercios', RequestContentType.APPLICATION_JSON, body, token)
}
export async function obtenerAppComercio (idComercio, token){
    return await getRequest(API_URL, `/api/appscomercios?idComercio=${idComercio}`, token)
}
export async function eliminarAppComercio (idApp, token){
    return await deleteRequest(API_URL, `/api/appscomercios/${idApp}`, token)
}
export async function eliminarApp(idApp, token){
  return await deleteRequest(API_URL, `/api/apps/${idApp}`, token)
}