import React, { useState, useEffect } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Switch,
} from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import { useLoader } from "../../context/LoaderContext";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import {
  crearAppComercio,
  eliminarAppComercio,
  obtenerAppComercio,
  obtenerListadoApps,
} from "../../connection/mantenedores/aplicacionesMantenedor";
import { getToken } from "../../connection/tokenController";
import Dialogo from "../../components/Dialogo";
import { useParams } from "react-router-dom";
import { obtenerComercioPorIdRequest } from "../../connection/mantenedores/comercioMantenedor";

const breadcrumbItems = [
  { href: "/comercios", label: "Comercios" },
  { label: "aplicaciones" },
];

function Aplicaciones(props) {
  let { idComercio } = useParams();
  const [apps, setApps] = useState([]);
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [dialogo, setDialogo] = useState({
    open: false,
    app: { nombre: "" },
  });
  const [nombreComercio, setNombreComercio] = useState("");
  
  const obtenerListadoAplicaciones = async () => {
    showLoader();
    try {
      const appDisponibles = await obtenerListadoApps(getToken());
      const appComercio = await obtenerAppComercio(idComercio, getToken());
      const aplicaciones = appDisponibles.map((aplicacion) => {
        const comercioApp = appComercio.find((x) => x.idApps === aplicacion.idApps);
        return {
          idAppComercio: comercioApp ? comercioApp.idAppsComercios : null,
          idApps: aplicacion.idApps,
          nombre: aplicacion.nombre,
          habilitado: comercioApp ? true : false,
        };
      });
      setApps([...aplicaciones]);
    }catch(error) {
      showNotification("error", "Ha ocurrido un error");
      console.error(error);
    }finally {
      hideLoader();
    }
  }
  useEffect(() => {
    obtenerListadoAplicaciones();
    obtenerNombreComercio();
    // eslint-disable-next-line
  }, []);

  const handleSwitchChange = async (app) => {
    if (app.habilitado) {
      setDialogo({ open: true, app: app });
      return;
    }
    await toggleApp(app);
  };

  const handleConfirmHabilitarApp = async () => {
    await toggleApp(dialogo.app);
    handleCancelDialog();
  };

  const handleCancelDialog = () => {
    setDialogo({ ...dialogo, open: false });
  };
  const obtenerNombreComercio = async () => {
    const comercio = await obtenerComercioPorIdRequest(idComercio, getToken());
    setNombreComercio(comercio.nombreComercio)
  }

  const toggleApp = async (app) => {
    showLoader();
    let res;
    try {
      const idAppComercio = app.idAppComercio;
      if (!app.habilitado) {
        const body = {
          idApps: app.idApps,
          idComercio: idComercio
        }
        res = await crearAppComercio(body, getToken());
      } else {
        res = await eliminarAppComercio(idAppComercio, getToken());
      }
      if (res.ok) {
        obtenerListadoAplicaciones();
        showNotification("success", "Actualizado Correctamente");
      } else {
        throw new Error("Fallo en la actualización");
      }
    } catch (error) {
      showNotification("error", error.message || "Algo salió mal");
    } finally {
      setTimeout(hideLoader, 500);
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        APLICACIONES
      </Typography>
      <ShadowBox>
        <CustomBreadcrumbs items={breadcrumbItems} />
        <h2>{nombreComercio}</h2>
        <hr></hr>
        <Table
          sx={{ minWidth: 375, width: "fit-content" }}
          aria-label="Tabla comercios"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <b>APLICACIÓN</b>
              </TableCell>
              <TableCell>
                <b>HABILITAR</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apps.map((app, index) => (
              <TableRow 
              key={index}>
                <TableCell>{app.nombre}</TableCell>
                <TableCell>
                  <Switch
                    checked={app.habilitado}
                    onChange={() => handleSwitchChange(app)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ShadowBox>
      <Dialogo
        open={dialogo.open}
        onClose={handleCancelDialog}
        title="¿Quieres Deshabilitar esta Aplicación?"
        text={`Estás seguro de deshabilitar la APP ${dialogo.app.nombre}`}
        onConfirm={handleConfirmHabilitarApp}
      />
    </>
  );
}

export default Aplicaciones;
