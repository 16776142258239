import React from 'react';
import { Grid, Typography } from "@mui/material";

export default function DonacionesConfiguraciones({ item }) {
  if (!item) {
    return <Typography variant="h6">No hay datos disponibles</Typography>;
  }
  return (
    <Grid container direction="row" spacing={2} alignItems="stretch" justifyContent="flex-start">
      <Grid item xs={12} sm={6} md={4}>
        <div className="donacion_data_container">
          <Typography variant="h6">Configuración Textos</Typography>
          <table className="grid-table">
            <tbody>
              <tr>
                <th>Texto título:</th>
                <td>{item.textoTitulo || 'N/A'}</td>
              </tr>
              <tr>
                <th>Texto total:</th>
                <td>{item.textoTotal || 'N/A'}</td>
              </tr>
              <tr>
                <th>Texto botón:</th>
                <td>{item.textoBoton || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <div className="donacion_data_container">
          <Typography variant="h6">Configuración Montos</Typography>
          <table className="grid-table">
            <tbody>
              <tr>
                <th>Monto mínimo:</th>
                <td style={{ textAlign: "right" }}>
                  ${item.minimo ? item.minimo.toLocaleString("es-CL") : 'N/A'}
                </td>
              </tr>
              <tr>
                <th>Monto máximo:</th>
                <td style={{ textAlign: "right" }}>
                  ${item.maximo ? item.maximo.toLocaleString("es-CL") : 'N/A'}
                </td>
              </tr>
              <tr>
                <th>Múltiplo:</th>
                <td style={{ textAlign: "right" }}>
                  {item.multiplo ? item.multiplo.toLocaleString("es-CL") : 'N/A'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <div className="donacion_data_container">
          <Typography variant="h6">Configuración Botones</Typography>
          <table className="grid-table">
            <tbody>
              <tr>
                <th>Botón monto 1:</th>
                <td style={{ textAlign: "right" }}>
                  ${item.monto1 ? item.monto1.toLocaleString("es-CL") : 'N/A'}
                </td>
              </tr>
              <tr>
                <th>Botón monto 2:</th>
                <td style={{ textAlign: "right" }}>
                  ${item.monto2 ? item.monto2.toLocaleString("es-CL") : 'N/A'}
                </td>
              </tr>
              <tr>
                <th>Botón monto 3:</th>
                <td style={{ textAlign: "right" }}>
                  ${item.monto3 ? item.monto3.toLocaleString("es-CL") : 'N/A'}
                </td>
              </tr>
              <tr>
                <th>Botón monto 4:</th>
                <td style={{ textAlign: "right" }}>
                  ${item.monto4 ? item.monto4.toLocaleString("es-CL") : 'N/A'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Grid>
    </Grid>
  );
}
