import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import RoundButton from "../../components/RoundButton";
import FiltroTexto from "../../components/FiltroTexto";
import { Link } from "react-router-dom";
import ComerciosTable from "../../components/ComerciosTable";
import { obtenerComercioPorIdRequest, obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import { useLoader } from "../../context/LoaderContext";
import { getToken } from "../../connection/tokenController";
import { isProduction } from "../../utils/utilidades";
import { getIdComercio, isComercioUserRole } from "../../connection/loginController";

const estilos = {
  divRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: '1rem'
  },
};

const ComerciosMantenedor = () => {
  let navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [textoFiltrar, setTextoFiltrar] = useState("");
  const [listado, setListado] = useState([]);
  const [idComercio /*setIdComercio*/] = useState(getIdComercio())

  const handleChangeInputFilter = (texto) => {
    setTextoFiltrar(texto);
  };

  const handleEdit = (row) => {
    navigate("/comercios/editar-comercio", { state: { comercio: row } });
  };

  const onlyComercio = async () => {
    try {
      const token = getToken();
      const onlyComercio = await obtenerComercioPorIdRequest(idComercio, token);
      setListado([onlyComercio]);
    } catch (error) {
      showNotification("error", "Ha ocurrido un error de conexión");
    }
  };

  const handleSetListado = async () => {
    try {
      showLoader();
      if (isComercioUserRole()) {
        return onlyComercio();
      }
      const token = getToken();
      let listadoComercios = await obtenerTodosLosComerciosRequest(token);
      listadoComercios = listadoComercios.filter((x) => x.habilitado);
      listadoComercios = listadoComercios.sort((a, b) => {
        if (a.nombreComercio < b.nombreComercio) return -1;
        if (a.nombreComercio > b.nombreComercio) return 1;
        return 0;
      });
      const ordenListado= listadoComercios.map(x => { return {...x, nombreComercio: x.nombreComercio.toUpperCase()}})
      setListado(ordenListado);
    } catch (error) {
      console.error("Error al obtener los comercios:", error);
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
      } else {
        showNotification("error", "Ha ocurrido un error inesperado");
      }
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    handleSetListado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!idComercio || Number(idComercio) === 0) {
      handleSetListado();
    } else {
      onlyComercio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idComercio]);

  return (
    <>
      <Typography variant="h5" sx={{ margin: '1rem 0' }}>COMERCIOS</Typography>
      <br />
      <ShadowBox style={{ padding: '2rem' }}>
        <div style={estilos.divRow}>
          {
            isProduction() || isComercioUserRole() ?
              (
                <>  </>
              ) : (
                <>
                  <FiltroTexto type="text" onFilter={handleChangeInputFilter} />
                  <Link
                    to="/comercios/nuevo-comercio"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    <RoundButton variant="outlined">AGREGAR</RoundButton>
                  </Link>
                </>
              )
          }
        </div>
        <br />
        <ComerciosTable
          listado={listado}
          filterText={textoFiltrar}
          onEdit={handleEdit}
        />
      </ShadowBox>
    </>
  );
};

export default ComerciosMantenedor;