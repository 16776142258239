import { getApiUrl, getRequest } from "../apiConnection";

const API_URL = getApiUrl();

export async function obtenerTxsC2CRequest(idPosData, desde, hasta, comando, token){
    const url = `/api/postxs?idPosData=${idPosData}&FechaRequestDesde=${desde}&FechaRequestHasta=${hasta}&Comando=${comando}`;
    return await getRequest(API_URL, url, token);
}
export async function obtenerTxsGScanRequest(idPosData, desde, hasta, comando, token){
    const url = `/api/getnetscantxs?idPosData=${idPosData}&FechaRequestDesde=${desde}&FechaRequestHasta=${hasta}&Comando=${comando}`;
    return await getRequest(API_URL, url, token);
}
export async function obtenerTxsDonacionesRequest(idPosData, desde, hasta, comando, token){
    const url = `/api/donacionestxs?idPosData=${idPosData}&FechaRequestDesde=${desde}&FechaRequestHasta=${hasta}&Comando=${comando}`;
    return await getRequest(API_URL, url, token);
}