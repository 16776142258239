import React, { useEffect, useState } from "react";
import {
    Divider,
    Typography,

} from "@mui/material";
import ShadowBox from "../../../components/ShadowBox";
import Button from "../../../components/Button";
import CustomBreadcrumbs from "../../CustomBreadcrumbs";
import Input from "../../Input/Input";
// import { formatearRut, validarRut } from "../../../utils/utilidades";
import Combobox from "../../Combobox";



export default function FormularioUsuario(props) {
    const [usuario, setUsuario] =
        useState(!props.usuario ? { nombre: "", emailUsuario: "" } : props.usuario);
    const [errorMessage, setErrorMessage] = useState(props.errorMessage);

    const handleChangeNombre = (e) => {
        setUsuario({ ...usuario, nombre: e.target.value });
    };
    const handleChangeEmailUsuario = (e) => {
        setUsuario((prevState) => ({ ...prevState, emailUsuario: e.target.value }));
    };

    const handleSelectComercio = (e) => {
        setUsuario({ ...usuario, idComercio: e.codigo });
    }
    const handleUnSelectComercio = () => {
        setUsuario({ ...usuario, idComercio: null });
    }

    const handleConfirm = (e) => {
        e.preventDefault();
        if (validarDatos()) {
            props.onConfirm(usuario);
        }
    };
    const validarDatos = () => {
        if (props.comercios && !usuario.idComercio) {
            setErrorMessage("Por favor seleccione comercio");
            return false;
        }
        if (!usuario.nombre || !usuario.emailUsuario) {
            setErrorMessage("Por favor complete todos los campos.");
            return false;
        }
        if (!usuario.nombre.trim()) {
            setErrorMessage("Por favor complete todos los campos.");
            return false;
        }
        if (!usuario.emailUsuario.trim()) {
            setErrorMessage("Por favor complete todos los campos.");
            return false;
        }
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(usuario.emailUsuario)) {
            setErrorMessage("Por favor, introduce un correo electrónico válido.");
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (props.usuario !== null) {
            setUsuario(props.usuario);
            if (props.usuario.idComercio && props.comercios) {
                const selectedCommerce = props.comercios.find(com => com.codigo === props.usuario.idComercio);
                props.onSelectComercio(selectedCommerce ? selectedCommerce : { nombre: "NOMBRE COMERCIO" });
            }
        }
        // eslint-disable-next-line
    }, [props.usuario, props.comercios]);

    useEffect(() => {
        setErrorMessage(props.errorMessage);
    }, [props.errorMessage]);
    useEffect(() => {
        if (props.usuario !== null)
            setUsuario(props.usuario)
    }, [props.usuario]);


    return (
        <>
            <Typography variant="h5" sx={{ margin: '1rem 0' }}>{props.titulo}</Typography>
            <br />
            <ShadowBox style={{ padding: '2rem' }}>
                <CustomBreadcrumbs items={[...props.breadcrumbItems, { label: props.titulo }]} />

                <form>
                    <Divider></Divider>
                    {
                        props.comercioSeleccionado && <>
                            <br />
                            <label style={{ color: "black" }}>COMERCIO:</label>
                            <Typography variant="h5"
                                sx={{ margin: '0.3rem 0' }}>
                                {props.comercioSeleccionado}
                            </Typography>
                            <Divider></Divider>
                        </>
                    }

                    <br />
                    {
                        !props.comercios ? <></> :
                            <>
                                <label style={{ color: "black" }}>Comercios</label>
                                <br />
                                <Combobox
                                    rows={props.comercios || []} // Asegúrate de que listaComandos esté definida
                                    placeholder="Comercio"
                                    onFilter={() => { }}
                                    onSelect={handleSelectComercio}
                                    onUnSelect={handleUnSelectComercio}
                                />
                                <br />
                                <br />
                            </>
                    }
                    <label style={{ color: "black" }}>NOMBRE</label>
                    <br />
                    <Input
                        type="text"
                        placeholder="Nombre"
                        value={usuario.nombre}
                        onChange={handleChangeNombre}
                    />
                    <br />
                    <br />
                    <label style={{ color: "black" }}>CORREO ELECTRÓNICO</label>
                    <br />
                    <Input
                        type="email"
                        placeholder="Email"
                        value={usuario.emailUsuario}
                        onChange={handleChangeEmailUsuario}
                    />
                    <br />
                    {errorMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                            {errorMessage}
                        </div>
                    )}
                    <br />
                    <br />
                    <Button
                        style={{ marginRight: "10px" }}
                        variant="gray"
                        onClick={props.onCancel}
                    >
                        CANCELAR
                    </Button>
                    <Button onClick={handleConfirm}>{props.buttonText}</Button>
                </form>
            </ShadowBox>
        </>
    );
}
