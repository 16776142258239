import { Box, Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import logo from "../../resources/images/logo.png";
import Button from "../../components/Button";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Input from "../../components/Input";
import { useLoader } from '../../context/LoaderContext'
import { cambiarPrimeraClaveResquest } from "../../connection/mantenedores/usuarioMantenedor";
import { logoutHandler } from "../../connection/loginController";
import { passwordValidation } from '../../utils/passwordValidation';

const styles = {
    box: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(0deg, #D4E8FF, white)"
    },
    imgLogo: {
        width: "8rem",
    },
    shadowBox: {
        borderRadius: "20px",
        padding: '2rem 1.5rem',
        textAlign: 'center',
        width: '20rem'
    },
    olvidadPassword: {
        color: "#c1c1c1",
    }
};

const COLOR_ROJO = "#FF0000", COLOR_GREEN = "#00c853";

export default function CambioClave() {
    const navigate = useNavigate();
    const { showLoader, hideLoader, showNotification } = useLoader();

    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');

    const handleChangeNewPassword = (e) => {
        setNewPassword(e.target.value);
    };

    const handleChangeConfirmNewPassword = (e) => {
        setError("")
        setConfirmNewPassword(e.target.value);
    };
    const handleSendClick = (e) => {
        e.preventDefault();
        cambiarPassword();
    };

    const validacionPassword = () => {
        try {
            if (!passwordValidation.validarLongitud(newPassword)) {
                return false
            }
            else if (!passwordValidation.validarSinEspacio(newPassword)) {
                return false
            }
            else if (!passwordValidation.validarMayuscula(newPassword)) {
                return false
            }
            else if (!passwordValidation.validarMinuscula(newPassword)) {
                return false
            }
            else if (!passwordValidation.validarNumero(newPassword)) {
                return false
            }
            else if (!passwordValidation.validarSimbolo(newPassword)) {
                return false
            }
            return true;
        } catch (error) {
            return false;
        }
    }

    const cambiarPassword = async () => {
        if (!validacionPassword())
            return;
        if (!newPassword.trim() || !confirmNewPassword.trim()) {
            setError("Por favor, complete todos los campos.");
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setError("Las contraseñas no coinciden.");
            return;
        }
        try {
            const userEmail = localStorage.getItem('userEmail');
            const firstPass = localStorage.getItem('firstPass');

            const datos = {
                emailUsuario: userEmail,
                clave: firstPass,
                claveNueva: newPassword
            }
            showLoader();
            await cambiarClave(datos, '');
            logoutHandler();
            showNotification("success", "Clave modificada con éxito");
            hideLoader();
            navigate('/login');

        } catch (err) {
            console.error(err);
            setError('Error en el proceso de actualización de contraseña.');
        }
    };

    const cambiarClave = async (user, token) => {
        await cambiarPrimeraClaveResquest(user, token);
    }
    return (
        <>
            <Box sx={styles.box}>
                <ShadowBox style={styles.shadowBox}>
                    <img style={styles.imgLogo} src={logo} alt="logo" />
                    <br />
                    <Typography variant="p" style={styles.labelLogin} gutterBottom>
                        Cambio de Clave
                    </Typography>
                    <br />
                    <br />
                    <br />
                    <Input type="password" className="gray" completeWidth={true} value={newPassword} onChange={handleChangeNewPassword} placeholder="Nueva Contraseña" />
                    <br />
                    <Input type="password" className="gray" completeWidth={true} value={confirmNewPassword} onChange={handleChangeConfirmNewPassword} placeholder="Confirmar Nueva Contraseña" />
                    <br />
                    <Typography sx={{ color: validacionPassword() ? COLOR_GREEN : COLOR_ROJO }}>
                        *La contraseña debe cumplir los siguientes requisitos
                    </Typography>
                    <ul style={{ textAlign: 'left' }}>
                        <li style={{ color: passwordValidation.validarLongitud(newPassword) ? COLOR_GREEN : COLOR_ROJO }}>Mínimo 12 caractéres de largo</li>
                        <li style={{ color: passwordValidation.validarMayuscula(newPassword) ? COLOR_GREEN : COLOR_ROJO }}>Al menos una mayúscula</li>
                        <li style={{ color: passwordValidation.validarMinuscula(newPassword) ? COLOR_GREEN : COLOR_ROJO }}>Al menos una minúscula</li>
                        <li style={{ color: passwordValidation.validarNumero(newPassword) ? COLOR_GREEN : COLOR_ROJO }}>Al menos un número</li>
                        <li style={{ color: passwordValidation.validarSimbolo(newPassword) ? COLOR_GREEN : COLOR_ROJO }}>Al menos un símbolo</li>
                        <li style={{ color: passwordValidation.validarSinEspacio(newPassword) ? COLOR_GREEN : COLOR_ROJO }}>No debe contener espacios en blanco</li>
                    </ul>
                    {error && (
                        <Typography style={{ color: 'red', wordWrap: 'break-word', maxWidth: "300px" }}>
                            {error}
                        </Typography>
                    )}
                    <br />
                    <br />
                    <Button onClick={handleSendClick} largeButton disabled={(!validacionPassword() || confirmNewPassword.length === 0)}>
                        Guardar Cambios
                    </Button>
                </ShadowBox>
            </Box>
        </>
    );
}