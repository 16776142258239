import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import DonacionesRow from "./DonacionesTableRow";

const styles = {
  smallCell: {
    width: '1%', 
    whiteSpace: 'nowrap'
  }
};

export default function DonacionesTable(props) {
  const [paginationControl, setPaginationControl] = useState({
    page: 0,
    rowsPerPage: 10
  });
  const handleRowsPerPageChange = (e) => {
    setPaginationControl({ ...paginationControl, page: 0, rowsPerPage: e.target.value });
  }
  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  }
  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={styles.smallCell}></TableCell>
            <TableCell sx={styles.smallCell}><Typography>EDITAR</Typography></TableCell>
            <TableCell sx={styles.smallCell}><Typography>LOGO</Typography></TableCell>
            <TableCell><Typography>COMERCIO</Typography></TableCell>
            <TableCell><Typography>RUT</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.listado.slice(paginationControl.rowsPerPage * paginationControl.page
              , paginationControl.rowsPerPage * paginationControl.page + paginationControl.rowsPerPage).map((x, key) => {
              return <DonacionesRow key={key} item={x}  editLink={props.editLink} onOpen={props.onOpen}/>
            })
          }
        </TableBody>
      </Table>
      <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={props.listado.length}
            rowsPerPage={paginationControl.rowsPerPage}
            page={paginationControl.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
    </TableContainer>
  )
}