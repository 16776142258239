import React, { useEffect, useState } from 'react'
import { useLoader } from '../../../context/LoaderContext';
import { editarUsuarioRequest } from '../../../connection/mantenedores/usuarioMantenedor';
import { getToken } from '../../../connection/tokenController';
import FormularioUsuario from '../../../components/Formularios/FormularioUsuario';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const breadcrumbItems = [
    { href: '/usuarios', label: "Usuarios" }
];


export default function EditarUsuarioOperaciones() {
    let location = useLocation();
    let navigate = useNavigate();
    const { showLoader, hideLoader, showNotification } = useLoader();
    const [editUser, setEditUser] = useState({})
    const [errorMessage, setErrorMessage] = useState("");

    const handleVolver = () => {
        navigate("/usuarios");
    };
    const handleConfirm = async (usuario) => {
        showLoader();
        const datos = {
            nombre: usuario.nombre,
            emailUsuario: usuario.emailUsuario,
            habilitado: usuario.habilitado,
            clave: usuario.clave,
        };
        try {
            await editarUsuarioRequest(datos, getToken(), editUser.idUsuario);
            setErrorMessage("");
            showNotification("success", "Usuario editado con éxito");
            handleVolver();
        } catch (error) {
            if (error.status === 500) {
                showNotification("error", "Error de conexión");
                return;
            }
            if (error.status === 409) {
                showNotification("error", "Ya existe un usuario con este correo");
                return;
            }
            showNotification("error", "Ha ocurrido un error inesperado");
        } finally {
            hideLoader();
        }
    };
    useEffect(() => {
        if (location.state?.usuario) {
            setEditUser(location.state?.usuario);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <FormularioUsuario
                titulo="Editar usuario"
                usuario={editUser}
                onCancel={handleVolver}
                onConfirm={handleConfirm}
                buttonText="ACEPTAR"
                errorMessage={errorMessage}
                breadcrumbItems={breadcrumbItems}
            />
        </>
    );
}
