import React, { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import "./styles.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
} from "@mui/material";
import PosTableRow from "./PosTableRow";
import { isProduction } from "../../utils/utilidades";
import { isLoginComercio } from "../../connection/loginController";

export default function PosTable(props) {
  const { listado, selectedSucursal } = props;
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: 10,
    count: 1,
    page: 0,
  });
  const [filas, setFilas] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };

  const handleRowsPerPageChange = (e) => {
    setPaginationControl({
      ...paginationControl,
      page: 0,
      rowsPerPage: e.target.value,
    });
  };

  useEffect(() => {
    if (selectedSucursal <= 0) {
      setFilas(listado);
      return;
    }
    setFilas(
      listado.filter((x) => {
        return x.idSucursal === selectedSucursal;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSucursal]);

  useEffect(() => {
    setFilas(listado);
  }, [listado]);

  const sortedRows = () => {
    return filas.sort((a, b) => {
      if (orderBy === "") return 0;
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      }
      return a[orderBy] > b[orderBy] ? -1 : 1;
    });
  };

  const inicioCorte = () => {
    return paginationControl.page * paginationControl.rowsPerPage;
  };

  const corteFinal = () => {
    return (
      paginationControl.rowsPerPage * paginationControl.page +
      paginationControl.rowsPerPage
    );
  };

  if (!Array.isArray(listado)) {
    console.error("El prop 'listado' no es una matriz:", listado);
    return null;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Tabla" size="small">
        <TableHead>
          <TableRow>
            {isProduction() || isLoginComercio() ? (
              <></>
            ) : (
              <TableCell>
                <Typography>
                  <b>EDITAR</b>
                </Typography>
              </TableCell>
            )}
            <TableCell>
              <TableSortLabel
                active={orderBy === "idTerminal"}
                direction={orderBy === "idTerminal" ? order : "asc"}
                onClick={(event) => handleRequestSort(event, "idTerminal")}
              >
                <Typography>
                  <b>ID TERMINAL</b>
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "idSucursal"}
                direction={orderBy === "idSucursal" ? order : "asc"}
                onClick={(event) => handleRequestSort(event, "idSucursal")}
              >
                <Typography>
                  <b>ID SUCURSAL</b>
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell
              style={{
                whiteSpace: "nowrap",
                width: "25%",
                maxWidth: "fit-content",
              }}
            >
              <TableSortLabel
                active={orderBy === "serialNumber"}
                direction={orderBy === "serialNumber" ? order : "asc"}
                onClick={(event) => handleRequestSort(event, "serialNumber")}
              >
                <Typography>
                  <b>SERIAL NUMBER</b>
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ display: 'none' }}>
              <Typography>
                <b></b>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="t-stripped t-highlight">
          {filas.length === 0 ? (
            <PosTableRow row={{}} variant="empty" />
          ) : (
            sortedRows()
              .slice(inicioCorte(), corteFinal())
              .map((row, idx) => {
                return (
                  <PosTableRow
                    key={idx}
                    row={row}
                    onEdit={props.onEdit}
                    onDelete={props.onDelete}
                  />
                );
              })
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filas.length}
        rowsPerPage={paginationControl.rowsPerPage}
        page={paginationControl.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </TableContainer>
  );
}
