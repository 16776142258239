import React, { useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const styles = {
  "& .MuiInputBase-root": {
    height: "32px",
    padding: "0px",
  },
  "& .MuiInputBase-input": {
    height: "32px",
  },
  "& .MuiInputBase-input::focused": {
    border: "0px !important",
  },
  "& input:focus": {
    border: "0px !important",
  },
  "& button": {
    transform: "translate(-10px, 0)",
  },
};
export default function SelectorFecha(props) {
  const datepicker = useRef(null);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        ref={datepicker}
        format="dd-MM-yyyy"
        value={props.value}
        onChange={(date) => props.onChange(date)}
        sx={styles}
        minDate={props.min || null}
        maxDate={props.max || null}
      />
    </LocalizationProvider>
  );
}
