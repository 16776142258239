import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import AllInboxIcon from "@mui/icons-material/AllInbox";

export default function GscanTableRow({
  item,
  onOpenDialog
}) {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{item.nombreComercio.toUpperCase()}</TableCell>
        <TableCell>{item.rut}</TableCell>
        <TableCell align="left" style={{ paddingLeft: "50px" }}>
          {item.endpoints.length}
        </TableCell>
        <TableCell>
          <Tooltip title="URLS">
            <IconButton onClick={() => onOpenDialog(item)}>
              <AllInboxIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
