import React, { useEffect, useRef, useState } from "react";
import Input from "../Input/Input";
import './style.css';
const estilos = {
  container: {
    display: 'flex'
  } 
}

export default function FiltroTexto(props) {
  const [texto, setTexto] = useState("");
  const generalTimeOut = useRef(null);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    clearTimeout(generalTimeOut.current);
    generalTimeOut.current = setTimeout(() => {
      props.onFilter(texto);
    }, 500);

    return () => clearTimeout(generalTimeOut.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [texto, props.onFilter]);

  useEffect(() => {
    setTexto(props.value || ""); 
  }, [props.value]);

  const handleChangeTexto = (e) => {
    setTexto(e.target.value);
    if (typeof props.onChange === "function") {
      props.onChange(e);
    }
  };
  useEffect(() => {
    setIsSelected(props.selected);
  }, [props.selected]);

  return (
    <div style={estilos.container}>
      <Input
        variant={props.variant}
        type="text"
        placeholder={props.placeholder || "Buscar..."}
        onChange={handleChangeTexto}
        value={texto}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        style={props.style || null}
      />
      {
        isSelected && 
        <button className="filtro-texto-unselectbtn" onClick={props.onUnSelect}>x</button>
      }
    </div>
  );
}
