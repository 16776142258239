import React, { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import './styles.css'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import UserTableRow from "./UserTableRow";
import { compararASC, compararDESC } from "../../utils/utilidades";

export default function UserTable(props) {
  const { listado, filterText } = props;
  const [filteredListado, setFilteredListado] = useState([]);
  const [sorting, setSorting] = useState({
    atributo: "",
    order: "asc",
  });
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: 10,
    count: 1,
    page: 0,
  });
  const inicioCorte = () =>{
    return (paginationControl.page * paginationControl.rowsPerPage)
}
const corteFinal = () => {
    return paginationControl.rowsPerPage * paginationControl.page + paginationControl.rowsPerPage
}
  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };
  const handleRowsPerPageChange = (e) => {
    setPaginationControl({ ...paginationControl, page: 0, rowsPerPage: e.target.value });
  };

  const handleSort = (atributo, callback) => {
    handleChangePage(null, 0);
    if (sorting.atributo === atributo) {
      const order = sorting.order === "asc" ? "desc" : "asc";
      setSorting({ ...sorting, order: order });
      callback(order);
      return;
    }
    setSorting({ atributo: atributo, order: "asc" });
    callback("asc");
  };

  const handleSortNombre = (order) => {
    setPaginationControl({ ...paginationControl, page: 0 });
    if (order === "asc") {
      const list = listado.sort((a, b) => compararASC(a, b, "nombre"));
      setFilteredListado(list);
      return;
    }
    setFilteredListado(
      listado.sort((a, b) => compararDESC(a, b, "nombre"))
    );
  };
  useEffect(() => {
    setPaginationControl({...paginationControl, page: 0});
    const lista = filterText
      ? listado.filter(
        (user) =>
          (user.nombre && user.nombre.toLowerCase().includes(filterText.toLowerCase())) ||
          (user.emailUsuario && user.emailUsuario.toLowerCase().includes(filterText.toLowerCase()))
      )
      : listado;
      setFilteredListado(lista);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, listado]);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Tabla" size="small">
        <TableHead>
          <TableRow >
            <TableCell><b>EDITAR</b></TableCell>
            <TableCell>
            <TableSortLabel
                active={sorting.atributo === "nombre"}
                direction={sorting.order}
                onClick={() => {
                  handleSort("nombre", handleSortNombre);
                }}
              >
                <b>NOMBRE</b>
              </TableSortLabel>
              </TableCell>
            <TableCell><b>EMAIL USUARIO</b></TableCell>
            <TableCell sx={{textAlign: 'center'}}><b>CAMBIO DE CLAVE</b></TableCell>
            <TableCell sx={{textAlign: 'center'}}><b>HABILITADO</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="t-stripped t-highlight">
          {filteredListado.length === 0? (
            <TableRow>
              <TableCell colSpan={5} align="center">
              <Typography>No se encontraron usuarios.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            filteredListado.slice(inicioCorte(),corteFinal()).map((row, idx) => (
                <UserTableRow
                  key={idx}
                  row={row}
                  onEdit={props.onEdit}
                  onChange={props.onChange}
                />
              ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredListado.length}
        rowsPerPage={paginationControl.rowsPerPage}
        page={paginationControl.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </TableContainer>
  );
}
