import React from "react";
import LoginComercio from "./LoginComercio";
import Login from "./Login";
import { isLoginComercio } from "../../connection/loginController";

export default function LoginController() {
  if (isLoginComercio())
    return <LoginComercio />
  else {
    return <Login />

  }
};

