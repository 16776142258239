import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import Button from "../../components/Button";
import Input from "../../components/Input/Input";
import { useNavigate, useParams } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import { crearApps, editarApps, obtenerListadoApps } from "../../connection/mantenedores/aplicacionesMantenedor";
import { getToken } from "../../connection/tokenController";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";

const breadcrumbItems = [
  { href: '/aplicaciones', label: "MANTENEDOR DE APLICACIONES" }
];

function EditarAplicaciones() {
  const { idApps } = useParams();
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [isEdit, setIsEdit] = useState(false);
  const [aplicacion, setAplicacion] = useState({
    idApp: 0,
    nombre: "",
  });

  const handleObtenerAplicacionEdit = async () => {
    try {
      showLoader();
      const listado = await obtenerListadoApps(getToken());
      const app = listado.find(x => x.idApps === parseInt(idApps));
      setAplicacion(app);
      setIsEdit(true);
    } catch (error) {
      showNotification("error", "Ha ocurrido un error inesperado");
      console.error(error)
    }
    finally {
      hideLoader();
    }
  }

  useEffect(() => {
    if (idApps) {
      handleObtenerAplicacionEdit();
    } // eslint-disable-next-line
  }, []);

  const handleVolver = (e) => {
    e.preventDefault();
    navigate("/aplicaciones");
  };

  const handleChange = (field, value) => {
    const regex = /^[A-Z\s]*/;
    const uppercasedValue = value.toUpperCase();
    if (field === "nombre" && !regex.test(uppercasedValue)) {
      return;
    }
    setAplicacion((prev) => ({ ...prev, [field]: uppercasedValue }));
  };

  const handleEditar = async (e) => {
    e.preventDefault();
    showLoader();
    try {
      const objeto = { ...aplicacion };
      const token = getToken();
      const respuesta = await editarApps(idApps, objeto, token);
      if (!respuesta) {
        return;
      }
      showNotification("success", "Aplicación editada con éxito");
      navigate("/aplicaciones");
    } catch (error) {
      console.error("Error en handleEditar:", error);
      showNotification("error", "Algo salió mal");
    } finally {
      hideLoader();
    }
  };

  const handleCrear = async (e) => {
    e.preventDefault();
    showLoader();
    try {
      const token = getToken();
      await crearAplicacion(token);
      showNotification("success", "Aplicación creada con éxito");
      navigate("/aplicaciones");
    } catch (error) {
      console.error("Error en handleCrear:", error);
      showNotification("error", "Algo salió mal");
      if (error.message.includes("401") || error.message.includes("Token")) {
        navigate("/aplicacion");
      }
    } finally {
      hideLoader();
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    showLoader();
    try {
      const token = getToken();
      if (isEdit) {
        await editarAplicacion(token);
      } else {
        await crearAplicacion(token);
      }
      showNotification("success", `Aplicación ${isEdit ? 'editada' : 'creada'} con éxito`);
      navigate("/aplicaciones");
    } catch (error) {
      console.error("Error en handleSave:", error);
      showNotification("error", "Algo salió mal");
      if (error.message.includes("401") || error.message.includes("Token")) {
        navigate("/login");
      }
    } finally {
      hideLoader();
    }
  };

  const crearAplicacion = async (token) => {
    showLoader();
    try {
      await crearApps(aplicacion, token);
    } catch (error) {
      console.error("Error en crearAplicacion:", error);
      throw error;
     } finally {
      hideLoader();
    }
  };

  const editarAplicacion = async (token) => {
    try {
      await editarApps(aplicacion, token);
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return false;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
      return false;
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>{isEdit ? "EDITAR" : "CREAR"} APLICACIÓN</Typography>
      <br />
      <ShadowBox sx={{ padding: "2rem" }}>
        <br />
        <CustomBreadcrumbs items={[...breadcrumbItems, { label: isEdit ? 'EDITAR APLICACIÓN' : 'CREAR APLICACIÓN' }]} />
        <Divider />
        <br />
        <br />
        <form onSubmit={handleSave}>
          <Box sx={{ textAlign: "left" }}>
            <label style={{ color: "black", fontWeight: "bold" }}>{isEdit ? "MODIFICAR" : "NUEVA"} APLICACIÓN</label>
            <br />
            <br />
            <Input
              type="text"
              placeholder=""
              maxLength={20}
              value={aplicacion.nombre}
              onChange={(e) => handleChange("nombre", e.target.value)}
              sx={{ marginTop: "1rem" }}
            />
            <br />
            <Box sx={{ marginTop: "0.5rem", display: "flex", justifyContent: "left", gap: "10px" }}>
              <Button
                variant="gray"
                onClick={handleVolver}
                sx={{ fontSize: "1rem", padding: "0.5rem 1rem" }}
              >
                CANCELAR
              </Button>
              <Button
                onClick={isEdit ? handleEditar : handleCrear}
                sx={{ fontSize: "1rem", padding: "0.5rem 1rem" }}
              >
                {isEdit ? "ACEPTAR" : "CREAR"}
              </Button>
            </Box>
          </Box>
        </form>
      </ShadowBox>
    </>
  );
}

export default EditarAplicaciones;