import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { obtenerTodosLosUsuariosRequest, eliminarUsuarioRequest, editarUsuarioRequest, restablecerUsuarioRequest } from "../../../connection/mantenedores/usuarioMantenedor";
import { getToken } from "../../../connection/tokenController";
import { compararASC } from "../../../utils/utilidades";
import { useLoader } from "../../../context/LoaderContext";
import UsuariosLayout from "../../../components/UsuariosLayout";
import { Typography } from "@mui/material";
import ShadowBox from "../../../components/ShadowBox";
import { OPERACIONES_ROLE_VALUE } from "../../../connection/loginController";

export default function UsuariosOperaciones() {
    const { showLoader, hideLoader, showNotification } = useLoader();
    const navigate = useNavigate();
    const [listado, setListado] = useState([]);

    const handleEnableDisable = async (modifyUser, onHabilitarUsuario) => {
        try {
            if (modifyUser.habilitado) {
                await eliminarUsuarioRequest(modifyUser.idUsuario, getToken());
                return await updateListadoAndGetMessage("Usuario dehabilitado con éxito");
            } else {
                await handleHabilitarYResetearUsuario(modifyUser, onHabilitarUsuario);
                return await updateListadoAndGetMessage("Usuario habilitado con éxito");
            }
        } catch (error) {
            console.error("Error en handleEnableDisable:", error);
            showNotification("error", "Error al habilitar/deshabilitar usuario");
        }
    };

    const handleHabilitarYResetearUsuario = async (modifyUser, onHabilitarUsuario) => {
        await editarUsuarioRequest(
            { ...modifyUser, habilitado: !modifyUser.habilitado },
            getToken(),
            modifyUser.idUsuario
        );
        const respuesta = await restablecerUsuarioRequest(modifyUser.idUsuario, getToken());
        onHabilitarUsuario(modifyUser, respuesta);
    };

    const updateListadoAndGetMessage = async (message) => {
        await handleSetListado();
        return message;
    };

    const handleSetListado = async () => {
        showLoader();
        try {
            const token = getToken();
            let usuarios = await obtenerTodosLosUsuariosRequest(token);
            const usuarioAdmin = usuarios.find(x => x.emailUsuario === "admin@ione.cl");
            usuarios = usuarios.filter(x => x.rol === OPERACIONES_ROLE_VALUE);
            let listado = usuarios.filter(x => x.emailUsuario !== "admin@ione.cl")
                                   .sort((a, b) => compararASC(a, b, "nombre"));

            if (usuarioAdmin) {
                listado.unshift(usuarioAdmin);
            }

            setListado(listado);
        } catch (error) {
            console.error("Error en handleSetListado:", error);
            showNotification(error.status === 500 ? "error" : "error", error.status === 500 ? "Error de conexión" : "Ha ocurrido un error inesperado");
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        handleSetListado();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEdit = (row) => {
        navigate(`/usuarios/editar-usuario`, { state: { usuario: row } });
    }

    return (
        <>
            <Typography variant="h5" sx={{ margin: "1rem 0" }}>
                MANTENEDOR DE USUARIOS GETNET
            </Typography>
            <br />
            <ShadowBox style={{ padding: "2rem" }}>
                <UsuariosLayout
                    listado={listado}
                    onEnableDisable={handleEnableDisable}
                    onEdit={handleEdit}
                    addUserRoute="/usuarios/nuevo-usuario" 
                    labelAddButton="AGREGAR"
                />
            </ShadowBox>
        </>
    );
};
