import './app.css';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import theme from './utils/theme.js';
import { LoaderProvider } from './context/LoaderContext.js';
import Loader from './components/Loader.jsx';
import rutas from './routes.js';
import { getIdComercio, getRolUsuario, isLoginComercio } from './connection/loginController.js';
import Notificaciones from './components/Notificaciones.jsx';
import { getTokenExpiration } from './connection/tokenController.js';
import { obtenerAppsHabilitadas } from './connection/AppsController.js';

function generarRutasChildrens(ruta, idx) {
  return (
    <Route key={idx} path={ruta.path} element={ruta.element}>
      {ruta.children.map((child, cidx) => {
        if (child.children) {
          return generarRutasChildrens(child, cidx);
        }
        if (child.index) {
          return <Route key={cidx} index element={child.element} />;
        }
        if (child.restrictionRole) {
          return (
            <Route
              key={cidx}
              path={child.ruta}
              element={
                <ProtectedRoute allowedRoles={child.restrictionRole}>
                  {child.element}
                </ProtectedRoute>
              }
            />
          );
        }
        return <Route key={cidx} path={child.ruta} element={child.element} />;
      })}
    </Route>
  );
}

const ProtectedRoute = ({ children, allowedRoles }) => {
  const navigate = useNavigate();

  const tokenExiprationControl = () => {
    const tokenExpiration = getTokenExpiration();
    if (tokenExpiration) {
      const timeUntilExpiration = tokenExpiration - new Date();
      const timer = setTimeout(() => {
      }, timeUntilExpiration);
      return () => clearTimeout(timer);
    }
  }

  useEffect(() => {
    tokenExiprationControl();
  }, [navigate]);

  const userRole = getRolUsuario();
  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/404" replace />;
  }

  return children;
};

function App() {
  const [stateRutas, setStateRutas] = useState(rutas);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    if (!isLoginComercio()) return;
    if (!getIdComercio()) return;
    try {
      const nombresApps = await obtenerAppsHabilitadas();

      const newRuta = stateRutas[3]?.children.flatMap(({ app, children, ...rest }) => {
        if (!app) {
          const filteredChildren = children?.filter(child => !child.app || nombresApps.includes(child.app)) || [];
          return filteredChildren.length > 0 ? [{ ...rest, children: filteredChildren }] : !children ? [rest] : [];
        }
        return nombresApps.includes(app) ? [{ app, ...rest, children }] : [];
      });

      if (newRuta) {
        setStateRutas(prevState => {
          const newStateRutas = [...prevState];
          newStateRutas[3] = { ...newStateRutas[3], children: newRuta };
          return newStateRutas;
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LoaderProvider>
        <Loader />
        <Notificaciones />
        <Router>
          <Routes>
            {stateRutas.map((ruta, idx) => {
              if (!ruta.children) {
                return <Route key={idx} path={ruta.ruta} element={ruta.element} />;
              }
              return generarRutasChildrens(ruta, idx);
            })}
          </Routes>
        </Router>
      </LoaderProvider>
    </ThemeProvider>
  );
}

export default App;
