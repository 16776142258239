import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import ShadowBox from '../../components/ShadowBox'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { validarEmail } from '../../utils/utilidades'
import { useLoader } from '../../context/LoaderContext'
import { olvidarClaveRequest } from '../../connection/mantenedores/usuarioMantenedor'
import { useNavigate } from 'react-router-dom'

const styles = {
    box: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(0deg, #D4E8FF, white)",
    },
    shadowBox: {
        borderRadius: "10px",
        padding: '2rem 1.5rem',
        textAlign: 'center',
        maxWidth: "20rem"
    }
}
export default function ForgotPassword() {
    const { showLoader, hideLoader, showNotification } = useLoader();
    const navigate = useNavigate()
    const [userEmail, setUserEmail] = useState("");
    const [emailError, setEmailError] = useState(false)
    const handleChangeEmail = (e) => {
        setEmailError(false);
        setUserEmail(e.target.value);
    }
    const handleConfirm = (e) => {
        e.preventDefault();
        if (!validarEmail(userEmail)) {
            setEmailError(true);
            showNotification("error", "Ingrese un correo electrónico válido");
            return;
        }
        forgotEmail();
    }
    const forgotEmail = async () => {
        try {
            showLoader();
            const respuesta = await olvidarClaveRequest(userEmail);
            if (respuesta.status === 200) {
                showNotification("success", "Tu contraseña temporal ha sido enviada a tu correo electrónico")
                navigate('/password-restablecida');
            }
        } catch (error) {
            if (error.status === 400) {
                const respuesta = await error.json();
                showNotification("error", respuesta.detail);
                return;
            }
            if (error.status === 500) {
                showNotification("error", "No hay conexión");
                return;
            }
            showNotification("error", "Ha ocurrido un error inesperado");
        } finally {
            hideLoader();
        }

    }
    const handleCancelar = (e) => {
        e.preventDefault();
        navigate('/login');
    }

    return (
        <Box sx={styles.box}>
            <ShadowBox style={styles.shadowBox}>
                <Typography variant="h6" gutterBottom>
                    ¿HAS OLVIDADO TU CONTRASEÑA?
                </Typography>
                <br />
                <Typography sx={{ textAlign: 'justify' }}>
                    Se establecerá una contraseña temporal que será enviada a tu dirección de correo electrónico la cual debes cambiar en tu proximo inicio de sesión
                </Typography>
                <br />
                <br />
                <Input
                    className="gray"
                    required
                    onChange={handleChangeEmail}
                    value={userEmail}
                    placeholder="Correo electrónico"
                    completeWidth
                    error={emailError}
                />
                <br />
                <br />
                <br />
                <Button onClick={handleConfirm} largeButton>RESTABLECER</Button>
                <br />
                <br />
                <Button onClick={handleCancelar} largeButton variant="gray">CANCELAR</Button>
            </ShadowBox>
        </Box>
    )
}
