import { getApiUrl, RequestContentType, getRequest, postRequest, putRequest } from "../apiConnection";

const API_URL = getApiUrl();

export const obtenerDatosRequest = async (token) => {
    return await getRequest(API_URL, "/api/getnetscan", token);
}
export const crearGetNetScanRequest = async (objeto, token) => {
    return await postRequest(API_URL, "/api/getnetscan", RequestContentType.APPLICATION_JSON, objeto, token)
}
export const editarGetnetScanRequest = async (id, objeto, token) => {
    return await putRequest(API_URL,`/api/getnetscan/${id}`, RequestContentType.APPLICATION_JSON, objeto, token);
}