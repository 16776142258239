import React, { useEffect, useState } from "react";
import FormularioUsuario from "../../../components/Formularios/FormularioUsuario";
import DialogoUsuarioPasswordTemporal from "../../../components/DialogoUsuarioPasswordTemporal";
import { crearUsuarioRequest } from "../../../connection/mantenedores/usuarioMantenedor";
import { getToken } from "../../../connection/tokenController";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../context/LoaderContext";
import { crearUsuarioComercioRequest } from "../../../connection/mantenedores/usuarioComercioMantenedor";
import { USUARIO_COMERCIO_INPAGE, SELECTED_COMMERCE } from "./UsuariosComercios";
import { COMERCIO_ROLE_VALUE } from "../../../connection/loginController";

const breadcrumbItems = [
  {
    href: "/usuarios-comercios",
    label: "Usuarios comercios",
  },
];


export default function CrearUsuarioComercio() {
  let navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [errorMessage, setErrorMessage] = useState("");
  const [dialogo, setDialogo] = useState({
    open: false,
    text: "",
  });

  const [selectedCommerce, setSelectedCommerce] = useState({
    nombre: "NOMBRE COMERCIO",
  });

  useEffect(() => {
    const commerce = localStorage.getItem(SELECTED_COMMERCE);
    if (commerce) {
      setSelectedCommerce(JSON.parse(commerce));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(USUARIO_COMERCIO_INPAGE, "true");
  }, []);
  

  const handleOpenDialogo = (email, text) => {
    const passwordBlob = new Blob([text], { type: "text/plain" });
    const item = new ClipboardItem({
      "text/plain": passwordBlob,
    });
    navigator.clipboard
      .write([item])
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
    setDialogo({ open: true, email: email, text: text });
  };
  const handleCloseDialogo = () => {
    setDialogo({ ...dialogo, open: false });
  };

  const handleVolver = () => {
    localStorage.setItem(USUARIO_COMERCIO_INPAGE, "true");
    navigate("/usuarios-comercios");
  };

  const handleConfirm = async (objeto) => {
    const datos = {
      nombre: objeto.nombre,
      emailUsuario: objeto.emailUsuario,
      habilitado: true,
      clave: objeto.clave,
      rol: COMERCIO_ROLE_VALUE,
    };
    try {
      showLoader();
      const response = await crearUsuarioRequest(datos, getToken());
      const idComercio = obtenerIdComercioFromLocalStorage();
      if (response.idUsuario)
        await guardarUsuarioComercio(response.idUsuario, idComercio);
      setErrorMessage("");
      handleOpenDialogo(datos.emailUsuario, response.clave);
      showNotification("success", "¡Usuario creado con éxito!");
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      if (error.status === 409) {
        showNotification("error", "Ya existe un usuario con este correo");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };
  const obtenerIdComercioFromLocalStorage = () => {
    try {
      const lsSelectedCommerce = localStorage.getItem(SELECTED_COMMERCE);
      if (lsSelectedCommerce.length === 0) {
        return;
      }
      return JSON.parse(lsSelectedCommerce).codigo;
    } catch (error) {
      console.error(error);
    }
  };
  const guardarUsuarioComercio = async (idUsuario, idComercio) => {
    try {
      await crearUsuarioComercioRequest(idUsuario, idComercio, getToken());
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <FormularioUsuario
        titulo="CREAR USUARIO COMERCIO"
        usuario={null}
        onCancel={handleVolver}
        onConfirm={handleConfirm}
        buttonText="ACEPTAR"
        errorMessage={errorMessage}
        breadcrumbItems={breadcrumbItems}
        comercioSeleccionado={selectedCommerce.nombre}
      />
      <DialogoUsuarioPasswordTemporal
        title="USUARIO CREADO CON ÉXITO"
        open={dialogo.open}
        onClose={handleCloseDialogo}
        email={dialogo.email}
        password={dialogo.text}
        onConfirm={handleVolver}
      />
    </>
  );
}
