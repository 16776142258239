import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useLoader } from "../context/LoaderContext";

export default function Notificaciones() {
    const { notificaciones, hideNotificacion } = useLoader();
    return (
        <>
            {notificaciones.length === 0 ? (
                <></>
            ) : (
                notificaciones.map((notificacion, key) => {
                    return (
                        <Snackbar
                            key={key}
                            open={notificacion.open}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            sx={{ transform: `translate(0, -${key * 3.5}rem)` }}
                            autoHideDuration={5000}
                            onClose={(e, reason) => { hideNotificacion(notificacion.id, e, reason); }}
                        >
                            <Alert
                                onClose={() =>
                                    hideNotificacion(notificacion.id)
                                }
                                severity={notificacion.type}
                                variant="filled"
                                sx={{ width: "100%" }}
                            >
                                {notificacion.message}
                            </Alert>
                        </Snackbar>
                    );
                })
            )}
        </>
    );
}
