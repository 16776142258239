import { Box } from "@mui/material";
const styleBox = {
  backgroundColor: "white",
  boxShadow: "0 0 8px #00000044",
  padding: "1rem",
  borderRadius: "8px"
};

export default function ShadowBox(props) {
  const estilos = { ...styleBox, ...props.style };
  return <Box sx={estilos}>{props.children}</Box>;
}
