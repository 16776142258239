const validarLongitud = (pass) => {
    return pass.length >= 12;
}
const validarSinEspacio = (pass) => {
    if (pass.length === 0) return false;
    return pass.split(' ').length === 1;
}
const validarMayuscula =(pass) => {
    return /^(?=.*?[A-Z]).*$/.test(pass)
}
const validarMinuscula =(pass) => {
    return /^(?=.*?[a-z]).*$/.test(pass)
}
const validarNumero =(pass) => {
    return /^(?=.*?[0-9]).*$/.test(pass)
}
const validarSimbolo =(pass) => {
    return pass.split('_').length > 1 || /[^\w\s]/.test(pass)
}

export const passwordValidation = {
    validarLongitud, 
    validarSinEspacio, 
    validarMayuscula, 
    validarMinuscula, 
    validarNumero, 
    validarSimbolo
}