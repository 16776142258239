import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import React, { useState } from "react";
import GscanDialogTableRow from "./GscanDialogTableRow";
import GscanDialogSectionHeader from "./GscanDialogSectionHeader";
import { useLoader } from "../../context/LoaderContext";
import { validarURL } from "../../utils/utilidades";

export default function GscanDialogAuth({
  endpoint,
  onToggleHabilitarEndpoint,
  onSave,
}) {
  const [editMode, setEditMode] = useState(false);
  const { showNotification } = useLoader();
  const [urlError, setUrlError] = useState("");

  const [valores, setValores] = useState({
    url: endpoint?.urlEndpoint || "",
    clientId: endpoint?.clientId || "",
    clientSecret: endpoint?.clientSecret || "",
  });

  const handleToggleEdit = () => {
    setEditMode(!editMode);
  };

  const handleConfirmEdit = () => {
    if (!validarURL(valores.url)) {
      setUrlError("La URL no es válida.");
      showNotification("error", "URL Auth no es válida.");
      return;
    }

    const objeto = {
      ...endpoint,
      urlEndpoint: valores.url,
      clientId: valores.clientId,
      clientSecret: valores.clientSecret,
      habilitado: allFieldsFilled(),
    };

    onSave(objeto);
    handleToggleEdit();
  };

  const handleCancelEdit = () => {
    setValores({
      url: endpoint?.urlEndpoint,
      clientId: endpoint?.clientId,
      clientSecret: endpoint?.clientSecret,
    });
    handleToggleEdit();
  };

  const handleChangeUrl = (url) => {
    setValores({ ...valores, url: url });
    if (!validarURL(url)) {
      setUrlError("La URL no es válida.");
    } else {
      setUrlError("");
    }
  };

  const handleChangeClientId = (clientId) => {
    setValores({ ...valores, clientId: clientId });
  };

  const handleChangeClientSecret = (clientSecret) => {
    setValores({ ...valores, clientSecret: clientSecret });
  };

  const allFieldsFilled = () => {
    return !!(
      valores.url.trim() &&
      valores.clientId.trim() &&
      valores.clientSecret.trim()
    );
  };

  const handleSwitchChange = () => {
    try {
      if (allFieldsFilled() || endpoint.habilitado) {
        onToggleHabilitarEndpoint(endpoint);
      } else {
        console.warn(
          "Algunos campos están vacíos. No se puede habilitar el switch."
        );
      }
    } catch (error) {
      console.error("Error al cambiar el estado del switch:", error);
    }
  };

  return (
    <>
      <GscanDialogSectionHeader
        title="Authenticatión"
        editMode={editMode}
        switchState={endpoint.habilitado}
        onSwitchChange={handleSwitchChange}
        disabled={Boolean()}
        onEditClick={handleToggleEdit}
        onEditConfirm={handleConfirmEdit}
        onEditCancel={handleCancelEdit}
        disableSwitch={!(allFieldsFilled() || endpoint.habilitado)}
      />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="authentication table">
          <GscanDialogAuthBody
            urlAuth={valores.url}
            clientId={valores.clientId}
            clientSecret={valores.clientSecret}
            onChangeUrl={handleChangeUrl}
            onChangeClientId={handleChangeClientId}
            onChangeClientSecret={handleChangeClientSecret}
            editMode={editMode}
            urlError={urlError}
          />
        </Table>
      </TableContainer>
    </>
  );
}

const GscanDialogAuthBody = ({
  editMode,
  urlAuth,
  clientId,
  clientSecret,
  onChangeUrl,
  onChangeClientId,
  onChangeClientSecret,
  urlError,
}) => {
  return (
    <TableBody>
      <GscanDialogTableRow
        editMode={editMode}
        label="URL Auth"
        value={urlAuth}
        onChange={onChangeUrl}
        error={urlError}
      />
      <GscanDialogTableRow
        editMode={editMode}
        label="Client ID"
        value={clientId}
        onChange={onChangeClientId}
      />
      <GscanDialogTableRow
        editMode={editMode}
        label="Client Secret"
        value={clientSecret}
        onChange={onChangeClientSecret}
      />
    </TableBody>
  );
};
