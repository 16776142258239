import { Chip } from '@mui/material'
import React from 'react'

export default function ChipList(props) {
    const handleDelete = (x) => {
        if (props.onDelete)
            props.onDelete(x);
    }
    const obtenerLabel = (x) => {
        if (props.label) {
            return props.label(x);
        }
        return x;
    }
    const obtenerListado = () => {
        return props.listado.map(x => {
            return { ...x, label: obtenerLabel(x) }
        })
    }
    return (
        <>
            {
                obtenerListado().length === 0 ? <></> :
                    obtenerListado().map((x, key) => {
                        return <Chip key={key} label={x.label} variant="outlined" sx={{ marginRight: '0.5rem' }} onDelete={() => { handleDelete(x) }} />
                    })
            }
        </>
    )
}
