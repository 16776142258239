import React from "react";
import { IconButton, Switch, TableCell, TableRow, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function UserTableRow(props) {
  const { row, variant, onEdit, onChange } = props;
  // Si variant es true o el usuario no está habilitado, no se dibuja el usuario en la tabla.
  if (variant) {
    return (
      <TableRow>
        <TableCell colSpan={5} sx={{ textAlign: 'center' }}>No hay datos o usuario no habilitado</TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow>
      <TableCell>
        <IconButton aria-label="Editar" onClick={() => onEdit(row)} disabled={row.emailUsuario === "admin@ione.cl"}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell><Typography>{row.nombre.toUpperCase()}</Typography></TableCell>
      <TableCell><Typography>{row.emailUsuario.toUpperCase()}</Typography></TableCell>
      <TableCell sx={{textAlign: 'center'}}><Typography>{row.cambioDeClave ? 'SÍ' : 'NO'}</Typography></TableCell>
      <TableCell sx={{textAlign: 'center'}}><Switch checked={row.habilitado} onChange={() => {onChange(row)}} disabled={row.emailUsuario === "admin@ione.cl"} /></TableCell>
    </TableRow>
  );
}
