import React, { useEffect, useState } from "react";
import RoundButton from "../RoundButton";
import Dialogo from "../Dialogo";
import FiltroTexto from "../FiltroTexto";
import { Link } from "react-router-dom";
import UserTable from "../UserTable";
import { useLoader } from "../../context/LoaderContext";
import CustomSelect from "../CustomSelect";
import DialogoUsuarioPasswordTemporal from "../DialogoUsuarioPasswordTemporal";

const estilos = {
  divRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    justifyContent: "center",
    flexWrap: "wrap", 
  },
};

const UsuariosLayout = ({
  listado,
  onEnableDisable,
  onEdit,
  addUserRoute,
  labelAddButton,
  labelAddUserButton,
  handleValidarSeleccionarButton,
  isButtonDisabled,
  onAddButtonClick,
}) => {
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [showListado, setShowListado] = useState([]);
  const [textoFiltrar, setTextoFiltrar] = useState("");
  const [openDisableDialog, setOpenDisableDialog] = useState(false);

  const [modifyUser, setModifyUser] = useState({});
  const [dialogo, setDialogo] = useState({
    open: false,
    email: "",
    password: "",
  });

  const handleCloseDialogo = () => {
    setDialogo({ ...dialogo, open: false });
  };
  const handleOpenDialogo = (email, password) => {
    const passwordBlob = new Blob([password], { type: "text/plain" });
    const item = new ClipboardItem({
      "text/plain": passwordBlob,
    });
    navigator.clipboard
      .write([item])
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
    showNotification("success", "Nueva contraseña copiada al portapapeles");
    setDialogo({ open: true, email: email, password: password });
  };

  const handleOpenDisableDialog = () => {
    setOpenDisableDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDisableDialog(false);
  };

  const handleChangeInputFilter = (texto) => {
    setTextoFiltrar(texto);
  };

  const handleDisable = (row) => {
    setModifyUser(row);
    handleOpenDisableDialog();
    return;
  };

  const handleConfirmEnableDisable = async () => {
    showLoader();
    try {
      const respuesta = await onEnableDisable(
        modifyUser,
        habilitarYResetearUsuario
      );
      showNotification("success", respuesta);
    } catch (error) {
      showNotification("success", error.message);
    } finally {
      hideLoader();
    }
  };
  const habilitarYResetearUsuario = (modifyUser, respuesta) => {
    handleOpenDialogo(modifyUser.emailUsuario, respuesta.clave);
    hideLoader();
  };

  const handleEdit = (row) => {
    onEdit(row);
  };

  const handleFilterHabilitado = (e) => {
    if (e.target.value.toLowerCase() === "deshabilitados") {
      setShowListado(listado.filter((x) => !x.habilitado));
      return;
    }
    if (e.target.value.toLowerCase() === "habilitados") {
      setShowListado(listado.filter((x) => x.habilitado));
      return;
    }
    setShowListado(listado);
  };

  useEffect(() => {
    setShowListado(listado);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listado]);
  return (
    <>
      <div style={estilos.divRow}>
        <FiltroTexto
          type="text"
          onFilter={handleChangeInputFilter}
          style={{ minWidth: "182px" }}
        />
        <div style={estilos.buttonGroup}>
          <Link
            to={addUserRoute}
            style={{ color: "black", textDecoration: "none" }}
            onClick={onAddButtonClick}
          >
            <RoundButton disabled={isButtonDisabled} variant="outlined">
              {labelAddButton}
            </RoundButton>
          </Link>
          {labelAddUserButton && (
            <Link
              to="/usuarios-comercios/nuevo-usuario-comercio"
              onClick={handleValidarSeleccionarButton}
            >
              <RoundButton disabled={isButtonDisabled} variant="outlined">
                {labelAddUserButton}
              </RoundButton>
            </Link>
          )}
        </div>
      </div>
      <br />
      <CustomSelect
        listado={["Mostrar todos", "Habilitados", "Deshabilitados"]}
        onChange={handleFilterHabilitado}
      />
      <br />
      <br />
      <UserTable
        listado={showListado}
        onChange={handleDisable}
        filterText={textoFiltrar}
        onEdit={handleEdit}
      />
      <Dialogo
        title={`¿Seguro deseas ${
          modifyUser.habilitado ? "deshabilitar" : "habilitar"
        } este Usuario?`}
        text={`¿Estás seguro que deseas ${
          modifyUser.habilitado ? "deshabilitar" : "habilitar"
        } al usuario ${modifyUser.emailUsuario}?`}
        open={openDisableDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmEnableDisable}
      />
      <DialogoUsuarioPasswordTemporal
        title="USUARIO HABILITADO CON ÉXITO"
        open={dialogo.open}
        onClose={handleCloseDialogo}
        email={dialogo.email}
        password={dialogo.password}
        onConfirm={handleCloseDialogo}
      />
    </>
  );
};

export default UsuariosLayout;
