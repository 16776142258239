import React, { useEffect, useRef, useState } from "react";
import ShadowBox from "../../components/ShadowBox";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input";
import "./editarDonacion.css";
import empty from "../../resources/images/empty.png";
import Button from "../../components/Button";
import {
  editarDonacionRequest,
  leerDonacionPorIdComercio,
  uploadFileRequest,
} from "../../connection/mantenedores/donacionesMantenedor";
import { getToken } from "../../connection/tokenController";
import { useLoader } from "../../context/LoaderContext";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";

const breadcrumbItems = [
  { href: '/donaciones', label: "Donaciones" },
  { label: "Editar donación" },
];

export default function EditarDonacion() {
  let navigate = useNavigate();
  let { idComercio } = useParams();
  const inputFileRef = useRef(null);
  const { showLoader, hideLoader, showNotification } = useLoader();

  const [donacion, setDonacion] = useState({
    idComercio: 0,
    urlLogo: "",
    textoTitulo: "",
    textoTotal: "",
    textoBoton: "",
    minimo: 1000,
    maximo: 20000,
    multiplo: 1000,
    monto1: 1000,
    monto2: 5000,
    monto3: 10000,
    monto4: 20000,
    hash: "",
    habilitado: true,
  });
  const handleChangeImage = async (e) => {
    if (e.target.files.length === 0) return;
    if(e.target.files[0].type !== "image/png") {
      showNotification("error", "La imágen debe estar en formato .png");
      return;
    }
    showLoader();
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("idComercio", donacion.idComercio);
    const response = await uploadFileRequest(formData, getToken());
    setDonacion({ ...donacion, urlLogo: response.urlLogo });
    hideLoader();
  };
  const handleChangeTitulo = (e) => {
    setDonacion({ ...donacion, textoTitulo: e.target.value });
  };
  const handleChangeTotal = (e) => {
    setDonacion({ ...donacion, textoTotal: e.target.value });
  };
  const handleChangeBoton = (e) => {
    setDonacion({ ...donacion, textoBoton: e.target.value });
  };
  const handleChangeMinimo = (e) => {
    setDonacion({ ...donacion, minimo: e.target.value });
  };
  const handleChangeMaximo = (e) => {
    setDonacion({ ...donacion, maximo: e.target.value });
  };
  const handleChangeMultiplo = (e) => {
    setDonacion({ ...donacion, multiplo: e.target.value });
  };
  const handleImageClick = () => {
    inputFileRef.current.click();
  };
  const traerDonacion = async () => {
    try {
      const donacion = await leerDonacionPorIdComercio(idComercio, getToken());
      setDonacion(donacion);
    } catch (err) {
      showNotification("error", "Ha ocurrido un error");
    }
  };

  useEffect(() => {
    traerDonacion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCancelarClick = (e) => {
    e.preventDefault();
    navigate("/donaciones");
  };
  const handleSubmitClick = async (e) => {
    try {
      e.preventDefault();
      showLoader();
      if (!validarImagen()) return;
      if (!validarTextos()) return;
      if (!validarMontos()) return;
      if (!validarBotones()) return;
      const data = {
        urlLogo: donacion.urlLogo,
        textoTitulo: donacion.textoTitulo,
        textoTotal: donacion.textoTotal,
        textoBoton: donacion.textoBoton,
        minimo: parseInt(donacion.minimo),
        maximo: parseInt(donacion.maximo),
        multiplo: parseInt(donacion.multiplo),
        monto1: parseInt(donacion.monto1),
        monto2: parseInt(donacion.monto2),
        monto3: parseInt(donacion.monto3),
        monto4: parseInt(donacion.monto4),
      };
      const respuesta = await editarDonacionRequest(
        data,
        idComercio,
        getToken()
      );
      if (respuesta) {
        showNotification("success", "Datos modificados exitosamente")
        navigate("/donaciones");
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoader();
    }
  };
  function validarImagen() {
    if (donacion.urlLogo) return true;
    showNotification("Error, Agregue una imagen");
    return false;
  }
  function validarTextos() {
    if (!donacion.textoTitulo) {
      showNotification("error", "Agregue titulo");
      return false;
    }
    if (!donacion.textoTotal) {
      showNotification("error", "Agregue texto total");
      return false;
    }
    if (!donacion.textoBoton) {
      showNotification("error", "Agregue texto boton");
      return false;
    }
    return true;
  }
  function validarMontos() {
    const minimo = parseInt(donacion.minimo);
    const maximo = parseInt(donacion.maximo);
    const multiplo = parseInt(donacion.multiplo);
    if (minimo >= maximo) {
      showNotification("error", "Error, el minimo debe ser menor al máximo");
      return false;
    }
    if (multiplo <= 0) {
      showNotification("error", "Error, el multiplo debe ser mayor a 0");
      return false;
    }
    if (multiplo > minimo) {
      showNotification("error", "el multiplo debe ser menor o igual al mínimo");
      return false;
    }
    return true;
  }
  function validarBotones() {
    try {
      const monto1 = parseInt(donacion.monto1);
      const monto2 = parseInt(donacion.monto2);
      const monto3 = parseInt(donacion.monto3);
      const monto4 = parseInt(donacion.monto4);
      const minimo = parseInt(donacion.minimo);
      const maximo = parseInt(donacion.maximo);
      const multiplo = parseInt(donacion.multiplo);
      if (monto1 < minimo) {
        showNotification("error", `El monto mínimo de los botones es $${minimo.toLocaleString("es-CL")}`);
        return false;
      }
      if (monto1 >= monto2) {
        showNotification("error", "El monto 1 debe ser menor al monto 2");
        return false;
      }
      if (monto1 % multiplo !== 0) {
        showNotification("error", `El monto 1 no es multiplo de ${multiplo.toLocaleString("es-CL")}`);
        return false;
      }
      if (monto2 >= monto3) {
        showNotification("error", "El monto 2 debe ser menor al monto 3");
        return false;
      }
      if (monto2 % multiplo !== 0) {
        showNotification("error", `El monto 2 no es multiplo de ${multiplo.toLocaleString("es-CL")}`);
        return false;
      }
      if (monto3 >= monto4) {
        showNotification("error", "El monto 3 debe ser menor al monto 4");
        return false;
      }
      if (monto3 % multiplo !== 0) {
        showNotification("error", `El monto 3 no es multiplo de ${multiplo.toLocaleString("es-CL")}`);
        return false;
      }

      if (monto4 % multiplo !== 0) {
        showNotification("error", `El monto 4 no es multiplo de ${multiplo.toLocaleString("es-CL")}`);
        return false;
      }
      if (monto4 > maximo) {
        showNotification("error", `El monto máximo de los botones es $${maximo.toLocaleString("es-CL")}`);
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }
  return (
    <>
      <Typography variant="h5" sx={{ margin: '1rem 0' }}>EDITAR DONACIÓN</Typography>
      <br />
      <ShadowBox style={{ padding: '2rem' }}>
        <CustomBreadcrumbs items={breadcrumbItems} />
        <form>
          <Typography variant="h6">Subir logo</Typography>
          <br />
          <div>
            <div
              className="donacion_contenedor_imagen"
              onClick={handleImageClick}
            >
              <img
                className="donacion_image_file"
                alt="logo de app"
                src={donacion.urlLogo || empty}
              />
            </div>
            <br />
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={handleChangeImage}
              accept="image/png"
            />
          </div>
          <br />

          <Typography variant="h6">Configurar textos</Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <label style={{ color: "black" }}>Texto título</label>
              <Input
                type="text"
                placeholder="Texto título"
                value={donacion.textoTitulo}
                onChange={handleChangeTitulo}
              />
            </Grid>
            <Grid item>
              <label style={{ color: "black" }}>Texto total</label>
              <Input
                type="text"
                placeholder="Texto total"
                value={donacion.textoTotal}
                onChange={handleChangeTotal}
              />
            </Grid>
            <Grid item>
              <label style={{ color: "black" }}>Texto botón</label>
              <Input
                type="text"
                placeholder="Texto botón"
                value={donacion.textoBoton}
                onChange={handleChangeBoton}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <Typography variant="h6">Configurar montos</Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <label style={{ color: "black" }}>Mínimo</label>
              <Input
                type="number"
                placeholder="Mínimo"
                value={donacion.minimo}
                onChange={handleChangeMinimo}
                min={0}
              />
            </Grid>
            <Grid item>
              <label style={{ color: "black" }}>Máximo</label>
              <Input
                type="number"
                placeholder="Mínimo"
                value={donacion.maximo}
                onChange={handleChangeMaximo}
                min={0}
              />
            </Grid>
            <Grid item>
              <label style={{ color: "black" }}>Multiplo</label>
              <Input
                type="number"
                placeholder="Multiplo"
                value={donacion.multiplo}
                onChange={handleChangeMultiplo}
                min={0}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <Typography variant="h6">Configurar botones</Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <label style={{ color: "black" }}>Monto 1</label>
              <Input
                type="number"
                placeholder="Monto 1"
                value={donacion.monto1 || 0}
                onChange={(e) => {
                  setDonacion({ ...donacion, monto1: e.target.value });
                }}
                min={0}
                max={10000000}
              />
            </Grid>
            <Grid item>
              <label style={{ color: "black" }}>Monto 2</label>
              <Input
                type="number"
                placeholder="Monto 2"
                value={donacion.monto2 || 0}
                onChange={(e) => {
                  setDonacion({ ...donacion, monto2: e.target.value });
                }}
                min={0}
                max={10000000}
              />
            </Grid>
            <Grid item>
              <label style={{ color: "black" }}>Monto 3</label>
              <Input
                type="number"
                placeholder="Monto 3"
                value={donacion.monto3 || 0}
                onChange={(e) => {
                  setDonacion({ ...donacion, monto3: e.target.value });
                }}
                min={0}
                max={10000000}
              />
            </Grid>
            <Grid item>
              <label style={{ color: "black" }}>Monto 4</label>
              <Input
                type="number"
                placeholder="Monto 4"
                value={donacion.monto4 || 0}
                onChange={(e) => {
                  setDonacion({ ...donacion, monto4: e.target.value });
                }}
                min={0}
                max={10000000}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button onClick={handleCancelarClick} variant="gray">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleSubmitClick}>Aceptar</Button>
            </Grid>
          </Grid>
        </form>
      </ShadowBox>
    </>
  );
}
