import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ADMIN_ROLE_VALUE, COMERCIO_ROLE_VALUE, DESARROLLO_ROLE_VALUE, OPERACIONES_ROLE_VALUE } from "../connection/loginController";
import { getRolUsuario } from "../connection/loginController";
export default function Home() {
  const [to, setTo] = useState('');

  useEffect(() => {
    const role = getRolUsuario();
    if (role === DESARROLLO_ROLE_VALUE || role === COMERCIO_ROLE_VALUE || role === OPERACIONES_ROLE_VALUE) {
      setTo('/comercios');
      return;
    }
    if (role === ADMIN_ROLE_VALUE) {
      setTo('/usuarios');
      return;
    }
  }, []);
  if (to) {
    return (
      <Navigate to={to} replace />
    );
  }
  return <div style={{ color: "black" }}>
    <Typography variant="h4">Bienvenido al sistema de control de Getnet Adcom</Typography>
  </div>

}
