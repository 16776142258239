import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { obtenerTodosLosUsuariosRequest, eliminarUsuarioRequest, editarUsuarioRequest, restablecerUsuarioRequest } from "../../../connection/mantenedores/usuarioMantenedor";
import { getToken } from "../../../connection/tokenController";
import { useLoader } from "../../../context/LoaderContext";
import UsuariosLayout from "../../../components/UsuariosLayout";
import { Typography } from "@mui/material";
import ShadowBox from "../../../components/ShadowBox";
import { DESARROLLO_ROLE_VALUE } from "../../../connection/loginController";

export default function UsuariosDesarrollo() {
    const { showLoader, hideLoader, showNotification } = useLoader();
    let navigate = useNavigate();
    const [listado, setListado] = useState([]);

    const handleEnableDisable = async (modifyUser, onHabilitarUsuario) => {
        if (modifyUser.habilitado) {
            await eliminarUsuarioRequest(modifyUser.idUsuario, getToken());
            handleSetListado();
            return "Usuario dehabilitado con éxito"
        }
        await handleHabilitarYResetearUsuario(modifyUser, onHabilitarUsuario);
        handleSetListado();
        return "Usuario habilitado con éxito";
    }
    const handleHabilitarYResetearUsuario = async (modifyUser, onHabilitarUsuario) => {
        await editarUsuarioRequest({ ...modifyUser, habilitado: !modifyUser.habilitado }, getToken(), modifyUser.idUsuario);
        const respuesta = await restablecerUsuarioRequest(modifyUser.idUsuario, getToken());
        onHabilitarUsuario(modifyUser, respuesta)
    }
    const handleSetListado = async () => {
        try {
            showLoader();
            const token = getToken();
            let usuarios = await obtenerTodosLosUsuariosRequest(token);
            usuarios = usuarios.filter(x => x.rol === DESARROLLO_ROLE_VALUE)
            setListado(usuarios);
        } catch (error) {
            if (error.status === 500) {
                showNotification("error", "Error de conexión");
                return;
            }
            showNotification("error", "Ha ocurrido un error inesperado");
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        handleSetListado();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

    }, [listado]);

    const handleEdit = (row) => {
        navigate(`/usuarios/editar-usuario-desarrollo`, { state: { usuario: row } });
    }

    return (
        <>
            <Typography variant="h5" sx={{ margin: "1rem 0" }}>
                MANTENEDOR DE USUARIOS DESARROLLO
            </Typography>
            <br />
            <ShadowBox style={{ padding: "2rem" }}>
                <UsuariosLayout listado={listado}
                    onEnableDisable={handleEnableDisable}
                    onEdit={handleEdit}
                    addUserRoute="/usuarios/nuevo-usuario-desarrollo" 
                    labelAddButton="AGREGAR"
                    />
            </ShadowBox>
        </>
    );
};

