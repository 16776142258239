import { getApiUrl, RequestContentType, getRequest, postRequest } from "../apiConnection";

const API_URL = getApiUrl();

export async function crearUsuarioComercioRequest(idUsuario, idComercio, token) {
    const usuario = {
        idUsuario: idUsuario,
        idComercio: idComercio
    }
    return await postRequest(API_URL, '/api/usuarioscomercios', RequestContentType.APPLICATION_JSON, usuario, token);
}

export async function obtenerComerciosPorIdUsuarioRequest(idUsuario, token) {
    return await getRequest(API_URL, `/api/comercios?idUsuario=${idUsuario}`, token);
}
